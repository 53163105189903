import styled from "styled-components";

export const MessageWindowWraper = styled.div`

    z-index: 5;
    width: 90%;
    height: 100%;
    transition: 1s ease;
    visibility: hidden;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({fade}) => {
        if(fade){
            return 'opacity: 1;  visibility: visible; '
        }else{
            return 'opacity: 0;  visibility: hidden;'
        }
    }
    }

    @media screen and (max-width: 480px){
        width: 100%;
        top: 0;
        margin-left: -12px;
        position: fixed;
    }


`


export const MessageWindowContainer = styled.div`
    width: 570px;

    border-radius: 12px;
    border: 1px solid #F3F3F3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
    background: white;
    border: 1px solid var(--blue-100);
    /*
    position: absolute;
    z-index: 5;    
    left: 25%;
    top: 15%;
    */

    img{
        height: 270px;
    }

    @media screen and (max-width: 480px){
        width: 85%;
    }
`

export const MessageText = styled.div`

    h2{
        font-size: var(--text-xlarge);
        font-weight: var(--text-bold);
        white-space: pre-wrap;
        color: var(--blue-700);
        text-align: center;
        margin-bottom: 20px;

    }

    p{
        font-size: var(--text-small);
        font-weight: var(--text-normal);
        white-space: pre-wrap;
        color: var(--blue-700);
        text-align: center;
        margin-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (max-width: 480px){
        text-align: center;
    }

`