import React from "react";

import { i18n } from "../../../translate/i18n";

export default function AnswerId({questionId}){

    return(
        <>
            {questionId === 1 && (
                <>
                    <h4>{i18n.t('question.questions.1.title')}</h4>
                    <p>
                        {i18n.t('question.questions.1.answer.text1')}
                    </p>
                    <p>
                        {i18n.t('question.questions.1.answer.text2')}
                    </p>
                </>
            )}

            {questionId === 2 && (
                <>
                    <h4>{i18n.t('question.questions.2.title')}</h4>
                    <p>
                        {i18n.t('question.questions.2.answer.text')}
                    </p>
                </>
            )}

            {questionId === 3 && (
                <>
                    <h4>{i18n.t('question.questions.3.title')}</h4>
                    <p>
                        {i18n.t('question.questions.3.answer.text')}
                    </p>
                </>
            )}

            {questionId === 4 && (
                <>
                    <h4>{i18n.t('question.questions.4.title')}</h4>
                    <p>
                        {i18n.t('question.questions.4.answer.text')}    
                    </p>
                </>
            )}

            {questionId === 5 && (
                <>
                    <h4>{i18n.t('question.questions.5.title')}</h4>
                    <p>
                        {i18n.t('question.questions.5.answer.text')}
                    </p>

                    <ol>
                        <li>
                            {i18n.t('question.questions.5.answer.list.1')}
                        </li>
                        <li>
                            {i18n.t('question.questions.5.answer.list.2')}
                        </li>
                        <li>
                            {i18n.t('question.questions.5.answer.list.3')}
                        </li>
                        <li>
                            {i18n.t('question.questions.5.answer.list.4')}
                        </li>
                        <li>
                            {i18n.t('question.questions.5.answer.list.5')}
                        </li>
                        <li>
                            {i18n.t('question.questions.5.answer.list.6')}
                        </li>
                        <li>
                            {i18n.t('question.questions.5.answer.list.7')}
                        </li>
                        <li>
                            {i18n.t('question.questions.5.answer.list.8')}
                        </li>
                        <li>
                            {i18n.t('question.questions.5.answer.list.9')}
                        </li>
                        <li>
                            {i18n.t('question.questions.5.answer.list.10')}
                        </li>
                    </ol>
                </>
            )}

            {questionId === 6 && (
                <>
                    <h4>{i18n.t('question.questions.6.title')}</h4>
                    <p>
                        {i18n.t('question.questions.6.answer.text')}
                    </p>
                </>
            )}

            {questionId === 7 && (
                <>
                    <h4>{i18n.t('question.questions.7.title')}</h4>
                    <p>
                        {i18n.t('question.questions.7.answer.text')}
                    </p>
                    <ol>
                        <li>
                            {i18n.t('question.questions.7.answer.list.1')}
                        </li>
                        <li>
                            {i18n.t('question.questions.7.answer.list.2')}
                        </li>
                        <li>
                            {i18n.t('question.questions.7.answer.list.3')}
                        </li>
                    </ol>
                </>
            )}

            {questionId === 8 && (
                <>
                    <h4>{i18n.t('question.questions.8.title')}</h4>
                    <p>
                        {i18n.t('question.questions.8.answer.text1')}
                    </p>
                    <p>
                        {i18n.t('question.questions.8.answer.text2')}
                    </p>
                    <p>
                        {i18n.t('question.questions.8.answer.text3')}
                    </p>
                    <ul>
                        <li>
                            {i18n.t('question.questions.8.answer.list.1')}
                        </li>
                        <li>
                            {i18n.t('question.questions.8.answer.list.2')}
                        </li>
                        <li>
                            {i18n.t('question.questions.8.answer.list.3')}
                        </li>
                        <li>
                            {i18n.t('question.questions.8.answer.list.4')}
                        </li>
                        <li>
                            {i18n.t('question.questions.8.answer.list.5')}
                        </li>
                        <li>
                            {i18n.t('question.questions.8.answer.list.6')}
                        </li>
                    </ul>

                    <p>
                        A partir desse momento, o seguro cobrirá 100% dos seus gastos médicos. Tenha em mente que esta conta é feita dentro da validade do seu plano. Quando um novo ano se inicia, os gastos voltam para o $0 e, antes que o seguro cubra 100% da sua conta de hospital, você precisará atingir novamente os valores do seu Deductible e Out Of Pocket Maximum.
                    </p>
                </>
            )}

            {questionId === 9 && (
                <>
                    <h4>{i18n.t('question.questions.9.title')}</h4>
                    <p>
                        {i18n.t('question.questions.9.answer.text1')}
                    </p>

                    <p>
                        {i18n.t('question.questions.9.answer.text2')}
                    </p>

                    <p>
                        {i18n.t('question.questions.9.answer.text3')}
                    </p>
                </>
            )}

            {questionId === 10 && (
                <>
                    <h4>{i18n.t('question.questions.10.title')}</h4>
                    <p>
                        {i18n.t('question.questions.10.answer.text')}
                    </p>
                </>
            )}

            {questionId === 11 && (
                <>
                    <h4>{i18n.t('question.questions.11.title')}</h4>
                    <p>
                        {i18n.t('question.questions.11.answer.text')}
                    </p>
                </>
            )}

            {questionId === 12 && (
                <>
                    <h4>{i18n.t('question.questions.12.title')}</h4>
                    <p>
                        {i18n.t('question.questions.12.answer.text')}
                    </p>
                </>
            )}

            {questionId === 13 && (
                <>
                    <h4>{i18n.t('question.questions.13.title')}</h4>
                    <p>
                        {i18n.t('question.questions.13.answer.text')}
                    </p>
                </>
            )}

            {questionId === 14 && (
                <>
                    <h4>{i18n.t('question.questions.14.title')}</h4>
                    <p>
                        {i18n.t('question.questions.14.answer.text1')}
                    </p>
                    <p>
                        {i18n.t('question.questions.14.answer.text2')}
                    </p>
                </>
            )}

            {questionId === 15 && (
                <>
                    <h4>{i18n.t('question.questions.15.title')}</h4>
                    <p>
                        {i18n.t('question.questions.15.answer.text')}
                    </p>
                </>
            )}

        </>
    )
}