export const ourTeam = {
    foundedCompany: {
        title: 'Trabalhamos para ter a certeza de que tudo vai ficar bem com você e sua família!',
        text: 'A ROYAL EAGLE INSURANCE foi fundada com o intuito de humanizar a relação entre o seguro e o segurado, oferecendo a transparência no relacionamento e o cuidado constante que propicie a mais segura jornada para você e sua família nos Estados Unidos, possibilitando que cada cliente se sinta único.'
    },
    team: {
        edimara: {
            title:'Edimara Pasinato Dal Pozzo',
            subTitle: 'Insurance Producer',
            text1: 'Atua como Insurance Producer nos Estados Unidos para General Lines, Life, Health, HMO, Annuities Accidents, Property, Casualty & Personal Lines, liderando um time altamente qualificado e comprometido com a qualidade dos serviços, agilidade, credibilidade e orientação às necessidades do cliente.',
            text2: 'Ela é responsável por um amplo portfólio de produtos e serviços em INSURANCE que beneficiam e protegem indivíduos, famílias, empresas, patrimônios e grupos de empregadores e funcionários. A frente do Grupo Royal Eagle Insurance, Edimara tem como propósito o desenvolvimento e a execução da gestão de atendimento humanizado, esclarecedor e personalizado, que estabeleça uma relação de confiança entre o seguro e o segurado, além de transparência no relacionamento, honestidade e o cuidado constante para cada cliente.',
            text3: 'Além disso, ela é atuante ativa em organização não governamental e sem fins lucrativos que desenvolve metodologias e tecnologias educacionais, sociais e ambientais, promovendo a inclusão educacional e contribuindo com o desenvolvimento socioambiental da comunidade que está inserida.',
            certifications: {
                title: 'Certificações para Seguros',
                number: 8,
                list: {
                    1: 'General Lines',
                    2: 'Life',
                    3: 'Health',
                    4: 'HMO',
                    5: 'Annuities',
                    6: 'Accidents',
                    7: 'Property',
                    8: 'Casualty & Personal Lines'    
                }
            },
            bachelors: {
                title: 'Bacharéis',
                number: 2,
                list: {
                    1: 'Adminstracao de Empresas',
                    2: 'Recursos Humanos',    
                }
            },
            postgraduate: {
                title: 'Pós-Graduação',
                number: 3,
                list: {
                    1: 'Direito',
                    2: 'Recursos Humanos',
                    3: 'Adminstracao de Empresas',    
                }
            }

        },
        patricia: {
            title:'Patricia Duarte',
            subTitle: 'Licensed Insurance Agent',
            text1: 'Patricia Duarte é uma especialista no setor de seguros com licença e experiência necessárias para oferecer o melhor serviço possível aos seus clientes. Sua missão é construir apólices de seguros personalizadas para as necessidades específicas de cada cliente. Como sua agente de seguros, Patrícia possui especialidade em seguros de caminhões e também pode aconselhá-lo sobre uma variedade de seguros incluindo seguro de automóvel pessoal e comercial, residencial, empresarial e comercial.',
            text2: 'Nascida no Brasil, Patrícia mora nos EUA há 25 anos tendo residido na Flórida por doze anos e atualmente residindo em Dallas, Texas.',
            certifications: {
                title: 'Certificações para Seguros',
                number: 1,
                list: {
                    1: 'Property & Casualty',    
                }
            },
            bachelors: {
                title: 'Bacharéis',
                number: 2,
                list: {
                    1: 'Marketing',
                    2: 'Web Developer',    
                }
            },
        },
        fernanda: {
            title:'Fernanda Leite',
            subTitle: 'Marketing e Atendimento',
            text1: 'A missão de Fernanda Leite é atender nossos clientes e direcionar as demandas pessoais de cada um à broker da Royal Eagle Insurance, Edimara Pasinato, para cotação e aplicação dos seguros.',
            text2: 'Fernanda também gerencia o marketing da Royal Eagle Insurance.',
            text3: 'Seu excelente atendimento ao cliente e habilidades de comunicação são motivo constante de elogios. Fernanda é extemamente atenciosa e tem muito carinho e vocação no atendimento aos nossos clientes.',
            text4: 'Temos ORGULHO de contar com essa excelente profissional, de coração imenso e extrema competência em nosso Royal Eagle Team!'
        },
    }
}