export const ourValues = {
    pageTitle: 'Sobre',
    title: 'A ambição do Grupo\n Royal Eagle Insurance',
    text: 'É de contribuir com padrões elevados de credibilidade, clareza e transparência para garantir que os seguros comercializados sejam os mais apropriados para suas necessidades.',
    subTitle: 'Nossos valores',
    list: {
        1: 'Agilidade',
        2: 'Credibilidade',
        3: 'Qualidade dos Serviços',
        4: 'Reconhecimento',
        5: 'Orientação aos Clientes',
        6: 'Orientação para os Resultados',
    }
}