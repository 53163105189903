import React from "react";
import { LandPageFooter, Content, SocialMeida, Terms, Copyright } from "./style";
import { Link } from "react-router-dom";

import IconFooterFacebook from '../../asset/img/icon-footer-facebook.svg'
import IconFooterInstagram from '../../asset/img/icon-footer-instagram.svg'


export default function LandpageFooter(){
    return(
        <>
         <LandPageFooter>
            <Content>


                <SocialMeida>               
                    <Link to="https://www.facebook.com/Royal-Eagle-Insurance-101503306195835" target="blank"><img src={IconFooterFacebook} alt="Facebook" /></Link>
                    <Link to="https://www.instagram.com/royaleagleins/" target="blank"><img src={IconFooterInstagram} alt="Instagram" /></Link>
                </SocialMeida>

                <Terms>
                    <Link to="/"><p>Termo de Uso</p></Link>
                    <Link to="/"><p>Política de Privacidade</p></Link>
                </Terms>

                <Copyright>
                    <p>© 2024 Royal Eagle Insurace</p>
                </Copyright>
                
            </Content>
         </LandPageFooter>
            
        </>
    )
}