import React from "react";
import ButtonPrimary from '../ButtonPrimary'
import { InfirmationContainer, InforCard, InforCardText, InformationContent, PhoneNumber, Service, Specialist, SpecialistText, Time, Timeinfo, TimeInfoRow }from './style'
import {i18n} from '../../translate/i18n'

import IconPhoneInformation from '../../asset/img/icon-phone-information.svg'
import IconDocumentInformation from '../../asset/img/icon-document-information.svg'
import IconTimeInformation from '../../asset/img/icon-time-information.svg'
import { Link } from "react-router-dom";

export default function Informations(){
    return(
        <InfirmationContainer>
            <InformationContent>
                <InforCard>
                    <img src={IconPhoneInformation} alt="" />
                    <h4>{i18n.t('informations.serviceCard.title')}</h4>
                    <InforCardText>
                        <p>
                            <span>{i18n.t('informations.serviceCard.textColor')}</span>{i18n.t('informations.serviceCard.text1')}
                        </p>
                        <p>
                            {i18n.t('informations.serviceCard.text2')}
                        </p>
                    </InforCardText>
                    <PhoneNumber>
                        <p>
                            (346) 351 9858
                        </p>
                    </PhoneNumber>
                </InforCard>
                <InforCard>
                    <img src={IconDocumentInformation} alt="" />

                    <SpecialistText>
                        <p>
                            {i18n.t('informations.expertsCard.text1')} <span>{i18n.t('informations.expertsCard.textColor')}</span> {i18n.t('informations.expertsCard.text2')}
                        </p>
                    </SpecialistText>

                    <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                        <ButtonPrimary type="small">
                            {i18n.t('informations.expertsCard.button')}
                        </ButtonPrimary>
                    </Link>

                </InforCard>
                <InforCard>
                    <img src={IconTimeInformation} alt="" />
                    <h4>{i18n.t('informations.timeCard.title')}</h4>
                    <Timeinfo>

                        <div>
                            <p>
                            {i18n.t('informations.timeCard.week')}
                            </p>
                            <p>
                                {i18n.t('informations.timeCard.weekTime')}
                            </p>
                        </div>
                        <div>
                            <p>
                                {i18n.t('informations.timeCard.saturday')}
                            </p>
                            <p>
                                {i18n.t('informations.timeCard.saturdayTime')}
                            </p>
                        </div>
                        <div>
                            <p>
                                {i18n.t('informations.timeCard.sanday')}
                            </p>
                            <p>
                                {i18n.t('informations.timeCard.sandayTime')}
                            </p>
                        </div>

                    </Timeinfo>
                    
                </InforCard>
            </InformationContent>
        </InfirmationContainer>
    )
}