export const informations = {

    serviceCard: {
        title:'Service',
        textColor: 'A Royal Eagle Insurance Group ',
        text1: 'is a company that wants to turn your worries into peace of mind. Our service is fully focused on meeting your needs successfully.',
        text2: 'We are insurance specialists. Tell us how can we help you?'
    },
    expertsCard: {
        text1: 'As experts in ',
        textColor: 'insurance in the United States, ',
        text2: 'we can provide the right insurance to protect everything you care about.',
        button: 'Get Free Quote\n and Advice'
    },
    timeCard: {
        title: 'Time - CST',
        week: 'Monday to Friday',
        weekTime: '9:00 AM - 5:00 PM',
        saturday: 'Saturday',
        saturdayTime: 'Closed',
        sanday: 'Sunday',
        sandayTime: 'Closed'
    }


}