import React from "react";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Header from "../components/Header";
import HealthContent from "../components/HealthContent";

export default function Health(){
    return(
        <>        
            <Header />

            <main>

                <HealthContent />

                <Form />

            </main>

            <Footer />

        </>
    )
}