export const contact = {
    pageTitle: 'Contato',
    subTitle: 'Vamos conversar,\n estamos a sua disposição!',
    text: 'Não hesite em nos contatar por telefone, e-mail ou preenchendo o formulário de contato abaixo. Teremos prazer em responder a todas as suas perguntas e fornecer conselhos personalizados para ajudá-lo a tomar a melhor decisão para suas necessidades de seguro.',
    phone: 'Telefone',
    mail: 'E-mail',
    location: 'Localização',
    contactPhone: {
        phone: '+1(346) 351-9858',
        phoneLink: 'https://wa.me/13463519858',
    },

}