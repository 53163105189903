import React from "react";

import { i18n } from "../../translate/i18n";

import ImgAbout1 from '../../asset/img/img-about-1.jpg'
import ImgAbout2 from '../../asset/img/img-about-2.jpg'
import ImgAbout3 from '../../asset/img/img-about-3.jpg'
import ImgAbout4 from '../../asset/img/img-about-4.jpg'

import { BackgroundCircle, CenterText, ImgPerspectiveBottomLeft, ImgPerspectiveBottomRight, ImgPerspectiveTopLeft, ImgPerspectiveTopRight, OurValues, OurValuesContainer, Title, ValuesItens } from './style'

export default function OurValuesContent(){
    return(
        <OurValues>
            <OurValuesContainer>
                <Title>
                    <h1>{i18n.t('ourValues.pageTitle')}</h1>
                </Title>

                <BackgroundCircle></BackgroundCircle>

                <ImgPerspectiveTopLeft>
                    <img src={ImgAbout1} alt="" />
                    <img src={ImgAbout1} alt="" />
                    <img src={ImgAbout1} alt="" />
                </ImgPerspectiveTopLeft>

                <ImgPerspectiveTopRight>
                    <img src={ImgAbout2} alt="" />
                    <img src={ImgAbout2} alt="" />
                    <img src={ImgAbout2} alt="" />
                </ImgPerspectiveTopRight>

                <ImgPerspectiveBottomLeft>
                    <img src={ImgAbout3} alt="" />
                    <img src={ImgAbout3} alt="" />
                    <img src={ImgAbout3} alt="" />
                </ImgPerspectiveBottomLeft>

                <ImgPerspectiveBottomRight>
                    <img src={ImgAbout4} alt="" />
                    <img src={ImgAbout4} alt="" />
                    <img src={ImgAbout4} alt="" />
                </ImgPerspectiveBottomRight>


                <CenterText>

                    <h2>{i18n.t('ourValues.title')}</h2>

                    <p>
                        {i18n.t('ourValues.text')}
                    </p>

                    <ValuesItens>
                        <h4>{i18n.t('ourValues.subTitle')}</h4>
                        <ul>
                            <li>{i18n.t('ourValues.list.1')}</li>
                            <li>{i18n.t('ourValues.list.2')}</li>
                            <li>{i18n.t('ourValues.list.3')}</li>
                            <li>{i18n.t('ourValues.list.4')}</li>
                            <li>{i18n.t('ourValues.list.5')}</li>
                            <li>{i18n.t('ourValues.list.6')}</li>
                        </ul>
                    </ValuesItens>

                </CenterText>


            </OurValuesContainer>
        </OurValues>
    )
}