import React, { useState } from "react";
import ButtonPrimary from "../ButtonPrimary";
import { Link, useParams } from "react-router-dom";
import getPost from '../../asset/blog/post'

import { Post, PostContainer, Title, BlogSpace, PostInfo, PostImage, PostDateCategory, PostText, PostTitle} from './style'

import IconBlog from '../../asset/img/icon-title-blog.svg';
import PostAutoImage from '../../asset/img/post-image.jpg';
import BlogSeeMore from "../BlogSeeMore";

export default function PostContent(){

    const {postId} = useParams()
    
    const postResult = getPost(postId)
    const textSlited = postResult.text.split(/\r?\n/)

    return(
        <Post>
            <PostContainer>

                <Title>
                    <img src={IconBlog} alt="Blog" />
                    <h1>Blog - {postId}</h1>
                </Title>

                <BlogSpace>
                    
                    <PostInfo>

                        <PostImage src={postResult.image} />
                        
                        <PostDateCategory>
                            <p>{postResult.date} | {postResult.category}</p>
                        </PostDateCategory>
                        <PostTitle>
                            <h3>{postResult.title}</h3>
                        </PostTitle>
                        <PostText>
                           {textSlited.map((paragraph)=> (
                            <p>{paragraph}</p>
                           ))}
                        </PostText>

                    </PostInfo>

                    <BlogSeeMore />

                </BlogSpace>

            </PostContainer>
        </Post>
    )
}