import React from "react";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Header from "../components/Header";
import TruckContent from "../components/TruckContent";

export default function Truck(){
    return(
        <>        
            <Header />

            <main>

                <TruckContent />

                <Form />

            </main>

            <Footer />

        </>
    )
}