import React from "react";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Header from "../components/Header";
import BusinessContent from "../components/BusinessContent";

export default function Business(){
    return(
        <>        
            <Header />

            <main>

                <BusinessContent />

                <Form />

            </main>

            <Footer />

        </>
    )
}