import styled from "styled-components";
import BackgroundPurple from '../../asset/img/background-purple.svg'

import ImgContactPhone from '../../asset/img/img-contact-phone.jpg'
import ImgContactMail from '../../asset/img/img-contact-mail.jpg'
import ImgContacAddress from '../../asset/img/img-contact-address.jpg'


export const Contact = styled.section`

    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: -105px;
    background: url(${BackgroundPurple}) no-repeat left top;

    @media screen and (max-width: 480px){
        justify-content: flex-start;
        margin-top: -60px;
    }
`

export const ContactContainer = styled.div`

    width: var(--container-width);
    padding-top: 225px;

    @media screen and (max-width: 480px){
        width: 100%;
        padding-top: 180px;
    }
`

export const Title = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;

    & h1{
        font-size: var(--text-xlarge);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        margin-left: 30px
    }

`

export const CenterArticle = styled.div`

    margin-top: 120px;
    margin-bottom: 120px;

    display: flex;
    flex-direction: column;
    align-items: center;

    & h4{
        font-size: var(--text-small);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        margin-bottom: 40px;
    }
    
    @media screen and (max-width: 480px){
        margin-bottom: 80px;
        padding-left: 20px;
        padding-right: 20px;

    }
`

export const CenterArticleHeader = styled.div`

    width: 971px;
    & h4{
        white-space: pre-line;
    }

    @media screen and (max-width: 480px){
        width: 100%;
        text-align: justify;
    }
`

export const CollumText = styled.div`

    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 80px;

    & > div{
        width: 370px;
        height: 300px;
        border-radius: 12px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        
        padding-top: 80px;
        padding-bottom: 80px;

        & > p{
                color: var(--white);
                padding-bottom: 11px;
        }

        & div{
            text-align: center;
            
            & h4{
                color: var(--white);
                margin-top: 40px;
            }

            & p{
                color: var(--white);
            }

        }

        /* & a{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            color: var(--white);
        } */

    }

    @media screen and (max-width: 480px){
        width: 100%;
        flex-direction: column;
        margin-bottom: 40px;

        & > div{
            width: 100%;
        }
    }
`

export const CardPhone = styled.div`

    background: url(${ImgContactPhone}) no-repeat;


    & div:nth-last-child(1){
        display: flex;
        align-items: center;
        gap: 20px;
    }
    & a{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        color: var(--white);
    }


`
export const CardMail = styled.div`

    background: url(${ImgContactMail}) no-repeat;
    
    & a{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: var(--white);
    }

`
export const CardAddress = styled.div`

    background: url(${ImgContacAddress}) no-repeat;

    & a{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        color: var(--white);
    }


`