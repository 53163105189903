import styled from "styled-components";

import ButtonBackground from '../../asset/img/button-background.jpg'

export const Button = styled.button`

    padding: 12px 24px;
    background: linear-gradient(270deg, #171666 0%, #44368E 72.88%, #AF517E 148.86%);
    border: none;
    border-radius: 12px;
    color: var(--white);
    font-family: Outfit;
    transition: all linear 0.2s;

    
    cursor: pointer;

    ${
        (props) => {
            switch (props.type){
                case "small" : return `font-size: 16px; font-weight: 400;`
                case "large" : return `font-size: var(--text-small); font-weight: 700;`
            }
        }
    }

    &:hover{
        filter: brightness(120%) hue-rotate(70deg)     
    }

`