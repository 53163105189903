import styled, {keyframes} from "styled-components";
import BackgroundPurple from '../../asset/img/background-purple.svg'
import BackgroundCardQuestion from '../../asset/img/background-card-question.svg'

import iconHeartPurple from '../../asset/img/icon-question-heart-purple.svg'
import iconHeartRed from '../../asset/img/icon-question-heart-red.svg'
import iconPeoplePurple from '../../asset/img/icon-question-people-purple.svg'
import iconPeopleRed from '../../asset/img/icon-question-people-red.svg'
import iconMedicPurple from '../../asset/img/icon-question-medic-purple.svg'
import iconMedicRed from '../../asset/img/icon-question-medic-red.svg'
import iconWalletPurple from '../../asset/img/icon-question-wallet-purple.svg'
import iconWalletRed from '../../asset/img/icon-question-wallet-red.svg'
import iconHospitalPurple from '../../asset/img/icon-question-hospital-purple.svg'
import iconHospitalRed from '../../asset/img/icon-question-hospital-red.svg'

export const Question = styled.section`

    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: -105px;
    background: url(${BackgroundPurple}) no-repeat left top;
    
    @media screen and (max-width: 480px){
        margin-top: -60px;
    }
`

const suaveBg = keyframes`
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
    }
    
`

const suaveCard = keyframes`
    from {
        opacity: 0;
        margin-top: 4px
    }
    to{
        opacity: 1;
        margin-top: 0px
    }
    
`

export const PopupBackground = styled.div`

    position: fixed;
    top: 0;
    left: 0%;
    width: 100vw;
    height: 100vh;
    background: rgba(66, 66, 66, 0.2);
    backdrop-filter: blur(2px);
    z-index: 10;
    animation: ${suaveBg} 0.2s linear;

`


export const PopupAnswer = styled.div`

    position: fixed;
    top: 0;
    left: 0%;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    animation: ${suaveCard} 0.2s linear;

    @media screen and (max-width: 480px){
        padding-left: 5px;
        padding-right: 5px;
        box-sizing: border-box;
    }

`

export const PopupAnswerContainer = styled.div`

    width: 770px;
    background: url(${BackgroundCardQuestion}) no-repeat top left, var(--white);
    padding: 30px;
    border-radius: 12px;

    & h4{
        width: 95%;
        font-size: var(--text-small);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        margin-top: -25px;
        margin-bottom: 40px;
    }

    & > div{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    & button{
        background: none;
        border: none;
        cursor: pointer;

    }

    & p{
        margin-bottom: 24px;
        text-align: justify;

        &:nth-last-child(1){
            margin-bottom: 0px;
        }
    }

    & ul{
        list-style: disc inside;
        margin-bottom: 24px;
    }

    & ol{
        list-style: decimal inside;
        margin-bottom: 24px;
    }

    & li{
        margin-bottom: 10px;

        &:nth-last-child(1){
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 480px){
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;

        & h4{
            width: 90%;
        }
    }


`

export const QuestionContainer = styled.div`

    width: var(--container-width);
    padding-top: 225px;

    @media screen and (max-width: 480px){
        width: 100%;
        padding-top: 180px;
    }

`

export const Title = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 120px;

    & h1{
        font-size: var(--text-xlarge);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        margin-left: 30px
    }

`

export const QuestionCardsContainer = styled.div`

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 120px;

    @media screen and (max-width: 480px){
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;

    }

`
export const QuestionCard = styled.div`

    width: 308px;
    height: 121px;
    background:
    ${props => 
        props.bg === 'heart' && (`url(${iconHeartPurple})`) ||
        props.bg === 'wallet' && (`url(${iconWalletPurple})`) ||
        props.bg === 'medic' && (`url(${iconMedicPurple})`) ||
        props.bg === 'hospital' && (`url(${iconHospitalPurple})`) ||
        props.bg === 'people' && (`url(${iconPeoplePurple})`)
    }
      bottom right no-repeat, linear-gradient(135.63deg, #FBFBFF 0%, #F5F5FB 69.46%, #E2E0FF 97.35%);
    border: 1px solid #CAC9EE;
    border-radius: 12px;
    cursor: pointer;

    padding-left: 30px;
    padding-right: 30px;

    display: flex;
    align-items: center;

    transition: ease 0.2s;
    
    &:hover{
        background: 
        ${props => 
        props.bg === 'heart' && (`url(${iconHeartRed})`) ||
        props.bg === 'wallet' && (`url(${iconWalletRed})`) ||
        props.bg === 'medic' && (`url(${iconMedicRed})`) ||
        props.bg === 'hospital' && (`url(${iconHospitalRed})`) ||
        props.bg === 'people' && (`url(${iconPeopleRed})`)
    }
        bottom right no-repeat, linear-gradient(135.63deg, #FFFBFB 0%, #FBF5F5 69.46%, #FFE0E0 97.35%);
        border: 1px solid #F3B2B4;
        box-shadow: 0px 0px 20px 1px #FBDDDE;
    }

`

