export const auto = {
    pageTitle: 'Auto',
    text1: 'Your auto insurance policy helps protect your financial stability if you have an accident. There are many types of toppings, some of which are optional.',
    image1: 'New car',
    button: 'Quotation Form',
    subTitle: 'More about Auto Insurance',
    text2: 'Auto insurance helps provide protection against the negative financial effects of a car accident. The law requires you to have liability coverage. You can also choose to purchase coverage that helps protect your property, regardless of who is at fault in an accident.',
    text3: 'Contact us today to learn more about creating an auto insurance policy that complies with local laws while protecting your finances.',
    text4: 'Get an Auto Insurance Quote, Royal Eagle Insurance Group agents can help you evaluate your auto insurance options. Contact us and we will help you to have your personalized car insurance policy.',
    image2: 'Car maintenance'
}