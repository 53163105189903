export const question = {
    pageTitle: 'Duvidas Frequentes',

    questions: {
        1: {
            title:'O que é o Obamacare?',
            answer: {
                text1: 'O Affordable Care Act, popularmente conhecido como Obamacare, é uma lei promulgada em 2014 para garantir que todos os americanos tenham um seguro de saúde acessível. A lei oferece subsídios aos consumidores (“premium tax credits”) que reduzem os custos das famílias nos gastos com saúde.',
                text2: 'O Obamacare fez mudanças importantes na forma como muitas pessoas podem acessar os cuidados de saúde. Por exemplo, antes do Obamacare, as seguradoras podiam excluir pessoas com doenças pré-existentes. Assim, o Obamacare garantiu aos assegurados tratamentos básicos e até mesmo internações em caso de doenças graves, tudo isso independente de sexo ou, principalmente, de condições pré-existentes. Os estados da União que aderiram ao projeto também receberam mais verbas federais para outros projetos voltados para a área de saúde pública.'
            }
        },
        2: {
            title: 'Posso aplicar para o Obamacare a qualquer momento?',
            answer: {
                text: 'Existe um período específico para a aplicação do Obamacare. Inicia-se no dia 01 de novembro e termina no dia 15 de dezembro. Este tambem é o periondo de renovação para os planos que entrarão em vigor em 01 de janeiro do ano seguinte.'
            }
        },
        3: {
            title: 'Como posso aplicar para o Obamacare fora do período regular de inscrição?',
            answer: {
                text: 'Após o período de inscrição que vai de 01 de novembro a 15 de dezembro, você só poderá se inscrever para o Obamacare se estiver qualificado para um período de inscrição especial como casar-se, nascimento de filho ou adoção, mudar de Estado, divorciar-se, entre outros.'
            }
        },
        4: {
            title: 'Quem não tem Social Security Number pode fazer a aplicação para o Obamacare?',
            answer: {
                text: 'Sim, apresentar Social Security é opcional.'
            }
        },
        5: {
            title: 'Qual é a cobertura oferecida pelo Obamacare?',
            answer: {
                text: 'O ato também determina uma lista de cuidados em saúde, especialmente relacionados à prevenção, que todos os planos de saúde são obrigados a cobrir, sem teto de cobertura, para:',
                list: {
                    1: 'Visitas preventiva, check-up anual, incluindo gerenciamento de doenças crônicas',
                    2: 'Maternidade e recém-nascido',
                    3: 'Tratamento de saúde mental e comportamental',
                    4: 'Serviços e dispositivos para ajudar pessoas com lesões, deficiências ou condições crônicas',
                    5: 'Laboratório',
                    6: 'Cuidados pediátricos',
                    7: 'Medicamentos prescritos',
                    8: 'Atendimento ambulatorial',
                    9: 'Serviços de pronto-socorro',
                    10: 'Hospitalização',
                }
            }
        },
        6: {
            title: 'Quais são os requisitos para se inscrever no Obamacare?',
            answer: {
                text: 'Para se inscrever na cobertura de saúde por meio do Obamacare, você deve estar legalmente no país e declarar imposto de renda.'
            }
        },
        7: {
            title: 'Quanto custa o seguro de saúde através do Obamacare?',
            answer: {
                text: 'Os valores dos planos variam com:',
                list: {
                    1: 'Região que você mora',
                    2: 'Quantas pessoas há em sua casa',
                    3: 'O rendimento familiar declarado no imposto anual'
                }
            }
        },
        8: {
            title: 'Qual é o "Maximum Out of Pocket" ou Limite Máximo Anual?',
            answer: {
                text1: 'Este é o limite que você terá com seus gastos anuais para cada pessoa ou família (não incluí o pagamento mensal) . Imagine que você possui o deductible de 1 mil dólares, um Co-Insurance de 20% e um Out of Pocket Maximum de 5 mil dólares. Depois de gastar os 1 mil, você ainda é responsável pelo pagamento de 20% dos custos, desde que esse valor não ultrapasse o máximo de 5 mil dólares.',
                text2: 'Se você precisar passar por um tratamento grave de 100 mil dólares, não pagará os 20 mil correspondentes ao seu Co-Insurance, mas o limite anual de 5 mil, apenas.',
                text3: 'Caso você tenha um seguro de saúde com as seguintes condições:',
                list: {
                    1: '$500 dólares deductible',
                    2: '80% coinsurance',
                    3: '$3.000 dólares Out of Pocket Maximum.',
                    4: 'Se sua conta hospitalar foi de $10.000 dólares, primeiro você pagará $500 dólares.',
                    5: 'A partir daí, o seu seguro irá cobrir 80% do valor, enquanto você pagará os restantes 20%.',
                    6: 'Você continuará pagando 20% das contas até atingir o seu Out Of Pocket Maximum.',
                },
                text4: 'A partir desse momento, o seguro cobrirá 100% dos seus gastos médicos. Tenha em mente que esta conta é feita dentro da validade do seu plano. Quando um novo ano se inicia, os gastos voltam para o $0 e, antes que o seguro cubra 100% da sua conta de hospital, você precisará atingir novamente os valores do seu Deductible e Out Of Pocket Maximum.'
            }
        },
        9: {
            title: 'Qual a diferença entre Life Insurance Term e Life Insurance Permanent Life?',
            answer: {
                text1: 'Permanent Life Insurance é o Whole Life Insurance ou Universal Life Insurance e oferecem cobertura vitalícia para seus segurados.',
                text2: 'O Term Life Insurance fornece proteção por um determinado período, contudo seu custo é menor quando comparado ao Permanent Life Insurance.',
                text3: 'O Seguro de Vida garantirá que seus beneficiários possam usar o dinheiro para qualquer finalidade que escolherem. Ter a rede de segurança do seguro de vida garante, por exemplo, que sua família possa quitar a hipotéca de sua casa, pagar pelas projetos que você sonhou para eles, dando a sua família uma medida de conforto em face da incerteza e da tristeza.',
            }
        },
        10: {
            title: 'Como funciona o PPO?',
            answer: {
                text: 'PPO você é livre para escolher o médico de para sua necessidade. Com um plano PPO você nao precisa do *referral (ser encaminhado pelo clinico geral) ou seja, pode ir direto a um especialista. PPO oferece uma rede maior atendimento de médicos e hospitais; muitas vezes rede nacional. Você também pode se consultar com médicos “out of network”, porém, obviamente, neste caso, o valor pago pelo seguro para tratamentos médicos será menor.'
            }
        },
        11: {
            title: 'O que é Deductible?',
            answer: {
                text: 'Deductible é uma espécie de valor de franquia. É o valor que você precisa pagar antes que o seu seguro comece a pagar por tratamentos médicos válidos durante o período de 1 ano. Se você contrata um plano de saúde com deductible de 1 mil dólares, isso significa que seu plano só passa a “valer” depois que você já tiver pago mil dólares no período daquele ano, não incluídos os valores pagos no seu premium.'
            }
        },
        12: {
            title: 'O que é Premium?',
            answer: {
                text: 'Premium é a mensalidade que você precisa pagar para o seu plano.'
            }
        },
        13: {
            title: 'Como funciona o HMO?',
            answer: {
                text: 'HMO oferecem uma listagem menor de hospitais “in network” e você precisará passar por um clínico geral que irá te atender inicialmente que, então, lhe encaminhar ao especialista, se necessário. Você não poderá escolher o especialista que irá te assistir. Esses planos não cobrem visitas a médicos que são considerados “out of network” pelo seu plano.'
            }
        },
        14: {
            title: 'O que é Co-Pay?',
            answer: {
                text1: 'Co-Pay é o quanto você pagará junto ao plano por cada consulta e uso do plano. Ao contratar um seguro, você geralmente é responsável por pagar partes das suas despesas médicas. Esse valor altera de acordo com o tipo/categoria do médico.',
                text2: 'Por exemplo: voce pode ter que desembolsar U$15 para clínico geral, U$40 para especialistas e U$200 para emergência (tenha em mente que esses valores variam de acordo com seu seguro contratado).',
            }
        },
        15: {
            title: 'O que é Co-Insurance?',
            answer: {
                text: 'É a porcentagem que o seu seguro pagará quando você atingir o seu deductible. Por exemplo: Se você tem um Co-Insurance de 20% e recorre a um tratamento de $1000 dólares, isso significa que o plano pagará $800 dólares e você precisará arcar com os $200 restantes.'
            }
        }
    }

}