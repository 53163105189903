import React from "react";
import ButtonPrimary from "../ButtonPrimary";

import { i18n } from "../../translate/i18n";

import { Truck, TruckContainer, ImgContent, LeftArticle, LeftImgEffect, RightArticle, RightImgEffect, Subtitle, TextContent, Title } from './style'

import IconTruck from '../../asset/img/icon-title-truck.svg'
import ImgTruck1 from '../../asset/img/img-truck-1.jpg'
import ImgTruck2 from '../../asset/img/img-truck-2.jpg'
import { Link } from "react-router-dom";

export default function TruckContent(){
    return(
        <Truck>
            <TruckContainer>

                <Title>
                    <img src={IconTruck} alt={i18n.t('truck.pageTitle')} />
                    <h1>{i18n.t('truck.pageTitle')}</h1>
                </Title>

                <LeftArticle>
                    <TextContent>

                        <p>
                            {i18n.t('truck.text1')}
                        </p>

                        <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <ButtonPrimary type="large">
                                {i18n.t('truck.button')}
                            </ButtonPrimary>
                        </Link>

                    </TextContent>

                    <ImgContent right>

                        <div>
                            <img src={ImgTruck1} alt={i18n.t('truck.image1')} />
                            <RightImgEffect></RightImgEffect>
                        </div>

                    </ImgContent>
                </LeftArticle>

                <Subtitle>
                    <h2>{i18n.t('truck.subTitle')}</h2>
                </Subtitle>

                <RightArticle>

                    <ImgContent left="true">
                        <div>
                            <LeftImgEffect></LeftImgEffect>
                            <img src={ImgTruck2} alt={i18n.t('truck.image2')} />                            
                        </div>
                    </ImgContent>

                    <TextContent>
                        <p>
                            {i18n.t('truck.text2')}
                        </p><br />
                        <p>
                            {i18n.t('truck.text3')}
                        </p>
                    </TextContent>

                </RightArticle>

            </TruckContainer>
        </Truck>
    )
}