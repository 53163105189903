import React from 'react'
import { IframeVideo, LandPageHeader, LandPageHeaderButton, LandPageHeaderContainer, LandPageHeaderTitle, LandPageHeaderVideo } from './style'
import { Link } from 'react-router-dom'

import LogoHeader from '../../asset/img/logo-header.png'
import ButtonPrimary from '../ButtonPrimary'

export default function LandpageHeader(){

    function send(){

        let url = "https://wa.me/13467450578?text=" // Seu numero
        + "Escolha a mensagem para ser enviada para o Whatsapp."
        window.open(url, '_blank')

    }    
    
    return(
        <>
            <LandPageHeader>
                <LandPageHeaderContainer>
                    <nav>
                        <Link to="/" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <img src={LogoHeader} alt="Royal Eagle Insurance" />
                        </Link>
                    </nav>

                    <LandPageHeaderTitle>
                        <h1>
                            O que não te contaram sobre <span>SEGURO PARA ESTUDANTE F1</span>
                        </h1>
                    </LandPageHeaderTitle>

                    <LandPageHeaderVideo>
                        <IframeVideo width="100%" 
                            height="536" 
                            src="https://youtube.com/embed/o8fsGzfGVBU" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            referrerpolicy="strict-origin-when-cross-origin" 
                            allowfullscreen>

                        </IframeVideo>
                    </LandPageHeaderVideo>

                    <LandPageHeaderButton>
                        <ButtonPrimary type="large" onClick={send}>
                            Fazer Cotação
                        </ButtonPrimary>
                    </LandPageHeaderButton>
                    
                </LandPageHeaderContainer>
            </LandPageHeader>
        </>
    )
}