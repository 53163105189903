import styled from "styled-components";

export const FormContainer = styled.section`

    width: 100%;
    margin-top: -580px;
    display: flex;
    justify-content: center;

`

export const FormContent = styled.div`

    width: 570px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 60px;
    margin-bottom: 60px;
    position: relative;

    @media screen and (max-width: 480px){
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 12px;
        padding-right: 12px;
        margin-bottom: 12px;
    }

`

export const WhiteOnTop = styled.div`

    background: linear-gradient(270.96deg, #FFFFFF 23.45%, rgba(255, 255, 255, 0) 91.53%);

`

export const FormAnimation = styled.div`

    width: 570px;
    position: relative;
    z-index: -1;

    @media screen and (max-width: 480px){
        width: 100%;
    }

`

export const LottieContainer = styled.div`

    margin-left: -245px;
    padding-bottom: 57px;

    & > div svg{
        width: 974px;
    }

    @media screen and (max-width: 480px){
        margin-left: 0px;
        display: none;

    }

`

export const LottieContainerMobile = styled.div`
    display: none;

    @media screen and (max-width: 480px){
        display: block;
        padding-bottom: 57px;
        width: 100%;
    }

`

export const FormFields = styled.div`
    
    padding: 60px 30px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 0px 8px #808297;


    @media screen and (max-width: 480px){
        width: 100%;
        padding: 60px 0px;

    }
    
`

export const FormTitle = styled.div`

    margin-bottom: 80px;

    & h2{
        font-size: var(--text-large);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        white-space: pre-line;
        text-align: center;
    }

    & h4{
        font-size: var(--text-small);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        text-align: center;
    }

    @media screen and (max-width: 480px){
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    }

`

export const FormInputs = styled.form`

    display: flex;
    flex-direction: column;
    

    & label{
        width: 100%;
        font-size: var(--text-small);
        color: var(--blue-900);
        margin-bottom: 24px;
        
    }

    & input{
        width: 100%;
        height: 50px;
        background-color: var(--blue-50);
        border: 1px solid var(--blue-100);
        margin-top: 18px;
        border-radius: 4px;
        padding-left: 20px;
        box-sizing: border-box;

        &:focus{
            border-color: white;
        }

    }

    & input[type=checkbox]{
        width: 18px;
        height: 20px;
        margin: 0px;

        @media screen and (max-width: 480px){
            width: 40px;
            height: 40px;

        }

    }

    & select{
        width: 100%;
        height: 52px;
        background-color: var(--blue-50);
        border: 1px solid var(--blue-100);
        margin-left: 0px;
        margin-top: 18px;
        border-radius: 4px;
        padding-left: 20px;
        color: var(--blue-700);
    }

    & button{
        margin-top: 16px;
    }

    @media screen and (max-width: 480px){

        align-items: flex-start;
        padding-left: 20px;
        padding-right: 20px;
    }

`

export const InputRow = styled.div`

    display: flex;
    gap: 30px;

    @media screen and (max-width: 480px){

        width: 100%;
        flex-direction: column;
        gap: 0px;

    }
    
`

export const ButtonContainer = styled.div`

    width: 100%;
    display: flex;
    justify-content: right;

`

export const CheckboxContainer = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    p{
        font-size: var(--text-xxsmall);
    }

`