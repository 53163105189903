import React from "react";

import { i18n } from "../../translate/i18n";

import {CardAddress, CardMail, CardPhone, CenterArticle, CenterArticleHeader, CollumText, Contact, ContactContainer, Title } from './style'

import IconPhone from '../../asset/img/icon-contact-phone.svg'
import IconMail from '../../asset/img/icon-contact-mail.svg'
import IconAddress from '../../asset/img/icon-contact-address.svg'
import IconWhatsapp from '../../asset/img/icon-contact-whatsapp.svg'
import { Link } from "react-router-dom";

export default function ContactContent(){
    return(
        <Contact>
            <ContactContainer>

                <Title>
                    <h1>{i18n.t('contact.pageTitle')}</h1>
                </Title>

                <CenterArticle>
                    
                    <CenterArticleHeader>

                        <h4>{i18n.t('contact.subTitle')}</h4>

                        <p>
                            {i18n.t('contact.text')}
                        </p>

                    </CenterArticleHeader>

                    <CollumText>

                        <CardPhone>
                            <Link to={i18n.t('contact.contactPhone.phoneLink')} target="blank">
                                <div>
                                        <img src={IconPhone} alt="" />
                                    <h4>{i18n.t('contact.phone')}</h4>
                                </div>

                                <div>
                                        <img src={IconWhatsapp} alt="" />
                                        <p>{i18n.t('contact.contactPhone.phone')}</p>
                                </div>
                            </Link>

                        </CardPhone>

                        <CardMail>
                                <div>
                                    <img src={IconMail} alt="" />
                                    <h4>{i18n.t('contact.mail')}</h4>
                                </div>
                                <div>
                                    <Link to="mailto:edimara@royaleagleinsurance.com">
                                        <p>edimara@royaleagleinsurance.com</p>
                                    </Link>
                                    <Link to="mailto:gisele@royaleagleinsurance.com">
                                        <p>gisele@royaleagleinsurance.com</p>
                                    </Link>
                                </div>
                        </CardMail>

                        <CardAddress>
                            <div>                                
                                <img src={IconAddress} alt="" />
                                <h4>{i18n.t('contact.location')}</h4>
                            </div>
                            <div>
                                <p>1040 Schlipf Rd - Suite 129</p>
                                <p>Katy, Tx 77493</p>
                            </div>

                        </CardAddress>

                    </CollumText>

                </CenterArticle>

            </ContactContainer>
        </Contact>
    )
}