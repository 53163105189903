export const life = {
    pageTitle: 'Life',
    annuities: {
        title: 'Annuities',
        text: 'Muitos consumidores hoje estão preocupados principalmente em ter o suficiente para durar na aposentadoria. As anuidades de hoje oferecem uma variedade de recursos e benefícios que podem gerar uma renda que você não pode sobreviver, fornecer um legado para os entes queridos e até mesmo ajudá-lo a cuidar de despesas de cuidados de longo prazo, caso seja necessário.',
    },
    benefits: {
        title: 'Benefits',
        text: 'Seguro de vida que você não precisa morrer para usar! Nossas apólices de benefícios sem custos adicionais vêm com aprimoramentos que permitem que você acelere os benefícios de sua apólice para colocar o dinheiro necessário em suas mãos se você sofrer uma doença terminal, crônica ou crítica, como ataque cardíaco, derrame ou câncer - ou - em caso de lesão crítica.',        
    },
    image1: 'Life Insurance',
    lifeInsurance: {
        title: 'Life Insurance',
        text: 'Life insurance é uma apólice projetada para fornecer aos beneficiários designados uma quantia em dinheiro após sua morte em troca de um prêmio mensal durante toda a sua vida ou por um período definido. Você pode obter dois tipos principais de seguro de vida, dependendo de suas necessidades: TERM or PERMANENT life insurance.',
        lifeInsuranceTypes:{
            termLifeInsurance: {
                title: 'Term life insurance',
                text: 'Dura um período específico de tempo e termina no final do prazo definido. Prazos padrão são geralmente 10, 20 e 30 anos.'
            },
            permanentLifeInsurance: {
                title: 'Permanent life insurance',
                text: 'É para toda a sua vida, a menos que você interrompa seus pagamentos. Este tipo de seguro de vida costuma ser mais caro.'
            }
        },
        text2: 'Life insurance é uma apólice projetada para fornecer aos beneficiários designados uma quantia em dinheiro após sua morte em troca de um prêmio mensal durante toda a sua vida ou por um período definido. Você pode obter dois tipos principais de seguro de vida, dependendo de suas necessidades: TERM or PERMANENT life insurance.',
        button: 'Formulário de Cotação'
    },
    questions:{
        1:{
            title: 'Qual seguro de vida eu preciso?',
            text1: 'Muitos fatores podem afetar o custo de seus prêmios de seguro de vida, alguns fora de seu controle. É essencial gerenciar seus fatores controláveis para tentar reduzir seus custos antes de solicitar uma apólice. Ao determinar quanto seguro de vida você precisa, considere quais despesas precisariam ser cobertas no caso de sua morte, como hipoteca, empréstimos estudantis e pagamento de carro. Considere a substituição de renda se você estiver deixando para trás filhos e cônjuge.',
            text2: 'Nós vamos ajudá-lo a determinar quanto seguro de vida você precisa para fornecer a melhor proteção financeira possível para seus entes queridos.',
            image: 'Apólice de seguro'
        },
        2:{
            title: 'Quantas apólices de seguro de vida uma pessoa pode ter?',
            text: 'Não há limite para o número de apólices de seguro de vida que você pode ter. Se você tiver dúvidas sobre quantas apólices de seguro de vida você deve ter ou se estiver considerando várias apólices, entre em contato com um agente para uma discussão mais aprofundada.',
            image: 'Quantas apólices de seguro de vida uma pessoa pode ter'
        }
    },
    callQuotation:{
        title: 'Obtenha um seguro de vida para atender às suas necessidades',
        text: 'Na Royal Eagle, somos especialistas em LIFE INSURANCE para uma variedade de necessidades diferentes. Entre em contato com um agente hoje para explorar suas opções de seguro de vida.',
        button: 'Formulário de Cotação'
    }
    
}