import React from "react";
import ButtonPrimary from "../ButtonPrimary";

import { SeeMore, CardDateCategory, CardResume, BlogMiniCard, CardMiniTitle, SideListTitle } from './style'
import { blogList } from "../../asset/blog/blogList";
import { Link } from "react-router-dom";

export default function BlogSeeMore(){

    const seeMoreFiltered = blogList.filter((post) => post.isSeeMore === true)

    return(
       
        <SeeMore>

            <SideListTitle>
                <h3>Veja Também</h3>
            </SideListTitle>

            {seeMoreFiltered.map(post => (
                <BlogMiniCard>
                    <CardDateCategory>
                        <p>{`${post.date} | ${post.category}`}</p>
                    </CardDateCategory>

                    <CardMiniTitle>
                        <h3>{post.title}</h3>
                    </CardMiniTitle>

                    <CardResume>
                        <p>
                            {post.resume}
                        </p>
                    </CardResume>
                </BlogMiniCard>

            ))}

        </SeeMore>
    )
}