export const feedback = {
    title: 'Feedback from Our Customers',
    customers: {
        1: {
            name: 'Ana Lucia Dornelles',
            text: 'Royal Eagle Insurance provides an excellent service. I am very satisfied and I feel super safe because they are always attentive to our needs, indicating the ideal health plan for their profile. Any doubts I had were clarified with easy and efficient communication.'
        },
        2: {
            name: 'Elba Ferreira',
            text: 'Edimara has a unique professionalism. She was very efficient and fought for the best insurance for me. I am very satisfied with her work. Polite, responsible, fast. You know what you do. I SUPER recommend! Thanks!'
        },
        3: {
            name: 'Patrícia Lobo',
            text: 'It\'s been really great working with Edimara and Patricia from Royal Eagle on all my insurance needs. They really made the experience stress free. Thank you!'
        },
        4: {
            name: 'Cristiane Sozo Cardoso',
            text: 'Today I am very happy, because God placed an angel in my path, EDIMARA, a super professional and attentive person, a lovely person… I had a problem adding my daughter to my health insurance, I had been trying since October 2022 and nothing was working right, until I met this enlightened person, yes, she solved everything in a few minutes, I just have to thank you from the bottom of my heart for what you did for me today!!!! Thank you honey'
        },
        5: {
            name: 'Rita Pitanga',
            text: 'I highly recommend, Edimara is an excellent professional and a beautiful person, sincere and very careful with the customer.'
        },
        6: {
            name: 'Melissa Oliveira',
            text: 'This one I also sign below! Edimara Pasinato Dal Pozzo is a sweetheart and always available! A super professional!'
        },
    }

}