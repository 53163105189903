import React from "react";
import ButtonPrimary from "../ButtonPrimary";

import { i18n } from "../../translate/i18n";

import { Auto, AutoContainer, ImgContent, LeftArticle, LeftImgEffect, RightArticle, RightImgEffect, Subtitle, TextContent, Title } from './style'

import IconAuto from '../../asset/img/icon-title-auto.svg'
import ImgAuto1 from '../../asset/img/img-auto-1.jpg'
import ImgAuto2 from '../../asset/img/img-auto-2.jpg'
import { Link } from "react-router-dom";

export default function AutoContent(){
    return(
        <Auto>
            <AutoContainer>

                <Title>
                    <img src={IconAuto} alt={i18n.t('auto.pageTitle')} />
                    <h1>{i18n.t('auto.pageTitle')}</h1>
                </Title>

                <LeftArticle>
                    <TextContent>

                        <p>
                            {i18n.t('auto.text1')}
                        </p>

                        <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <ButtonPrimary type="large">
                                {i18n.t('auto.button')}
                            </ButtonPrimary>
                        </Link>

                    </TextContent>

                    <ImgContent right>

                        <div>
                            <img src={ImgAuto1} alt={i18n.t('auto.image1')} />
                            <RightImgEffect></RightImgEffect>
                        </div>

                    </ImgContent>
                </LeftArticle>

                <Subtitle>
                    <h2>{i18n.t('auto.subTitle')}</h2>
                </Subtitle>

                <RightArticle>

                    <ImgContent left="true">
                        <div>
                            <LeftImgEffect></LeftImgEffect>
                            <img src={ImgAuto2} alt={i18n.t('auto.image2')} />                            
                        </div>
                    </ImgContent>

                    <TextContent>
                        <p>
                            {i18n.t('auto.text2')}
                        </p><br/>
                        <p>
                            {i18n.t('auto.text3')}
                        </p><br/>
                        <p>
                            {i18n.t('auto.text4')}
                        </p>
                    </TextContent>

                </RightArticle>

            </AutoContainer>
        </Auto>
    )
}