export const health = {
    pageTitle: 'Health',
    obamacare: {
        title: 'Obamacare',
        subTitle: 'Understanding the Affordable Care Act (ACA)',
        text1: 'Contrary to popular belief, Obamacare is not government-provided health insurance. ObamaCare is a nickname for the health care law called The Patient Protection and Affordable Care Act (PPACA). Sometimes called the Affordable Care Act (ACA) for short',
        text2: 'President Barack Obama signed the Affordable Care Act into law on March 23, 2010. Despite some changes, many repeal attempts, and some major lawsuits, the ACA is still the “law of the land” today.',
        button: 'Quotation Form',
        title2: 'What does ObamaCare do?',
        text3: 'The Affordable Care Act seeks to increase the quality, availability and affordability of private and public health insurance for tens of millions of uninsured Americans through its many provisions, which include new regulations, taxes, mandates and subsidies.',
        text4: 'The law also aims to contain the growth of health spending in the United States, which has been growing at an unsustainable pace. More specifically, ObamaCare offers a host of new benefits, rights and protections, such as:',
        textBox: {
            1: 'Allow young people to remain on their parents\' plan until age 26',
            2: 'Prevent insurers from denying coverage or overcharging based on health status',
            3: 'Prevent insurance companies from abandoning you when you are sick or if you make an honest mistake in your application',
            4: 'Prevention of gender discrimination',
            5: 'Prevent insurers from imposing unwarranted rate increases',
            6: 'Expanding coverage to tens of millions by subsidizing health insurance costs through Health Insurance Marketplaces (HealthCare.Gov and the state Marketplaces)',
            7: 'Allow young people to remain on their parents\' plan until age 26',
            8: 'Making CHIP easier for kids to get',
            9: 'Give you the right to a quick appeal of the insurance company\'s decisions',
            10: 'Medicaid expansion to millions in states that opted to expand the program',
            11: 'Ending lifetime and annual dollar limits',
            12: 'Provide tax breaks for small businesses to offer health insurance to their employees',
            13: 'Requiring large companies to provide health coverage for employees',
            14: 'Requiring all insurers to cover people with pre-existing conditions',
            15: 'Ensure all plans cover minimum benefits such as cost-sharing limits and ten essential benefits including free preventive care, referral-free OB-GYN services, free birth control, and coverage for out-of-network emergency care',
        }
        
    },
    groupHealth: {
        title: 'Group Health Insurance',
        subTitle: 'Offering health coverage is an important decision for your business.',
        text1: 'Our small business health insurance portfolio includes a wide range of plan options, as well as extensive network coverage for groups of employees. We connect and coordinate all of our benefits to work together to improve all aspects of your employee care. This helps lower your overall costs and makes it easier to manage benefits, while delivering more value to your team.',
        text2: 'Medical and Pharmacy Coverage: All of our small business medical insurance plans feature integrated pharmacy coverage. The plans are designed to work seamlessly together so doctors and medical professionals can deliver the right care, in the right environment, at the right time.',
        button: 'Quotation Form',
        questions: {
            1:{
                title: 'Who will be contemplated?',
                text: 'We\'ll help you consider the needs of your employees and their dependents to find a plan that meets the diverse medical and financial needs of the group.'
            },
            2: {
                title: 'How much cost sharing can you afford?',
                text: 'We\'ll help you consider the needs of your employees and their dependents to find a plan that meets the diverse medical and financial needs of the group.'
            }
        },
        subTitle2: 'There are many factors to consider when evaluating your small business health insurance options.',
        text3: 'At Royal Eagle Insurance Group we recommend using the following 5 criteria to find plans that best suit your needs:',
        textBox: {
            1: 'Monthly premium: know what you and your employees will be able to pay monthly',
            2: 'drug coverage',
            3: 'Medical provider networks.',
            4: 'Deductible, copay, and coinsurance: Make sure these types of payments are manageable for you and your employees when you receive medical care.',
            5: 'Vision & Dental: You can add coverage for vision and dental care to ensure your employees are fully covered.',
        },
        callQuotation: {
            title: 'At Royal Eagle we are specialists in Group Health Insurance',
            text: 'Our licensed agents can provide personalized recommendations and guide you in choosing health plans for your business in a simple, fast and affordable way.',
            button: 'Quotation Form'
        }
    },

}