import React, { useState } from "react";
import ButtonPrimary from "../ButtonPrimary";
import BlogSeeMore from "../BlogSeeMore";
import { Link } from "react-router-dom";
import { blogList } from "../../asset/blog/blogList";

import { Blog, BlogContainer, Title, BlogSpace, BlogMainList, BlogSideList, BlogCard, CardImgContent, CardImg, CardIconContent, CardIcon, CardDateCategory, CardTitle, CardResume,BlogMainListContainer, Pagination, LeftButton, RightButton } from './style'

import IconBlog from '../../asset/img/icon-title-blog.svg'
import iconHealth from '../../asset/img/icon-blog-health.svg'
import iconLife from '../../asset/img/icon-blog-life.svg'
import iconTravel from '../../asset/img/icon-blog-travel.svg'
import iconAuto from '../../asset/img/icon-blog-auto.svg'
import iconHome from '../../asset/img/icon-blog-home.svg'
import iconBusiness from '../../asset/img/icon-blog-business.svg'
import iconTruck from '../../asset/img/icon-blog-truck.svg'

export default function BlogContent(){

    const [currentPage, setCurrentPage] = useState(1)
    const numberItensPerPage = 6

    const totalPageNumber = Math.ceil(blogList.length / numberItensPerPage)

    const endItemPage = currentPage * numberItensPerPage
    const startItemPage = endItemPage - numberItensPerPage

    const currentItensPage = blogList.slice(startItemPage, endItemPage)    

    function handleLeftButton(){
        if(currentPage > 1) {
            setCurrentPage(currentPage -1)
            window.scroll({ top: 800, behavior: 'smooth'}) 
        }
    }

    function handleRightButton(){
        if(currentPage < totalPageNumber) {
            setCurrentPage(currentPage +1)
            window.scroll({ top: 800, behavior: 'smooth'}) 
        }
    }

    function chageIcon(category){

        switch(category){
            case 'Health Insurance': 
                return iconHealth
            case 'Life Insurance':
                return iconLife
            case 'Travel Insurance':
                return iconTravel
            case 'Auto Insurance':
                return iconAuto
            case 'Home Insurance':
                return iconHome
            case 'Business Insurance':
                return iconBusiness
            case 'Truck Insurance':
                return iconTruck
        }
       
    }

        return(
        <Blog>
            <BlogContainer>

                <Title>
                    <img src={IconBlog} alt="Blog" />
                    <h1>Blog</h1>
                </Title>

                <BlogSpace>
                    <BlogMainListContainer>
                        <BlogMainList>
                            {currentItensPage.map(postCard => (
                                <BlogCard>
                                    <CardImgContent>
                                        <CardImg src={postCard.miniImage} title="Post" />
                                        <CardIconContent>
                                            <CardIcon src={chageIcon(postCard.category)} title={postCard.category} />
                                        </CardIconContent>
                                    </CardImgContent>

                                    <CardDateCategory>
                                        <p>{postCard.date} | {postCard.category}</p>
                                    </CardDateCategory>

                                    <CardTitle>
                                        <h3>{postCard.title}</h3>
                                    </CardTitle>

                                    <CardResume>
                                        <p>
                                            {postCard.resume}
                                        </p>
                                    </CardResume>
                                </BlogCard>
                            ))}    

                        </BlogMainList>

                        <Pagination>
                            <LeftButton onClick={handleLeftButton}> {'<'} </LeftButton>
                            <p>{`${currentPage} / ${totalPageNumber}`}</p>
                            <RightButton onClick={handleRightButton}> {'>'} </RightButton>
                        </Pagination>
                    </BlogMainListContainer>

                    <BlogSeeMore />

                </BlogSpace>

            </BlogContainer>
        </Blog>
    )
}