import React from 'react'
import Lottie from 'react-lottie'
import AnimationMessage from '../../../asset/video/message-sent.json'
import { MessageWindowContainer,MessageText } from './style'

export default function MessageWindow({isStopped}){

    const lottieOptions = {
      loop: false,
      autoplay: false,
      animationData: AnimationMessage,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    }

    return(
        <MessageWindowContainer>
            <Lottie options={lottieOptions} width={300} height={270} isStopped={isStopped}/>
            <MessageText>Mensagem Enviada!</MessageText>
        </MessageWindowContainer>
    )

}