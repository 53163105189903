export const travel = {
    pageTitle: 'Travel',
    text1: 'A travel insurance policy can protect you from a variety of unexpected circumstances while traveling. The right TRAVEL INSURANCE policy gives you peace of mind, no matter where your travels take you. You\'ll know who to turn to if something goes wrong with 24/7 emergency assistance.',
    button: 'Quotation Form',   
    image1: 'Airport', 
    questionTitle: 'Why do I need travel insurance?',
    image2: 'Airplane trip',
    subTitles: {
        1: {
            title: 'You need to cancel a last minute trip',
            text: 'Auto insurance helps provide protection against the negative financial effects of a car accident. The law requires you to have liability coverage. You can also choose to purchase coverage that helps protect your property, regardless of who is at fault in an accident.',
        },
        2: {
            title: 'Travel delays leave you lost',
            text: 'Contact us today to learn more about creating an auto insurance policy that complies with local laws while protecting your finances.'
        },
        3: {
            title: 'You get sick or injured when away from home',
            text: 'Get an Auto Insurance Quote, Royal Eagle Insurance Group agents can help you evaluate your auto insurance options. Contact us and we will help you to have your personalized car insurance policy.'
        }
    },
    callQuotation: {
        title: 'Get travel insurance to suit your needs',
        text: 'We can help you find a suitable travel insurance policy that meets your needs. You can compare all your travel insurance options, get coverage online, and travel worry-free.',
        button: 'Quotation Form'
    }
}