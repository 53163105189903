import React from "react";
import AutoContent from "../components/AutoContent";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Header from "../components/Header";
import LifeContent from "../components/LifeContent";

export default function Life(){
    return(
        <>        
            <Header />

            <main>

                <LifeContent />

                <Form />

            </main>

            <Footer />

        </>
    )
}