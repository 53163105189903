export const home = {
    pageTitle: 'Homeowners',
    text1: 'Your homeowner\'s insurance policy covers your home, personal effects and other structures on your property. It also provides personal liability coverage if someone is injured on your property or you or a family member is responsible for damage or injury to someone else\'s property.',
    button: 'Quotation Form',
    image1: 'Home',
    subTitle: 'More about Homeowners Insurance',
    image2: 'Set of houses',
    text2: 'It is crucial to get the right home insurance policy for your situation. Talk to us about your home insurance options. Depending on where you live, you may also need additional coverage for a possible flood or earthquake.',
    question: {
        title: 'What type of home insurance should I buy?',
        subTitle: 'You have three main options for home insurance:'
    },
    autoInsuranceTypes: {
        1: {
            title: 'Actual cash value (ACV) insurance',
            text: 'It covers the cost of the house and its belongings after depreciation. When you make a claim on an ACV home insurance policy, the insurer will reimburse your losses, but only up to the current amount.'
        },
        2: {
            title: 'Replacement cost insurance',
            text: 'It covers the actual cash value of your home and assets, but does so without deducting depreciation. This coverage allows you to rebuild or repair your home and replace your assets at their current value.'
        },
        3: {
            title: 'Guaranteed replacement cost insurance',
            text: 'Provides extra protection designed to protect your assets from inflation. This coverage pays the costs of repairing or replacing our home and possessions, even if the costs exceed your policy limits. Some insurers may offer extended replacement with a cap of 20% to 25% more than the policy limit.'
        }
    },
    callQuotation: {
        title: 'Get home insurance to suit your needs',
        text: 'Home insurance coverage protects one of your most valuable assets. Contact Royal Eagle Insurance Group today for a personalized home insurance quote.',
        button: 'Quotation Form'
    }
}