export const ourValues = {
    pageTitle: 'About',
    title: 'The ambition of the Group\n Royal Eagle Insurance',
    text: 'It is important to contribute with high standards of credibility, clarity and transparency to ensure that the insurance offered is the most appropriate for your needs.',
    subTitle: 'Our values',
    list: {
        1: 'Agility',
        2: 'Credibility',
        3: 'Quality of Services',
        4: 'Recognition',
        5: 'Customer Orientation',
        6: 'Results Orientation',
    }
}