import React from "react";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Header from "../components/Header";
import QuoteContent from "../components/QuoteContent";

export default function Quote(){
    return(
        <>        
            <Header />

            <main>

                <QuoteContent />

                <Form type="quote"/>

            </main>

            <Footer />

        </>
    )
}