export const feedback = {
    title: 'Feedback dos Nossos Clientes',
    customers: {
        1: {
            name: 'Ana Lucia Dornelles',
            text: 'A Royal Eagle Insurance presta um serviço de excelência. Estou muito satisfeita e sinto-me super segura pois estão sempre atentos às nossas necessidades indicando o plano de saúde ideal para o seu perfil. Todas as dúvidas que tinha foram esclarecidas com uma comunicação fácil e eficiente.'
        },
        2: {
            name: 'Elba Ferreira',
            text: 'Edimara é de um profissionalismo ímpar. Foi muito eficiente e batalhou pelo melhor seguro pra mim. Estou muito satisfeita com o seu trabalho. Educada, responsável, rápida. Sabe o que faz. Eu SUPER recomendo! Obrigada!'
        },
        3: {
            name: 'Patrícia Lobo',
            text: 'Tem sido muito bom trabalhar com Edimara e Patricia da Royal Eagle em todas as minhas necessidades de seguro. Eles realmente tornaram a experiência livre de estresse. Obrigado!'
        },
        4: {
            name: 'Cristiane Sozo Cardoso',
            text: 'Hoje estou muito feliz, porque Deus colocou um anjo no meu caminho, EDIMARA, uma super profissional e atenciosa, um amor de pessoa… eu estava com problema para adicionar minha filha no meu seguro de saúde, estava tentando desde outubro de 2022 e nada dava certo, até que conheci essa pessoa iluminada, sim, ela resolveu tudo em poucos minutos, eu só tenho que agradecer de coração oque vc fez por mim hoje!!!! Muito obrigada querida'
        },
        5: {
            name: 'Rita Pitanga',
            text: 'Super recomendo, Edimara é uma excelente profissional e uma pessoa linda, sincera e muito cuidadosa com o cliente.'
        },
        6: {
            name: 'Melissa Oliveira',
            text: 'Essa eu também assino embaixo! Edimara Pasinato Dal Pozzo é uma querida e sempre disponível! Uma super profissional!'
        },
    }

}