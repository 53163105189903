import React from 'react'

import QuoteLinkHeader from '../components/QuoteLinkHeader'
import FormQuoteLink from '../components/FormQuoteLink'

export default function QuoteLink(){

    return(
        <>             
            <QuoteLinkHeader />

            <main>

                <FormQuoteLink />

            </main>
            
        </>
    )
}