export const travel = {
    pageTitle: 'Travel',
    text1: 'Uma apólice de seguro de viagem pode protegê-lo de uma variedade de circunstâncias inesperadas durante a viagem. A apólice de TRAVEL INSURANCE certa oferece tranquilidade, não importa onde suas viagens o levem. Você saberá a quem recorrer se algo der errado, com assistência de emergência 24 horas por dia, 7 dias por semana.',
    button: 'Formulário de Cotação',   
    image1: 'Aeroporto', 
    questionTitle: 'Por que preciso de um seguro viagem?',
    image2: 'Viagem de avião',
    subTitles: {
        1: {
            title: 'Você precisa cancelar uma viagem de última hora',
            text: 'O seguro auto ajuda a fornecer proteção contra os efeitos financeiros negativos de um acidente de carro. A lei exige que você tenha cobertura de responsabilidade. Você também pode optar por comprar uma cobertura que ajude a proteger sua propriedade, independentemente de quem seja o culpado em um acidente.',
        },
        2: {
            title: 'Atrasos nas viagens deixam você perdido',
            text: 'Entre em contato conosco hoje para saber mais sobre como criar uma apólice de seguro de automóvel que esteja em conformidade com as leis locais e, ao mesmo tempo, proteja suas finanças.'
        },
        3: {
            title: 'Você fica doente ou ferido quando está longe de casa',
            text: 'Faça uma Cotação de Seguro Auto, Agentes Royal Eagle Insurance Group podem ajudá-lo a avaliar suas opções de auto insurance. Entre em contato e ajudaremos você a ter sua apólice de seguro de automóvel personalizada.'
        }
    },
    callQuotation: {
        title: 'Obtenha um seguro para viagem para atender às suas necessidades',
        text: 'Podemos ajudá-lo a encontrar uma apólice de seguro de viagem adequada que atenda às suas necessidades. Você pode comparar todas as suas opções de seguro de viagem, obter cobertura on-line e viajar sem preocupações.',
        button: 'Formulário de Cotação'
    }
}