import React from "react";
import ButtonPrimary from "../ButtonPrimary";

import { i18n } from "../../translate/i18n";

import { BoxContent, Business, BusinessContainer, CenterArticle, CenterArticleHeader, CollumText, GetInsurance, ImgContent, LeftArticle, LeftImgEffect, RightArticle, RightImgEffect, TextContent, Title } from './style'

import IconBusiness from '../../asset/img/icon-title-business.svg'
import ImgBusiness1 from '../../asset/img/img-business-1.jpg'
import ImgBusiness2 from '../../asset/img/img-business-2.jpg'
import { Link } from "react-router-dom";

export default function BusinessContent(){
    return(
        <Business>
            <BusinessContainer>

                <Title>
                    <img src={IconBusiness} alt={i18n.t('business.pageTitle')} />
                    <h1>{i18n.t('business.pageTitle')}</h1>
                </Title>

                <LeftArticle>
                    <TextContent>

                        <h2>{i18n.t('business.commercialAuto.title')}</h2>
                        
                        <p>
                            {i18n.t('business.commercialAuto.text')}
                        </p>

                    </TextContent>

                    <ImgContent right>

                        <div>
                            <img src={ImgBusiness1} alt={i18n.t('business.image1')} />
                            <RightImgEffect></RightImgEffect>
                        </div>

                    </ImgContent>
                </LeftArticle>

                <CenterArticle>
                    
                    <CenterArticleHeader>

                        <h2>{i18n.t('business.generalLiability.title')}</h2>

                        <p>
                            {i18n.t('business.generalLiability.text1')}
                        </p><br/>

                        <p>
                            {i18n.t('business.generalLiability.text2')}
                        </p>

                    </CenterArticleHeader>

                    <CollumText>

                        <div>

                            <BoxContent>
                                <p>
                                    {i18n.t('business.generalLiability.textBox.1')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('business.generalLiability.textBox.2')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('business.generalLiability.textBox.3')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                {i18n.t('business.generalLiability.textBox.4')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                {i18n.t('business.generalLiability.textBox.5')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                {i18n.t('business.generalLiability.textBox.6')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                {i18n.t('business.generalLiability.textBox.7')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                {i18n.t('business.generalLiability.textBox.8')}
                                </p>
                            </BoxContent>

                        </div>
                        <div>             

                            <BoxContent>
                                <p>
                                    {i18n.t('business.generalLiability.textBox.9')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('business.generalLiability.textBox.10')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('business.generalLiability.textBox.11')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('business.generalLiability.textBox.12')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('business.generalLiability.textBox.13')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('business.generalLiability.textBox.14')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('business.generalLiability.textBox.15')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('business.generalLiability.textBox.16')}
                                </p>
                            </BoxContent>
                            
                        </div>

                    </CollumText>

                </CenterArticle>


                <RightArticle>

                    <ImgContent left="true">
                        <div>
                            <LeftImgEffect></LeftImgEffect>
                            <img src={ImgBusiness2} alt={i18n.t('business.image2')} />                            
                        </div>
                    </ImgContent>

                    <TextContent>

                        <h4>{i18n.t('business.workersCompensation.title')}</h4>

                        <p>
                            {i18n.t('business.workersCompensation.text1')}
                        </p><br />

                        <p>
                            {i18n.t('business.workersCompensation.text2')}
                        </p><br />

                        <p>
                            {i18n.t('business.workersCompensation.text3')}
                        </p>

                    </TextContent>

                </RightArticle>

                <GetInsurance>
                    <div>
                        <h4>{i18n.t('business.callQuotation.title')}</h4>
                        <p>
                            {i18n.t('business.callQuotation.text')}
                        </p>
                        <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <ButtonPrimary type="large">
                                {i18n.t('business.callQuotation.button')}
                            </ButtonPrimary>
                        </Link>
                    </div>
                </GetInsurance>

            </BusinessContainer>
        </Business>
    )
}