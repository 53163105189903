import React from "react";
import ContactContent from "../components/ContactContent";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Header from "../components/Header";

export default function Contact(){
    return(
        <>        
            <Header />

            <main>

                <ContactContent />

                <Form />

            </main>

            <Footer />

        </>
    )
}