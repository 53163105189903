export const life = {
    pageTitle: 'Life',
    annuities: {
        title: 'Annuities',
        text: 'Many consumers today are primarily concerned with having enough to last them into retirement. Today\'s annuities offer a variety of features and benefits that can generate an income you may not survive on, provide a legacy for loved ones, and even help you take care of long-term care expenses should the need arise.',
    },
    benefits: {
        title: 'Benefits',
        text: 'Life insurance you don\'t have to die to use! Our no-additional benefit policies come with enhancements that allow you to accelerate your policy benefits to put the money you need in your hands if you suffer a terminal, chronic or critical illness such as a heart attack, stroke or cancer - or - in case of critical injury.',        
    },
    image1: 'Life Insurance',
    lifeInsurance: {
        title: 'Life Insurance',
        text: 'Life insurance is a policy designed to provide designated beneficiaries with a sum of money upon your death in exchange for a monthly premium for your entire life or for a defined period. You can get two main types of life insurance depending on your needs: TERM or PERMANENT life insurance.',
        lifeInsuranceTypes:{
            termLifeInsurance: {
                title: 'Term life insurance',
                text: 'It lasts for a specific amount of time and ends at the end of the defined time frame. Standard terms are usually 10, 20 and 30 years.'
            },
            permanentLifeInsurance: {
                title: 'Permanent life insurance',
                text: 'It\'s for life unless you stop your payments. This type of life insurance is usually more expensive.'
            }
        },
        text2: 'Life insurance is a policy designed to provide designated beneficiaries with a sum of money upon your death in exchange for a monthly premium for your entire life or for a defined period. You can get two main types of life insurance depending on your needs: TERM or PERMANENT life insurance.',
        button: 'Quotation Form'
    },
    questions:{
        1:{
            title: 'What life insurance do I need?',
            text1: 'MMany factors can affect the cost of your life insurance premiums, some beyond your control. It is essential to manage your controllable factors to try to reduce your costs before applying for a policy. When determining how much life insurance you need, consider what expenses would need to be covered in the event of your death, such as your mortgage, student loans, and car payment. Consider replacing income if you are leaving behind children and spouse.',
            text2: 'We\'ll help you determine how much life insurance you need to provide the best possible financial protection for your loved ones.',
            image: 'Insurance policy'
        },
        2:{
            title: 'How many life insurance policies can a person have?',
            text: 'There is no limit to the number of life insurance policies you can have. If you have questions about how many life insurance policies you should have or are considering multiple policies, contact an agent for further discussion.',
            image: 'How many life insurance policies can a person have'
        }
    },
    callQuotation:{
        title: 'Get life insurance to suit your needs',
        text: 'At Royal Eagle, we specialize in LIFE INSURANCE for a variety of different needs. Contact an agent today to explore your life insurance options.',
        button: 'Quotation Form'
    }
    
}