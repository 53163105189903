import React from "react";
import FeedBack from "../components/Feedback";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Informations from "../components/Informations";
import InsuranceProducer from "../components/InsuranceProducer";
import InsurersCarrucel from "../components/InsurersCarrucel";

export default function Main(){
    return(
        <>
            <Header />

            <main>

                <Informations />

                <InsuranceProducer />

                <FeedBack />

                <InsurersCarrucel />

            </main>
            
            <Footer />
        </>
    )
}