export const business = {
    pageTitle: 'Business & Commercial Insurance',
    image1: 'Commercial Auto',
    commercialAuto: {
        title: 'Commercial Auto',
        text: 'Se você precisa comprar uma apólice de seguro de automóvel comercial, dependerá do tipo de direção que você faz. Um bom corretor de seguros perguntará detalhes sobre como você usa os veículos em sua empresa, quem os conduzirá e se os funcionários, se os tiver, provavelmente dirigirão seus próprios carros para sua empresa.'
    },
    generalLiability: {
        title: 'General Liability',
        text1: 'Infelizmente para todo empresário, as chances de ser processado aumentaram drasticamente na última década. General Liability insurance pode impedir que um processo legal se transforme em um desastre financeiro, fornecendo proteção financeira caso sua empresa seja processada ou responsabilizada legalmente por algum dano ou prejuízo.',
        text2: 'General liability paga perdas decorrentes de lesões corporais reais ou alegadas, danos à propriedade ou lesões corporais em suas instalações comerciais ou decorrentes de suas operações.',
        textBox: {
            1: 'Lesão corporal, incluindo o custo dos cuidados, a perda de serviços e a restituição por qualquer morte resultante da lesão',
            2: 'Cobertura de danos à propriedade para danos físicos à propriedade de terceiros ou perda de uso dessa propriedade',
            3: 'A cobertura de operações concluídas por produtos fornece proteção de responsabilidade (danos e despesas legais até o limite de sua apólice) se uma lesão resultar de algo que sua empresa fez ou serviço que sua empresa forneceu',
            4: 'Cobertura de uso razoável da força',
            5: 'Cobertura de equipamento emprestado',
            6: 'Responsabilidade por bebidas alcoólicas',
            7: 'Cobertura de veículos não pertencentes (como aeronaves e embarcações)',
            8: 'Cobertura de lesões pessoais',
            9: 'A responsabilidade pelos produtos é um seguro de responsabilidade pelo produto mais especializado que protege sua empresa contra ações judiciais decorrentes de lesões ou acidentes relacionados ao produto',
            10: 'A responsabilidade contratual se estende a qualquer responsabilidade que você possa assumir ao entrar em uma variedade de contratos',
            11: 'Cobertura de dano por incêndio, raio ou explosão',
            12: 'Proteção de responsabilidade por danos causados pela água',
            13: 'Cobertura de custos de defesa legal',
            14: 'Cobertura de pagamentos médicos',
            15: 'Cobertura de lesão publicitária',
            16: 'Proteção de responsabilidade especializada para tipos de negócios específicos WORKER\'S COMPENSATION',
        }
    },
    workersCompensation: {
        title: 'Workers Compensation Insurance',
        text1: 'Os empregadores têm a responsabilidade legal para com seus funcionários de tornar o local de trabalho seguro. No entanto, acidentes acontecem mesmo quando todas as medidas de segurança razoáveis foram tomadas.',
        text2: 'Fornece pagamentos aos trabalhadores feridos, independentemente de quem foi o culpado no acidente, por tempo perdido no trabalho e por serviços médicos e de reabilitação. Ele também fornece benefícios de morte para cônjuges sobreviventes e dependentes.',
        text3: 'Workers compensation insurance não é seguro de saúde; Ele é projetado especificamente para lesões sofridas no trabalho.' 
    },
    image2: 'Workers Compensation',
    callQuotation: {
        title: 'Na Royal Eagle somos especialistas em Business & Commercial Insurance',
        text: 'Nossos agentes licenciados podem fornecer recomendações personalizadas e orientá-lo a escolher planos de saúde para sua empresa de forma simples, rápida e financeiramente acessível.',
        button: 'Formulário de Cotação'
    }
}