import IdPostImg1 from './blogImage/1-mini-mais-sobre-auto-insurance.jpg'

export const blogList = [
    {
        id: 1,
        isActive: true,
        isSeeMore: true,
        miniImage: IdPostImg1,
        resume: 'Sua apólice de seguro auto ajuda a proteger sua estabilidade financeira se você sofrer um acidente. Existem muitos tipos de coberturas, algumas delas opcionais.',
        date: '01/07/2024',
        category: 'Auto Insurance',
        title: 'Mais sobre Auto Insurance'
    },
    {
        id: 2,
        isActive: true,
        isSeeMore: false,
        miniImage: IdPostImg1,
        resume: 'Sua apólice de seguro auto ajuda a proteger sua estabilidade financeira se você sofrer um acidente. Existem muitos tipos de coberturas, algumas delas opcionais.',
        date: '01/07/2024',
        category: 'Travel Insurance',
        title: 'Outro post Travel Insurance'
    },
    {
        id: 3,
        isActive: true,
        isSeeMore: false,
        miniImage: IdPostImg1,
        resume: 'Sua apólice de seguro auto ajuda a proteger sua estabilidade financeira se você sofrer um acidente. Existem muitos tipos de coberturas, algumas delas opcionais.',
        date: '01/07/2024',
        category: 'Travel Insurance',
        title: 'Outro post Travel Insurance'
    },
    {
        id: 4,
        isActive: true,
        isSeeMore: true,
        miniImage: IdPostImg1,
        resume: 'Sua apólice de seguro auto ajuda a proteger sua estabilidade financeira se você sofrer um acidente. Existem muitos tipos de coberturas, algumas delas opcionais.',
        date: '01/07/2024',
        category: 'Travel Insurance',
        title: 'Outro post Travel Insurance'
    },
    {
        id: 5,
        isActive: true,
        isSeeMore: false,
        miniImage: IdPostImg1,
        resume: 'Sua apólice de seguro auto ajuda a proteger sua estabilidade financeira se você sofrer um acidente. Existem muitos tipos de coberturas, algumas delas opcionais.',
        date: '01/07/2024',
        category: 'Travel Insurance',
        title: 'Outro post Travel Insurance'
    },
    {
        id: 6,
        isActive: true,
        isSeeMore: false,
        miniImage: IdPostImg1,
        resume: 'Sua apólice de seguro auto ajuda a proteger sua estabilidade financeira se você sofrer um acidente. Existem muitos tipos de coberturas, algumas delas opcionais.',
        date: '01/07/2024',
        category: 'Travel Insurance',
        title: 'Outro post Travel Insurance'
    },
    {
        id: 7,
        isActive: true,
        isSeeMore: true,
        miniImage: IdPostImg1,
        resume: 'Sua apólice de seguro auto ajuda a proteger sua estabilidade financeira se você sofrer um acidente. Existem muitos tipos de coberturas, algumas delas opcionais.',
        date: '01/07/2024',
        category: 'Travel Insurance',
        title: 'Viagem com seguro garantido'
    }
]