import React from "react";
import ButtonPrimary from "../ButtonPrimary";

import { i18n } from "../../translate/i18n";

import { Auto, AutoContainer, ImgContent, LeftArticle, Quote, QuoteContainer, RightImgEffect, TextContent, Title } from './style'

import ImgQuote1 from '../../asset/img/img-quote-1.jpg'

export default function QuoteContent(){
    return(
        <Quote>
            <QuoteContainer>

                <Title>                    
                    <h1>{i18n.t('quote.pageTitle')}</h1>
                </Title>

                <LeftArticle>
                    <TextContent>

                        <h4>
                            {i18n.t('quote.subTitle')}
                        </h4>

                        <p>
                            {i18n.t('quote.text')}
                        </p>

                    </TextContent>

                    <ImgContent right>

                        <div>
                            <img src={ImgQuote1} alt={i18n.t('quote.pageTitle')} />
                            <RightImgEffect></RightImgEffect>
                        </div>

                    </ImgContent>
                </LeftArticle>

            </QuoteContainer>
        </Quote>
    )
}