import React from "react";
import ButtonPrimary from "../ButtonPrimary";

import { i18n } from "../../translate/i18n";

import {CenterArticle, CollumText, GetInsurance, Home, HomeContainer, ImgContent, LeftArticle, LeftImgEffect, RightArticle, RightImgEffect, Subtitle, TextContent, Title } from './style'

import IconHome from '../../asset/img/icon-title-home.svg'
import ImgHome1 from '../../asset/img/img-home-1.jpg'
import ImgHome2 from '../../asset/img/img-home-2.jpg'
import { Link } from "react-router-dom";

export default function HomeContent(){
    return(
        <Home>
            <HomeContainer>

                <Title>
                    <img src={IconHome} alt={i18n.t('home.pageTitle')} />
                    <h1>{i18n.t('home.pageTitle')}</h1>
                </Title>

                <LeftArticle>
                    <TextContent>

                        <p>
                            {i18n.t('home.text1')}
                        </p>

                        <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <ButtonPrimary type="large">
                                {i18n.t('home.button')}
                            </ButtonPrimary>
                        </Link>

                    </TextContent>

                    <ImgContent right>

                        <div>
                            <img src={ImgHome1} alt={i18n.t('home.image1')} />
                            <RightImgEffect></RightImgEffect>
                        </div>

                    </ImgContent>
                </LeftArticle>

                <Subtitle>
                    <h2>{i18n.t('home.subTitle')}</h2>
                </Subtitle>

                <RightArticle>

                    <ImgContent left="true">
                        <div>
                            <LeftImgEffect></LeftImgEffect>
                            <img src={ImgHome2} alt={i18n.t('home.image2')} />                            
                        </div>
                    </ImgContent>

                    <TextContent>
                        <p>
                        {i18n.t('home.text2')}
                        </p>
                    </TextContent>

                </RightArticle>

                <CenterArticle>

                    <h2>{i18n.t('home.question.title')}</h2>
                    <h4>{i18n.t('home.question.subTitle')}</h4>

                    <CollumText>

                        <div>
                            <h4>{i18n.t('home.autoInsuranceTypes.1.title')}</h4>
                            <p>
                                {i18n.t('home.autoInsuranceTypes.1.text')}
                            </p>
                        </div>
                        <div>
                            <h4>{i18n.t('home.autoInsuranceTypes.2.title')}</h4>
                            <p>
                                {i18n.t('home.autoInsuranceTypes.2.text')}
                            </p>
                        </div>

                        <div>
                            <h4>{i18n.t('home.autoInsuranceTypes.3.title')}</h4>
                            <p>
                                {i18n.t('home.autoInsuranceTypes.3.text')}
                            </p>
                        </div>

                    </CollumText>
                    
                </CenterArticle>

                <GetInsurance>
                    <div>
                        <h4>{i18n.t('home.callQuotation.title')}</h4>
                        <p>
                            {i18n.t('home.callQuotation.text')}
                        </p>
                        <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <ButtonPrimary type="large">
                                {i18n.t('home.callQuotation.button')}
                            </ButtonPrimary>
                        </Link>
                    </div>
                </GetInsurance>

            </HomeContainer>
        </Home>
    )
}