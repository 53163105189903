import styled from "styled-components";
import Background from '../../asset/img/background-feedback.jpg'
import BackgroundMobile from '../../asset/img/landpageImg/background-Landpage-header-mobile.jpg'

export const LandPageHeader = styled.section`

    width: 100%;
    display: flex;
    justify-content: center;
    background: url(${Background}) no-repeat top;

    @media screen and (max-width: 480px){
        background: url(${BackgroundMobile}) no-repeat top;
        background-size: contain;
    }
`

export const LandPageHeaderContainer = styled.div`

    width: var(--container-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;

    @media screen and (max-width: 480px){
        width: 100%;

        & img{
            width: 150px;
        }
    }

`

export const LandPageHeaderTitle = styled.div`

    padding: 80px 20px 80px 20px;

    & h1{
            width: 600px;
            color: #fff;
            text-align: center;
            font-size: 40px;

            & span{
                font-weight: 600;
            }
        }

    @media screen and (max-width: 480px){
        & h1{
            width: auto;
        }
    }

`

export const LandPageHeaderVideo = styled.div`


`

export const IframeVideo = styled.iframe`
    border-radius: 12px;

`

export const LandPageHeaderButton= styled.div`
    padding-top: 80px;

`