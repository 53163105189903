import styled from "styled-components";

import BackgroundEagle from '../../asset/img/background-eagle.svg'

export const ProducerContainer = styled.section`

    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-top: -135px;
    position: relative;
    //z-index: -1;
    background: url(${BackgroundEagle}) no-repeat 15% 50%;

    @media screen and (max-width: 480px){
        //margin-top: 120px;
        justify-content: flex-start;
        background: none;
    }

`

export const ProducerContent = styled.div`

    width: var(--container-width);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 120px;

    @media screen and (max-width: 480px){
        width: 100%;
        flex-wrap: wrap;
    }

`

export const ProducerInfo = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    

    & h2{
        font-size: var(--text-large);
        font-weight: var(--text-bold);
        color: var(--blue-1000);
    }
    & h4{
        font-size: var(--text-small);
        font-weight: var(--text-normal);
        color: var(--blue-700);
        margin-bottom: 80px;
    }

    & p{
        width: 470px;
        text-align: justify;
    }

    & a{
        margin-top: 40px;
        margin-bottom: 80px;
    }

    @media screen and (max-width: 480px) {
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        order: 2;
        

        & p{
            width: 100%;
            
        }
        & a{
            margin-bottom: 0px;
        }
    }

`

export const ProducerImage = styled.div`

    @media screen and (max-width: 480px){
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        order: 1;
        margin-bottom: 80px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;

        & img{
            width: 198%;
        }
    }

`

export const ImageContent = styled.div`

    width: 470px;

    @media screen and (max-width: 480px){
        width: 100%;
    }

`