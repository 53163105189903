import React from "react";
import ButtonPrimary from "../ButtonPrimary";

import { BoxContent, CenterArticle, CenterArticleHeader, CollumText, GetInsurance, Health, HealthContainer, ImgContent, LeftArticle, LeftImgEffect, RightArticle, RightImgEffect, TextContent, Title } from './style'

import { i18n } from "../../translate/i18n"; 

import IconHealth from '../../asset/img/icon-title-health.svg'
import ImgHealth1 from '../../asset/img/img-health-1.jpg'
import ImgHealth2 from '../../asset/img/img-health-2.jpg'
import ImgHealth3 from '../../asset/img/img-health-3.jpg'
import { Link } from "react-router-dom";

export default function HealthContent(){
    return(
        <Health>
            <HealthContainer>

                <Title>
                    <img src={IconHealth} alt="" />
                    <h1>{i18n.t('health.pageTitle')}</h1>
                </Title>

                <LeftArticle>
                    <TextContent>

                        <h2>{i18n.t('health.obamacare.title')}</h2>
                        <h5>{i18n.t('health.obamacare.subTitle')}</h5>
                        <p>
                            {i18n.t('health.obamacare.text1')}
                        </p><br />
                        <p>
                            {i18n.t('health.obamacare.text2')}
                        </p>

                        <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <ButtonPrimary type="large">
                                {i18n.t('health.obamacare.button')}
                            </ButtonPrimary>
                        </Link>

                    </TextContent>

                    <ImgContent right>

                        <div>
                            <img src={ImgHealth1} alt={i18n.t('health.obamacare.title')} />
                            <RightImgEffect></RightImgEffect>
                        </div>

                    </ImgContent>
                </LeftArticle>

                <CenterArticle>

                    <h2>{i18n.t('health.obamacare.title2')}</h2>
                    
                    <CenterArticleHeader>

                        <p>
                            {i18n.t('health.obamacare.text3')}
                        </p><br/>

                        <p>
                            {i18n.t('health.obamacare.text4')}   
                        </p>

                    </CenterArticleHeader>

                    <CollumText>

                        <div>

                            <BoxContent>
                                <p>
                                {i18n.t('health.obamacare.textBox.1')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.2')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.3')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.4')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.5')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.6')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.7')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.8')}
                                </p>
                            </BoxContent>

                        </div>
                        <div>             

                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.9')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.10')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.11')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.12')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.13')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.14')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.obamacare.textBox.15')}
                                </p>
                            </BoxContent>
                            
                        </div>

                    </CollumText>

                </CenterArticle>


                <RightArticle>

                    <ImgContent left="true">
                        <div>
                            <LeftImgEffect></LeftImgEffect>
                            <img src={ImgHealth2} alt={i18n.t('health.groupHealth.title')} />                            
                        </div>
                    </ImgContent>

                    <TextContent>

                        <h2>{i18n.t('health.groupHealth.title')}</h2>
                        <h5>{i18n.t('health.groupHealth.subTitle')}</h5>
                        <p>
                            {i18n.t('health.groupHealth.text1')}
                        </p><br />
                        
                        <p>
                            {i18n.t('health.groupHealth.text2')}
                        </p>

                        <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <ButtonPrimary type="large">
                                {i18n.t('health.groupHealth.button')}
                            </ButtonPrimary>
                        </Link>

                    </TextContent>

                </RightArticle>

                <LeftArticle>

                    <TextContent>
                        <div>

                            <h2>{i18n.t('health.groupHealth.questions.1.title')}</h2>
                            <p>
                                {i18n.t('health.groupHealth.questions.1.text')}
                            </p>

                        </div>
                        <div>
                            <h2>{i18n.t('health.groupHealth.questions.2.title')}</h2>
                            <p>
                                {i18n.t('health.groupHealth.questions.2.text')}
                            </p>
                        </div>
                    </TextContent>

                    <ImgContent right>

                        <div>
                            <img src={ImgHealth3} alt="" />
                            <RightImgEffect></RightImgEffect>
                        </div>

                    </ImgContent>
                </LeftArticle>

                <CenterArticle>
                    <CenterArticleHeader>

                        <h4>
                            {i18n.t('health.groupHealth.subTitle2')}
                        </h4>
                        <p>
                            {i18n.t('health.groupHealth.text3')}
                        </p>

                    </CenterArticleHeader>

                    <CollumText>

                        <div>

                            <BoxContent>
                                <p>
                                    {i18n.t('health.groupHealth.textBox.1')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.groupHealth.textBox.2')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.groupHealth.textBox.3')}
                                </p>
                            </BoxContent>

                        </div>
                        <div>             

                            <BoxContent>
                                <p>
                                    {i18n.t('health.groupHealth.textBox.4')}
                                </p>
                            </BoxContent>
                            <BoxContent>
                                <p>
                                    {i18n.t('health.groupHealth.textBox.5')}
                                </p>
                            </BoxContent>
                            
                        </div>

                    </CollumText>

                </CenterArticle>


                <GetInsurance>
                    <div>
                        <h4>
                            {i18n.t('health.groupHealth.callQuotation.title')}
                        </h4>
                        <p>
                            {i18n.t('health.groupHealth.callQuotation.text')}
                        </p>
                        <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <ButtonPrimary type="large">
                                {i18n.t('health.groupHealth.callQuotation.button')}
                            </ButtonPrimary>
                        </Link>
                    </div>
                </GetInsurance>


            </HealthContainer>
        </Health>
    )
}