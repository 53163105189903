export const form = {
    title1: 'Do you have any questions?\n Please get in touch',
    title2: 'Request your insurance quote today and see how we can help you get the peace of mind you deserve.',
    name: 'Name',
    lastName: 'Last Name',
    phone: 'Phone',
    email: 'E-mail',
    insuranceType: 'Type of Insurance',
    insuraceTypePlacehoder: 'Choose the type of insurance',
    message: 'Message',
    button: 'Send'

}