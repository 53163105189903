import React, { useState } from 'react'
import Lottie from 'react-lottie'
import AnimationMessage from '../../../asset/video/message-sent.json'
import { i18n } from "../../../translate/i18n";
import { MessageWindowContainer,MessageText, MessageWindowWraper } from './style'
import IconError from '../../../asset/img/icon-error.svg'

export default function MessageWindow({statusMessage, showMessage, isStopped}){

  const [sendEmailMessage, setSendEmailMessage] = useState({fade: false, isStopped: true})



  const lottieOptions = {
    loop: false,
    autoplay: false,
    animationData: AnimationMessage,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  return(
    <MessageWindowWraper fade={showMessage}>
      <MessageWindowContainer>

        {statusMessage ? 

          <>
            <Lottie options={lottieOptions} width={300} height={270} isStopped={isStopped}/>
            <MessageText>
              <h2>{i18n.t('formQuote.messageSent')}</h2>
              <p>{i18n.t('formQuote.touchSoon')}</p>
            </MessageText>

          </>
          :
          <>
            <img src={IconError} alt="Error"/>
            <MessageText> 
              <h2>{i18n.t('formQuote.messageNotSent')}</h2>
              <p>{i18n.t('formQuote.againLater')}</p>
            </MessageText>
          </>

        }

      </MessageWindowContainer>
    </MessageWindowWraper>
  )

}