import React from "react";
import { OurPriorityContainer, OurPriorityContent, OurPriorityText } from './style'

import { i18n } from "../../translate/i18n";

export default function OurPriority(){
    return(
        <OurPriorityContainer>
            <OurPriorityContent>

                <h2>{i18n.t('ourPriority.title')}</h2>
                <h4>{i18n.t('ourPriority.subTitle')}</h4>
                <OurPriorityText>
                    <div>
                        <p>
                            {i18n.t('ourPriority.text1')}
                        </p>
                        <p>
                            {i18n.t('ourPriority.text2')}
                        </p>
                    </div>
                </OurPriorityText>

            </OurPriorityContent>
        </OurPriorityContainer>
    )
}