import styled from "styled-components";
import BackgroundPurple from '../../asset/img/background-purple.svg'

export const Health = styled.section`

    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: -105px;
    background: url(${BackgroundPurple}) no-repeat left top;

    @media screen and (max-width: 480px){
        justify-content: flex-start;
        margin-top: -60px;
    }
`

export const HealthContainer = styled.div`

    width: var(--container-width);
    padding-top: 225px;

    @media screen and (max-width: 480px){
        width: 100%;
        padding-top: 180px;
    }

`

export const Title = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 80px;

    & h1{
        font-size: var(--text-xlarge);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        margin-left: 30px
    }

    @media screen and (max-width: 480px){
        padding-left: 20px;
    }
`

export const Subtitle = styled.div`

    margin-bottom: 120px;
    width: 100%;
    text-align: center;

    & h2{
        font-size: var(--text-xlarge);
        font-weight: var(--text-bold);
        color: var(--blue-900);
    }
    
    @media screen and (max-width: 480px){

        & h2{
            padding-left: 20px;
            padding-right: 20px;
        }
    }
`

export const LeftArticle = styled.article`

    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 130px;

    @media screen and (max-width: 480px){
        flex-direction: column;
        gap: 80px;
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const TextContent = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    

    & div{
        margin-bottom: 40px;
        
    }

    & div > h2{
        margin-bottom: 24px;
    }

    & div:nth-last-child(1){
        margin-bottom: 0px;
    }

    & h5 {
        width: 470px;
        font-size: var(--text-small);
        font-weight: var(--text-light);
        color: var(--blue-700);
        margin-top: 8px;
        margin-bottom: 24px;
    }

    & h2 {
        width: 470px;
        font-size: var(--text-large);
        font-weight: var(--text-bold);
        color: var(--blue-900);
    }

    & p{
        width: 470px;
        text-align: justify;
    }

    & button{
        margin-top: 80px;
    }

    @media screen and (max-width: 480px){
        align-items: center;
        order: 2;

        & p{
            width: 100%;
        }

        & h2{
            width: 100%;
            font-size: 38px;
        }

        & div h4 {
            width: 100%;
        }
        & h5 {
            width: 100%;
        }

    }
`

export const ImgContent = styled.div`

    width: 467px;

    & > div{
        width: 786px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        ${props => props.left && ('margin-left: -319px;')}
        
    }

    & img{
        border-radius: 12px;
    }

    @media screen and (max-width: 480px){
        width: 100%;
        order: 1;
        & > div{
            width: 100%;
            ${props => props.left && ('margin-left: 0px;')}
        }

        & img{
            width: 100%;
        }
    }
`

export const RightImgEffect = styled.div`

    width: 399px;
    height: 319px;
    background: linear-gradient(270deg, #FFFFFF 1%, #EDEAFF 90%);
    margin-left: -10px;
    position: relative;
    z-index: -1;

    @media screen and (max-width: 480px){
        display: none;
    }
`

export const RightArticle = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 130px;
    padding-bottom: 120px;

    @media screen and (max-width: 480px){
        flex-direction: column;
        gap: 80px;
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const LeftImgEffect = styled.div`

    width: 399px;
    height: 319px;
    background: linear-gradient(270deg, #FFFFFF 1%, #EDEAFF 90%);
    transform: rotate(180deg);
    margin-right: -10px;
    position: relative;
    z-index: -1;

    @media screen and (max-width: 480px){
        display: none;
    }
`

export const CenterArticle = styled.div`

    margin-top: 120px;
    margin-bottom: 120px;

    display: flex;
    flex-direction: column;
    align-items: center;

    & h2{
        font-size: var(--text-large);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        margin-bottom: 80px;        
        text-align: center;
    }

    & h4{
        font-size: var(--text-small);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        margin-bottom: 40px;    
    }

    @media screen and (max-width: 480px){
        margin-bottom: 80px;
        padding-left: 20px;
        padding-right: 20px;

    }
`

export const CenterArticleHeader = styled.div`

    width: 971px;

    @media screen and (max-width: 480px){
        width: 100%;
        text-align: justify;
    }

`

export const CollumText = styled.div`

    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 80px;

    & > div{
        width: 570px;
    }
    
    @media screen and (max-width: 480px){
        width: 100%;
        flex-direction: column;
        margin-bottom: 40px;

        & > div{
            width: 100%;
        }
    }
`

export const BoxContent = styled.div`

    flex: 1;
    background: var(--blue-50);
    border: 1px solid var(--blue-100);
    border-radius: 12px;
    padding: 24px 24px;
    margin-bottom: 24px;

    &:nth-last-child(1){
        margin-bottom: 0px;
    }

    & p{
        text-align: justify;
    }

`

export const GetInsurance = styled.article`

    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 120px;
   
   & div{
       width: 770px;
       text-align: right;

       & h4{
           font-size: var(--text-small);
           font-weight: var(--text-bold);
           color: var(--blue-900);
           width: 400px;
           text-align: left;
       }

       & p{
           margin-left: 200px;
           width: 570px;
           text-align: justify;
           margin-top: 40px;
           margin-bottom: 40px;
       }
   }

   @media screen and (max-width: 480px){
        
        & div{
            width: 100%;
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;

            & h4{
                width: 100%;
            }

            & p{
                width: 100%;
                margin-left: 0px;
            }
        }
    }

`


