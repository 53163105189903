import React from "react";
import { Avatar, AvatarContainer, AvatarImg, AvatarSlider, Carrucel, FeedBackArea, FeedbackContainer, FeedBackContent, FeedbackText, LeftButton, Perfil, RightButton, Slider, TextContainer } from './style'

import { i18n } from "../../translate/i18n";

import ImgFeedBackAna from '../../asset/img/img-feedback-ana.png'
import ImgFeedBackElba from '../../asset/img/img-feedback-elba.png'
import ImgFeedBackCristiane from '../../asset/img/img-feedback-cristiane.png'
import ImgFeedBackPatricia from '../../asset/img/img-feedback-patricia.png'
import ImgFeedBackRita from '../../asset/img/img-feedback-rita.png'
import ImgFeedBackMelissa from '../../asset/img/img-feedback-melissa.png'
import ImgFeedBackUnknow from '../../asset/img/img-feedback-unknow.png'

import ArrowButton from '../../asset/img/arrow.svg'
import { useState } from "react";

export default function FeedBack(){

    const [carrucelPosition, setCarrucelPosition] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [avatarPosition, setAvatarPosition] = useState(0)

    const windowWidth = window.screen.width;

    const feedbackDemoiments = [
        {
            img: ImgFeedBackAna,
            text: i18n.t('feedback.customers.1.text'),
            name: i18n.t('feedback.customers.1.name'),
            socialMedia: '',
            link: ''
        },
        {
            img: ImgFeedBackElba,
            text: i18n.t('feedback.customers.2.text'),
            name: i18n.t('feedback.customers.2.name'),
            socialMedia: '',
            link: ''
        },
        {
            img: ImgFeedBackPatricia,
            text: i18n.t('feedback.customers.3.text'),
            name: i18n.t('feedback.customers.3.name'),
            socialMedia: '',
            link: ''
        },
        {
            img: ImgFeedBackCristiane,
            text: i18n.t('feedback.customers.4.text'),
            name: i18n.t('feedback.customers.4.name'),
            socialMedia: '',
            link: ''
        },
        {
            img: ImgFeedBackRita,
            text: i18n.t('feedback.customers.5.text'),
            name: i18n.t('feedback.customers.5.name'),
            socialMedia: '',
            link: ''
        },
        {
            img: ImgFeedBackMelissa,
            text: i18n.t('feedback.customers.6.text'),
            name: i18n.t('feedback.customers.6.name'),
            socialMedia: '',
            link: ''
        },
    ]

    function handleScroll(event){
        let currentScroll = event.currentTarget.scrollLeft

        switch(currentScroll){
            case 0 :
                setCurrentIndex(0)
                break
            case 360 : 
                setCurrentIndex(1)
                break
            case 720 : 
                setCurrentIndex(2)
                break
            case 1080 : 
                setCurrentIndex(3)
                break
            case 1440 : 
                setCurrentIndex(4)
                break
            case 1800 : 
                setCurrentIndex(5)
                break
        }

        
        const TotalWalk = (currentScroll / 3.6) * -1
        setAvatarPosition(TotalWalk)
    }

    function handlePushLeft(){
        pushTextLeft()
        pushAvatarLeft()
    }

    function handlePushRight(){
        pushTextRight()
        pushAvatarRight()
    }

    function pushTextLeft(){
        if(currentIndex < feedbackDemoiments.length -1){
            setCarrucelPosition(carrucelPosition - 930)
            setCurrentIndex(currentIndex + 1)
        }
    }

    function pushTextRight(){
        if(carrucelPosition < 0){
            setCarrucelPosition(carrucelPosition + 930)
            setCurrentIndex(currentIndex - 1)          
        }
    }

    function pushAvatarLeft(){
        if(currentIndex < feedbackDemoiments.length -1){
            setAvatarPosition(avatarPosition - 100)
        }

    }

    function pushAvatarRight(){
        if(carrucelPosition < 0){
            setAvatarPosition(avatarPosition + 100)
        }
    }


    return(
        <FeedbackContainer>

            <FeedBackContent>

                <LeftButton>
                    <button onClick={handlePushRight}>
                        <img src={ArrowButton} alt="" />
                    </button>
                </LeftButton>

                <Carrucel>

                    <h2>{i18n.t('feedback.title')}</h2>

                    <FeedBackArea>

                        <Avatar>
                            <AvatarContainer>
                                <AvatarSlider style={{left: avatarPosition + 'px'}}>
                                    {feedbackDemoiments.map((avatarImage, index) => (
                                        <>                                                                                        
                                            <AvatarImg src={avatarImage.img} alt="" noSelected={(currentIndex != index) && ('true')} />
                                        </>
                                    ))}
                                </AvatarSlider>
                            </AvatarContainer>
                        </Avatar>

                        <FeedbackText onScroll={event => handleScroll(event)}>
                            <Slider style={{left: carrucelPosition + 'px'}}>
                                {feedbackDemoiments.map(demoiment => (

                                <TextContainer >

                                    <p>
                                        "{demoiment.text}"          
                                    </p>

                                    <Perfil>
                                        <h4>{demoiment.name}</h4>
                                        <p>{demoiment.socialMedia}</p>
                                    </Perfil>

                                </TextContainer>       

                                ))}

                            </Slider>
                        </FeedbackText>

                    </FeedBackArea>

                </Carrucel>

                <RightButton>
                    <button onClick={handlePushLeft}>
                        <img src={ArrowButton} alt="" />
                    </button>
                </RightButton>


            </FeedBackContent>

        </FeedbackContainer>
    )
}