import styled from "styled-components";
import Background from '../../asset/img/background-feedback.jpg'
import BackgroundMobile from '../../asset/img/landpageImg/background-Landpage-header-mobile.jpg'

export const LandPageHeader = styled.section`

    width: 100%;
    height: 702px;
    display: flex;
    justify-content: center;
    background: url(${Background}) no-repeat top;

    @media screen and (max-width: 480px){
        background: url(${BackgroundMobile}) no-repeat top;
        background-size: contain;
    }
`

export const LandPageHeaderContainer = styled.div`

    width: var(--container-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;

    @media screen and (max-width: 480px){
        width: 100%;

        & img{
            width: 200px;
        }
    }

`
export const LanguageChange = styled.div`

    width: 570px;
    display: flex;
    justify-content: flex-end;
    gap: 14px;

    & div{
        width: 30px;
        height: 30px;
        border-radius: 100% ;
        cursor: pointer;

        img{
            width: 30px;
            height: 30px;
        }
    }

    & div:hover{
        border: 1px solid #FFD67D;
    }

    @media screen and (max-width:480px){
        justify-content: flex-end;
        width: 100%;
        margin-right: 20px;
    }

`

export const LanguagePtbr = styled.div`

    
    border: 1px solid ${props => props.selected ? ('#FFD67D;') : ('transparent')};



    `

export const LanguageEnus = styled.div`

    border: 1px solid ${props => props.selected ? ('#FFD67D;') : ('transparent')};

`
