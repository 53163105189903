import styled from "styled-components";
import BackgroundOurPriority from '../../asset/img/background-about-ourpriority.jpg'

export const OurPriorityContainer = styled.section`

    width: 100%;
    height: 659px;
    display: flex;
    justify-content: center;
    background: url(${BackgroundOurPriority}) no-repeat center;
    background-size: cover;

        @media screen and (max-width: 480px){
            height: auto;
            padding-bottom: 120px;
        }


`
export const OurPriorityContent = styled.div`

    width: var(--container-width);
    margin-top: 120px;

    & h2{
        font-size: var(--text-large);
        font-weight: var(--text-bold);
        color: var(--white);
        text-align: center;

    }    

    & h4{
        font-size: var(--text-small);
        font-weight: var(--text-light);
        color: var(--yellow-100);
        text-align: center;
        margin-top: 8px;
    }

    @media screen and (max-width: 480px){
        width: 100%;
    }

`

export const OurPriorityText = styled.div`

    display: flex;
    justify-content: flex-end;

    & div{
        margin-top: 80px;
        width: 570px;

        & p{        
        font-weight: var(--text-light);
        color: var(--white);
        text-align: justify;
        margin-bottom: 24px;

            &:nth-last-child(1){
                margin-bottom: 0px;
            }

        }

    }

        @media screen and (max-width: 480px){

            & div{
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
            }
        }


`