import React from "react";
import { InsurersContainer, InsurersContent, Carrucel, InsurerText, Box} from './style'
import {i18n} from '../../translate/i18n'

import Imgaetna from '../../asset/img/insurers/ins-aetna.jpg'
import Imgbluecross from '../../asset/img/insurers/ins-bluecross.jpg'
import Imgcigna from '../../asset/img/insurers/ins-cigna.jpg'
import Imgkaiser from '../../asset/img/insurers/ins-kaiser.jpg'
import Imgmolina from '../../asset/img/insurers/ins-molina.jpg'
import Imgoscar from '../../asset/img/insurers/ins-oscar.jpg'
import Imgunitedhealthcare from '../../asset/img/insurers/ins-unitedhealthcare.jpg'
import ImgAllState from '../../asset/img/insurers/ins-allstate.jpg'
import ImgAmericanNational from '../../asset/img/insurers/ins-americam-national.jpg'
import ImgNationalLife from '../../asset/img/insurers/ins-national-life.jpg'
import ImgProcessive from '../../asset/img/insurers/ins-profressive.jpg'
import ImgKemper from '../../asset/img/insurers/ins-kemper.jpg'
import ImgDairyland from '../../asset/img/insurers/ins-dairyland.jpg'
import ImgForesters from '../../asset/img/insurers/ins-foresters.jpg'
import ImgTravelers from '../../asset/img/insurers/ins-travelers.jpg'

export default function InsurersCarrucel(){
    return(
        <InsurersContainer>
            <InsurersContent>
                <InsurerText>
                <h2>
                    {i18n.t('insuranceCarrucel.carrucelTitle')}
                </h2>
                </InsurerText>     
            </InsurersContent>      
            <Carrucel>
                <Box>
                    <img src={Imgaetna} alt="" />
                    <img src={Imgbluecross} alt="" />
                    <img src={Imgcigna} alt="" />
                    <img src={Imgkaiser} alt="" />
                    <img src={Imgmolina} alt="" />
                    <img src={Imgoscar} alt="" />
                    <img src={Imgunitedhealthcare} alt="" />
                    <img src={ImgAllState} alt="" />
                    <img src={ImgAmericanNational} alt="" />
                    <img src={ImgNationalLife} alt="" />
                    <img src={ImgProcessive} alt="" />
                    <img src={ImgKemper} alt="" />
                    <img src={ImgDairyland} alt="" />
                    <img src={ImgForesters} alt="" />
                    <img src={ImgTravelers} alt="" />
                </Box>
                <Box>
                    <img src={Imgaetna} alt="" />
                    <img src={Imgbluecross} alt="" />
                    <img src={Imgcigna} alt="" />
                    <img src={Imgkaiser} alt="" />
                    <img src={Imgmolina} alt="" />
                    <img src={Imgoscar} alt="" />
                    <img src={Imgunitedhealthcare} alt="" />
                    <img src={ImgAllState} alt="" />
                    <img src={ImgAmericanNational} alt="" />
                    <img src={ImgNationalLife} alt="" />
                    <img src={ImgProcessive} alt="" />
                    <img src={ImgKemper} alt="" />
                    <img src={ImgDairyland} alt="" />
                    <img src={ImgForesters} alt="" />
                    <img src={ImgTravelers} alt="" />
                </Box>

            </Carrucel>      
        </InsurersContainer>
    )
}