export const health = {
    pageTitle: 'Health',
    obamacare: {
        title: 'Obamacare',
        subTitle: 'Entendendo o Affordable Care Act (ACA)',
        text1: 'Ao contrário do que muitos pensam, o Obamacare não é um seguro de saúde oferecido pelo governo. ObamaCare é um apelido para a lei de saúde chamada The Patient Protection and Affordable Care Act (PPACA). Às vezes é chamado de Affordable Care Act (ACA) para abreviar',
        text2: 'O Presidente Barack Obama sancionou o Affordable Care Act em 23 de março de 2010. Apesar de algumas mudanças, muitas tentativas de revogação e alguns processos judiciais importantes, a ACA ainda é a “law of the land” hoje.',
        button: 'Formulário de Cotação',
        title2: 'O que o ObamaCare faz?',
        text3: 'O Affordable Care Act visa aumentar a qualidade, disponibilidade e acessibilidade do seguro de saúde privado e público para dezenas de milhões de Americanos sem seguro por meio de suas muitas disposições, que incluem novos regulamentos, impostos, mandatos e subsídios.',
        text4: 'A lei também visa conter o crescimento dos gastos com saúde nos Estados Unidos, que vem crescendo a um ritmo insustentável. Mais especificamente, o ObamaCare oferece uma série de novos benefícios, direitos e proteções, tais como:',
        textBox: {
            1: 'Permitir que jovens permaneçam no plano dos pais até os 26 anos',
            2: 'Impedir que as seguradoras neguem cobertura ou cobrem mais com base no estado de saúde',
            3: 'Impedir que as companhias de seguros o abandonem quando estiver doente ou se cometer um erro honesto em sua inscrição',
            4: 'Prevenção da discriminação de gênero',
            5: 'Impedir que as seguradoras imponham aumentos injustificados das taxas',
            6: 'Expandindo a cobertura para dezenas de milhões subsidiando os custos do seguro saúde por meio dos Marketplaces de Seguros de Saúde (HealthCare.Gov e os Marketplaces estatais)',
            7: 'Permitir que jovens permaneçam no plano dos pais até os 26 anos',
            8: 'Tornando o CHIP mais fácil para as crianças obterem',
            9: 'Dar-lhe o direito a um recurso rápido das decisões da companhia de seguros',
            10: 'Expansão do Medicaid para milhões em estados que optaram por expandir o programa',
            11: 'Acabar com os limites vitalícios e anuais em dólares',
            12: 'Fornecer incentivos fiscais para pequenas empresas para oferecer seguro de saúde a seus funcionários',
            13: 'Exigir que grandes empresas garantam cobertura de saude aos funcionários',
            14: 'Exigir que todas as seguradoras cubram pessoas com condições pré-existentes',
            15: 'Garantir que todos os planos cubram benefícios mínimos, como limites de compartilhamento de custos e dez benefícios essenciais, incluindo cuidados preventivos gratuitos, serviços OB-GYN sem encaminhamentos, controle de natalidade gratuito e cobertura para atendimentos de emergência fora da rede',
        }
        
    },
    groupHealth: {
        title: 'Group Health Insurance',
        subTitle: 'Oferecer cobertura de saúde é uma decisão importante para o seu negócio.',
        text1: 'Nosso portfólio de seguros de saúde para pequenas empresas inclui uma ampla variedade de opções de planos, além de ampla cobertura de rede para grupos de funcionários. Conectamos e coordenamos todos os nossos benefícios para trabalharmos juntos e aprimorarmos todos os aspectos do cuidado aos seus funcionários. Isso ajuda a reduzir seus custos gerais e facilita o gerenciamento de benefícios, ao mesmo tempo em que agrega mais valor ao seu time.',
        text2: 'Cobertura médica e de farmácia: todos os nossos planos de seguro médico para pequenas empresas apresentam cobertura integrada de farmácia. Os planos são projetados para funcionar perfeitamente em conjunto, para que médicos e profissionais médicos possam oferecer o atendimento certo, no ambiente certo, no momento certo.',
        button: 'Formulário de Cotação',
        questions: {
            1:{
                title: 'Quem será contemplado?',
                text: 'Vamos ajudar você a considerar quais são as necessidades de seus funcionários e seus dependentes para encontrar um plano que atenda às diversas necessidades médicas e financeiras do grupo.'
            },
            2: {
                title: 'Quanto de compartilhamento de custos você pode pagar?',
                text: 'Os premium do seguro de saúde para pequenas empresas são pagos pelos funcionários e pelo empregador. Nossa equipe de agentes ira ajudar você a entender como funciona o compartilhamento de custos entre sua empresa e seu funcionário.'
            }
        },
        subTitle2: 'Há muitos fatores a serem considerados ao avaliar suas opções de seguro de saúde para pequenas empresas',
        text3: 'Na Royal Eagle Insurance Group recomendamos usar os 5 critérios a seguir para encontrar planos que melhor atendam às suas necessidades:',
        textBox: {
            1: 'Monthly premium: saiba o que você e seus funcionários poderão pagar mensalmente',
            2: 'Cobertura de medicamentos',
            3: 'Redes de provedores médicos.',
            4: 'Deductible, copay e coinsurance: certifique-se de que esses tipos de pagamentos sejam gerenciáveis para você e seus funcionários quando você receber cuidados médicos.',
            5: 'Vision & Dental: é possivel adicionar cobertura para visão e atendimento odontológico para garantir que seus funcionários estejam totalmente cobertos.',
        },
        callQuotation: {
            title: 'Na Royal Eagle somos especialistas em Group Health Insurance',
            text: 'Nossos agentes licenciados podem fornecer recomendações personalizadas e orientá-lo a escolher planos de saúde para sua empresa de forma simples, rápida e financeiramente acessível.',
            button: 'Formulário de Cotação'
        }
    },

}