import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import './slideStyle.css'

import { Title, LandPageIntroduction, Container, IntroductionText, LandPageCarousel } from './style'

import ImgEdimara from '../../asset/img/landpageImg/Edimara.jpg'
import ImgEstudanteF1 from '../../asset/img/landpageImg/Estudante-F1.jpg'
import ImgEstudanteF12 from '../../asset/img/landpageImg/Estudante-F1-2.jpg'
import ImgMedicare from '../../asset/img/landpageImg/Medicare.jpg'
import ImgObamacare from '../../asset/img/landpageImg/Obamacare.jpg'
import { Pagination } from 'swiper/modules'


export default function LandpageIntroduction(){

    const windowSize = window.innerWidth

    return(
        <>
           <LandPageIntroduction>
                <Container>

                    <Title>
                        <h2>Royal Eagle Insurance</h2>
                    </Title>

                    <IntroductionText>
                        <p>
                            A Royal Eagle Insurance Group é uma empresa que  quer transformar suas preocupações em tranquilidade.  Nosso atendimento é totalmente focado em atender as  suas necessidades com sucesso.
                        </p>
                    </IntroductionText>
                   
                    <Swiper                        
                        slidesPerView={'auto'}
                        spaceBetween={20}
                        centeredSlides={window.innerWidth > 480 ? false : true}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >                        
                        <SwiperSlide><img src={ImgEdimara} alt="Edimara Pasinato" /></SwiperSlide>
                        <SwiperSlide><img src={ImgEstudanteF1} alt="Estudante F1 tem direito a Obamacare" /></SwiperSlide>
                        <SwiperSlide><img src={ImgEstudanteF12} alt="Estudante F1" /></SwiperSlide>
                        <SwiperSlide><img src={ImgMedicare} alt="Medicare" /></SwiperSlide>
                        <SwiperSlide><img src={ImgObamacare} alt="Obamacare" /></SwiperSlide>
                       
                    </Swiper>
                </Container>
           </LandPageIntroduction>
        </>
    )

}

