import React from "react";
import AutoContent from "../components/AutoContent";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Header from "../components/Header";

export default function Auto(){
    return(
        <>        
            <Header />

            <main>

                <AutoContent />

                <Form />

            </main>

            <Footer />

        </>
    )
}