import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import OurPriority from "../components/OurPriority";
import OurTeam from "../components/OurTeam";
import OurValuesContent from "../components/OurValuesContent";

export default function About(){
    return(
        <>        
            <Header />

            <main>

                <OurValuesContent />

                <OurPriority />

                <OurTeam />

            </main>

            <Footer />

        </>
    )
}