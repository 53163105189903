import React from "react";
import Lottie from "react-lottie";
import AnimationData from '../../asset/video/computer-animation.json'
import ButtonPrimary from "../ButtonPrimary";
import { i18n } from "../../translate/i18n";
import { FormAnimation, FormContainer, FormContent, FormFields, FormInputs, FormTitle, LottieContainer, WhiteOnTop, InputRow, LottieContainerMobile } from './style'

export default function FormLandpage(){

    function sendEmail(event){

        event.preventDefault()

        let url = "https://wa.me/13467450578?text=" // Seu numero
        + "Formulário de Contato" + "%0a" // Mensagem personalizada
        + "%0a" // Quebra de linha
        + "Nome: " + event.target.name.value + "%0a" // Dados do formulário
        + "Sobrenome: " + event.target.lastname.value + "%0a" // Dados do formulário
        + "Telefone: " + event.target.phone.value + "%0a"
        + "E-mail: " + event.target.email.value + "%0a"
        + "Tipo de Seguro: " + event.target.insurancetype.value + "%0a"
        + "Mensagem: " + event.target.message.value;
        window.open(url, '_blank').focus();

        for(let i = 0; i < event.target.length; i++){
            event.target.elements[i].value = ""
        }

    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: AnimationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }

    return(
        <FormContainer>
            <FormContent>

                <FormAnimation>
                    <WhiteOnTop></WhiteOnTop>
                    <LottieContainer>
                        <Lottie options={defaultOptions} width="974px" isClickToPauseDisabled/>
                    </LottieContainer>
                    <LottieContainerMobile>
                        <Lottie options={defaultOptions} width="100%" isClickToPauseDisabled/>
                    </LottieContainerMobile>
                </FormAnimation>

                <FormFields>

                    <FormTitle>

                        <h2>
                            Assessoria e Cotação gratuita!
                        </h2>

                      
                    </FormTitle>

                    <FormInputs onSubmit={sendEmail}>
                  
                        <InputRow>
                            <label>
                                <p>Nome</p> 
                                <input type="text" name="name" required />
                            </label>

                            <label>
                                <p>Sobrenome</p>
                                <input type="text" name="lastname" required />
                            </label>
                        </InputRow>
                        <InputRow>
                            <label>
                                <p>Telefone</p>
                                <input type="tel" name="phone" required />
                            </label>

                            <label>
                                <p>E-mail</p> 
                                <input type="email" name="email" required />
                            </label>
                        </InputRow>

                        <label>
                            <p>Tipo de Seguro</p> 
                            <select name="insurancetype" id="" defaultValue="" >
                                <option value="" disabled>Escolha o tipo de seguro</option>
                                <option value="health">Health</option>
                                <option value="life">Life</option>
                                <option value="travel">Travel</option>
                                <option value="auto">Auto</option>
                                <option value="home">Homeowners</option>
                                <option value="business">Business & Commercial</option>
                                <option value="truck">Truck</option>
                            </select>
                        </label>

                        <label>
                            Mensagem
                            <textarea name="message" required />
                        </label>
                        <div>
                            <ButtonPrimary type={'large'}>Enviar</ButtonPrimary>
                        </div>

                    </FormInputs>
                </FormFields>
            </FormContent>
        </FormContainer>
    )
}