import React from "react";
import { ImageContent, ProducerContainer, ProducerContent, ProducerImage, ProducerInfo } from './style'
import ButtonPrimary from '../ButtonPrimary'
import { i18n } from "../../translate/i18n";

import ImgEdimaraHome from '../../asset/img/Edimara-home.png'
import { Link } from "react-router-dom";

export default function InsuranceProducer(){
    return(
        <ProducerContainer>

            <ProducerContent>
                <ProducerInfo>
                    <div>
                        <h2>{i18n.t('insuranceProducer.title')}</h2>
                        <h4>{i18n.t('insuranceProducer.subTitle')}</h4>
                    </div>

                    <p>
                        {i18n.t('insuranceProducer.text1')}
                    </p>
                    <p>
                        {i18n.t('insuranceProducer.text2')}
                    </p>
                    <Link to="/about"
                        onClick={() => {
                            window.scroll({
                              top: 0,
                              left: 0,
                            });
                        }}
                    >
                        <ButtonPrimary type="large">{i18n.t('insuranceProducer.button')}</ButtonPrimary>
                    </Link>

                </ProducerInfo>
                <ProducerImage>
                    <ImageContent>
                        <img src={ImgEdimaraHome} alt={i18n.t('insuranceProducer.image')} />
                    </ImageContent>
                </ProducerImage>
            </ProducerContent>

        </ProducerContainer>
    )
}