import styled from "styled-components";

import BackgroundPurple from '../../asset/img/background-purple.svg'

export const InfirmationContainer = styled.section`

    width: 100%;
    margin-top: -105px;
    display: flex;
    justify-content: center;
    background: url(${BackgroundPurple}) no-repeat left top;

    @media screen and (max-width: 480px){
        margin-top: -60px;
    }

`

export const InformationContent = styled.div`

    width: var(--container-width);
    display: flex;
    justify-content: space-between;
    padding-top: 225px;

    @media (max-width: 480px) {
        width: 100%;
        justify-content: space-around;
        flex-wrap: wrap;
        row-gap: 30px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 180px;
    }

`

export const InforCard = styled.div`

    width: 309px;
    border: 1px solid var(--blue-900);
    border-radius: 12px;
    padding: 80px 30px 80px 30px;
    text-align: center;
    position: relative;
    z-index: 2;

    & h4{
        font-size: var(--text-small);
        color: var(--blue-1000);
        margin-bottom: 24px;
    }

    & img{
        margin-bottom: 40px;
    }

    & p span{
        color: var(--blue-700);
    }

    & button{
        white-space: pre-line;
        text-transform: uppercase;
    }

    @media screen and (max-width: 480px){
        width: 100%;
    }

`

export const InforCardText = styled.div`

    margin-bottom: 40px;

    & p:nth-child(1){
        margin-bottom: 24px;
    }

`

export const PhoneNumber = styled.div`

    font-size: var(--text-small);
    font-weight: var(--text-bold);
    color: var(--blue-700)

`

export const SpecialistText = styled.div`

    margin-bottom: 80px;

    & p{
        font-size: var(--text-small);
    }

`

export const Timeinfo = styled.div`


    & div{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--blue-500);
        padding: 16px 0px;
    }

    & div:nth-last-child(1){
        border: none;
    }

    & div:nth-child(1){
        padding-top: 0px;
    }

`

