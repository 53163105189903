export const home = {
    pageTitle: 'Homeowners',
    text1: 'Sua apólice de seguro residencial cobre sua casa, bens pessoais e outras estruturas em sua propriedade. Ele também fornece cobertura de responsabilidade pessoal se alguém for ferido em sua propriedade ou você ou um membro da família for responsável por danos ou ferimentos à propriedade de terceiros.',
    button: 'Formulário de Cotação',
    image1: 'Casa',
    subTitle: 'Mais sobre Homeowners Insurance',
    image2: 'conjunto de casas',
    text2: 'É crucial obter a apólice de seguro residencial certa para sua situação. Converse com conosco sobre suas opções de seguro residencial. Dependendo de onde você mora, você também pode precisar de cobertura adicional para uma possível inundação ou terremoto.',
    question: {
        title: 'Que tipo de seguro residencial devo comprar?',
        subTitle: 'Você tem três opções principais para o seguro residencial:'
    },
    autoInsuranceTypes: {
        1: {
            title: 'Actual cash value (ACV) insurance',
            text: 'Cobre o custo da casa e seus pertences após a depreciação. Quando você faz uma reclamação sobre uma apólice de seguro residencial ACV, a seguradora reembolsa suas perdas, mas apenas até o valor atual.'
        },
        2: {
            title: 'Replacement cost insurance',
            text: 'Cobre o valor real em dinheiro de sua casa e bens, mas o faz sem deduzir a depreciação. Esta cobertura permite-lhe reconstruir ou reparar a sua casa e substituir os seus bens pelo seu valor atual.'
        },
        3: {
            title: 'Guaranteed replacement cost insurance',
            text: 'Fornece uma proteção extra projetada para resguardar seu patrimônio da inflação. Esta cobertura paga os custos de reparo ou substituição de nossa casa e bens, mesmo que os custos excedam os limites de sua apólice. Algumas seguradoras podem oferecer substituição estendida com um teto de 20% a 25% a mais do que o limite da apólice.'
        }
    },
    callQuotation: {
        title: 'Obtenha um seguro residencial para atender às suas necessidades',
        text: 'A cobertura de seguro residencial protege um de seus ativos mais valiosos. Entre em contato com a Royal Eagle Insurance Group hoje para obter uma cotação personalizada para o seguro residencial.',
        button: 'Formulário de Cotação'
    }
}