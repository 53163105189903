export const formQuote = {
    title1: 'Do you have any questions?\n Please get in touch',
    title2: 'Request your insurance quote today and see how we can help you get the peace of mind you deserve.',
    title3 : 'Free advice and quotation!',
    name: 'Name',
    lastName: 'Last Name',
    phone: 'Phone',
    email: 'E-mail',
    educationalLevel: 'Educational Level',
    profession: 'Profession',
    highSchool: 'High School',
    higherEducation: 'Higher Education',
    graduateDegree: 'Graduate Degree',
    mastersDegree: 'Master\'s Degree',
    doctorate: 'Doctorate',
    insuranceType: 'Type of Insurance',
    insuraceTypePlacehoder: 'Choose the type of insurance',
    educationalLevelPlacehoder: 'Choose the educational Level',
    message: 'Message',
    button: 'Send',
    agreeNotifications: 'I agree to receive notifications via SMS, alerts and communications from Royal Eagle Insurance.',
    messageSent: 'Message sent!',
    touchSoon: 'We will be in touch soon.',
    messageNotSent: 'Message was not sent!',
    againLater: 'Please try again later.'

}