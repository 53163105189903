export const contact = {
    pageTitle: 'Contact',
    subTitle: 'Let\'s talk,\n we are at your disposal!',
    text: 'Do not hesitate to contact us by phone, email or by filling out the contact form below. We\'ll be happy to answer all of your questions and provide personalized advice to help you make the best decision for your insurance needs.',
    phone: 'Telephone',
    mail: 'E-mail',
    location: 'Location',
    contactPhone: {
        phone: '+1(346) 351-9372',
        phoneLink: 'https://wa.me/13463519372',
    },

}