import styled from "styled-components";
import BackgroundPurple from '../../asset/img/background-purple.svg'

export const Quote = styled.section`

    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: -105px;
    background: url(${BackgroundPurple}) no-repeat left top;

    @media screen and (max-width: 480px){
        justify-content: flex-start;
        margin-top: -60px;
    }

`

export const QuoteContainer = styled.div`

    width: var(--container-width);
    padding-top: 225px;

    @media screen and (max-width: 480px){
        width: 100%;
        padding-top: 180px;
    }
`

export const Title = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 80px;

    & h1{
        font-size: var(--text-xlarge);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        margin-left: 30px
    }

`

export const LeftArticle = styled.article`

    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 130px;
    padding-bottom: 120px;

    @media screen and (max-width: 480px){
        flex-direction: column;
        gap: 80px;
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const TextContent = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;    

    & h4 {
        width: 470px;
        font-size: var(--text-small);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        margin-bottom: 40px;
    }

    & p{
        width: 470px;
        text-align: justify;
    }

    & button{
        margin-top: 80px;
    }

    @media screen and (max-width: 480px){
        align-items: center;
        order: 2;

        & p{
            width: 100%;
        }

        & h4{
            width: 100%;
        }

    }
`

export const ImgContent = styled.div`

    width: 467px;
    

    & > div{
        width: 786px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        ${props => props.left && ('margin-left: -319px;')}
        
    }

    & img{
        border-radius: 12px;
    }

    @media screen and (max-width: 480px){
        width: 100%;
        order: 1;

        & > div{
            width: 100%;
            ${props => props.left && ('margin-left: 0px;')}
        }

        & img{
            width: 100%;
        }
    }
`

export const RightImgEffect = styled.div`

    width: 399px;
    height: 319px;
    background: linear-gradient(270deg, #FFFFFF 1%, #EDEAFF 90%);
    margin-left: -10px;
    position: relative;
    z-index: -1;

    @media screen and (max-width: 480px){
        display: none;
    }
`

export const RightArticle = styled.div`

    display: flex;
    justify-content: flex-end;
    gap: 130px;
    padding-bottom: 120px;

`

export const LeftImgEffect = styled.div`

    width: 399px;
    height: 319px;
    background: linear-gradient(270deg, #FFFFFF 1%, #EDEAFF 90%);
    transform: rotate(180deg);
    margin-right: -10px;
    position: relative;
    z-index: -1;

`

