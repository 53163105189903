import React, { useState } from "react";

import { PopupAnswer, PopupAnswerContainer, PopupBackground, Question, QuestionCard, QuestionCardsContainer, QuestionContainer, Title } from './style'

import { i18n } from "../../translate/i18n";

import BtnClose from '../../asset/img/btn-close.svg'
import AnswerId from "./Answers/AnswerId";

export default function QuestionContent(){


    const [answerCard, setAnswerCard] = useState(false)
    const [answerPage, setAnswerPage] = useState(0)

    function handleOpenCard(questionid){
        setAnswerPage(questionid)
        answerCard ? setAnswerCard(false) : setAnswerCard(true) 
    }

    const questionsValors = [
        {            
            id: 1,
            question: i18n.t('question.questions.1.title'),
            background: 'heart'
        },
        {            
            id: 2,
            question: i18n.t('question.questions.2.title'),
            background: 'heart'
        },
        {            
            id: 3,
            question: i18n.t('question.questions.3.title'),
            background: 'heart'
        },
        {            
            id: 4,
            question: i18n.t('question.questions.4.title'),
            background: 'heart'
        },
        {            
            id: 5,
            question: i18n.t('question.questions.5.title'),
            background: 'heart'
        },
        {            
            id: 6,
            question: i18n.t('question.questions.6.title'),
            background: 'heart'
        },
        {            
            id: 7,
            question: i18n.t('question.questions.7.title'),
            background: 'heart'
        },
        {            
            id: 8,
            question: i18n.t('question.questions.8.title'),
            background: 'people'
        },
        {            
            id: 9,
            question: i18n.t('question.questions.9.title'),
            background: 'people'
        },
        {            
            id: 10,
            question: i18n.t('question.questions.10.title'),
            background: 'medic'
        },
        {            
            id: 11,
            question: i18n.t('question.questions.11.title'),
            background: 'wallet'
        },
        {            
            id: 12,
            question: i18n.t('question.questions.12.title'),
            background: 'wallet'
        },
        {            
            id: 13,
            question: i18n.t('question.questions.13.title'),
            background: 'hospital'
        },
        {            
            id: 14,
            question: i18n.t('question.questions.14.title'),
            background: 'wallet'
        },
        {            
            id: 15,
            question: i18n.t('question.questions.15.title'),
            background: 'wallet'
        },

    ]

    return(
        <Question>
            {answerCard && (
                <>
                    <PopupBackground onClick={handleOpenCard}></PopupBackground>

                    <PopupAnswer >

                        <PopupAnswerContainer >
                            <div>                        
                                <button onClick={handleOpenCard}>
                                    <img src={BtnClose} alt="" />
                                </button>
                            </div>

                            <AnswerId questionId={answerPage} />

                        </PopupAnswerContainer>

                    </PopupAnswer>
                </>
            )}

            <QuestionContainer>

                <Title>
                    <h1>{i18n.t('question.pageTitle')}</h1>
                </Title>

                <QuestionCardsContainer>

                    {questionsValors.map(card => (
                        <QuestionCard bg={card.background} onClick={event => handleOpenCard(card.id)}>
                            <p>{card.question}</p>
                        </QuestionCard>
                        
                    ))}

                </QuestionCardsContainer>

            </QuestionContainer>
        </Question>
    )
}