export const business = {
    pageTitle: 'Business & Commercial Insurance',
    image1: 'Commercial Auto',
    commercialAuto: {
        title: 'Commercial Auto',
        text: 'Whether you need to purchase a commercial automobile insurance policy will depend on the type of driving you do. A good insurance agent will ask for details about how you use the vehicles at your company, who will drive them, and whether employees, if any, are likely to drive their own cars for your company.'
    },
    generalLiability: {
        title: 'General Liability',
        text1: 'Unfortunately for every business owner, the chances of being sued have increased dramatically over the past decade. General Liability insurance can prevent a lawsuit from turning into a financial disaster by providing financial protection if your business is sued or held legally liable for damage or loss.',
        text2: 'General liability pays losses arising from actual or alleged bodily injury, property damage or personal injury on its business premises or in connection with its operations.',
        textBox: {
            1: 'Bodily injury, including the cost of care, loss of services and restitution for any death resulting from the injury',
            2: 'Property Damage Coverage for physical damage to third party property or loss of use of that property',
            3: 'Product completed operations coverage provides liability protection (damage and legal expenses up to the limit of your policy) if an injury results from something your business has done or service your business has provided',
            4: 'Reasonable use of force coverage',
            5: 'Borrowed equipment coverage',
            6: 'Liability for alcoholic beverages',
            7: 'Coverage for non-owned vehicles (such as aircraft and vessels)',
            8: 'Personal injury coverage',
            9: 'Product liability is a more specialized product liability insurance that protects your business against lawsuits arising from product-related injuries or accidents.',
            10: 'Contractual liability extends to any liability you may assume by entering into a variety of contracts.',
            11: 'Fire, lightning or explosion damage coverage',
            12: 'Water damage liability protection',
            13: 'Coverage of legal defense costs',
            14: 'Medical payment coverage',
            15: 'Advertising injury coverage',
            16: 'Specialized liability protection for specific business types WORKER\'S COMPENSATION',
        }
    },
    workersCompensation: {
        title: 'Workers Compensation Insurance',
        text1: 'Employers have a legal responsibility to their employees to make the workplace safe. However, accidents do happen even when all reasonable safety measures have been taken.',
        text2: 'It provides payments to injured workers, regardless of who was at fault in the accident, for time lost at work, and for medical and rehabilitation services. It also provides death benefits for surviving spouses and dependents.',
        text3: 'Workers compensation insurance is not health insurance; It is specifically designed for injuries sustained on the job.' 
    },
    image2: 'Workers Compensation',
    callQuotation: {
        title: 'At Royal Eagle we are specialists in Business & Commercial Insurance',
        text: 'Our licensed agents can provide personalized recommendations and guide you in choosing health plans for your business in a simple, fast and affordable way.',
        button: 'Quotation Form'
    }
}