import React from "react";
import ButtonPrimary from "../ButtonPrimary";

import { i18n } from "../../translate/i18n";

import { Travel, TravelContainer, ImgContent, LeftArticle, LeftImgEffect, RightArticle, RightImgEffect, Subtitle, TextContent, Title, GetInsurance } from './style'

import IconTravel from '../../asset/img/icon-title-travel.svg'
import ImgTravel1 from '../../asset/img/img-travel-1.jpg'
import ImgTravel2 from '../../asset/img/img-travel-2.jpg'
import { Link } from "react-router-dom";

export default function TravelContent(){
    return(
        <Travel>
            <TravelContainer>

                <Title>
                    <img src={IconTravel} alt={i18n.t('travel.pageTitle')} />
                    <h1>{i18n.t('travel.pageTitle')}</h1>
                </Title>

                <LeftArticle>
                    <TextContent>

                        <p>
                            {i18n.t('travel.text1')}
                        </p>

                        <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <ButtonPrimary type="large">
                                {i18n.t('travel.button')}
                            </ButtonPrimary>
                        </Link>

                    </TextContent>

                    <ImgContent right>

                        <div>
                            <img src={ImgTravel1} alt={i18n.t('travel.image1')} />
                            <RightImgEffect></RightImgEffect>
                        </div>

                    </ImgContent>
                </LeftArticle>

                <Subtitle>
                    <h2>{i18n.t('travel.questionTitle')}</h2>
                </Subtitle>

                <RightArticle>

                    <ImgContent left="true">
                        <div>
                            <LeftImgEffect></LeftImgEffect>
                            <img src={ImgTravel2} alt={i18n.t('travel.image2')} />                            
                        </div>
                    </ImgContent>

                    <TextContent>
                        <div>
                            <h4>{i18n.t('travel.subTitles.1.title')}</h4>
                            <p>
                                {i18n.t('travel.subTitles.1.text')}
                            </p>
                        </div>

                        <div>
                            <h4>{i18n.t('travel.subTitles.2.title')}</h4>
                            <p>
                                {i18n.t('travel.subTitles.2.text')}
                            </p>
                        </div>
                        <div>
                            <h4>{i18n.t('travel.subTitles.3.title')}</h4>
                            <p>
                                {i18n.t('travel.subTitles.3.text')}
                            </p>
                        </div>
                    </TextContent>

                </RightArticle>

                <GetInsurance>
                    <div>
                        <h4>{i18n.t('travel.callQuotation.title')}</h4>
                        <p>
                            {i18n.t('travel.callQuotation.text')}
                        </p>
                        <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <ButtonPrimary type="large">
                                {i18n.t('travel.callQuotation.button')}
                            </ButtonPrimary>
                        </Link>
                    </div>
                </GetInsurance>

            </TravelContainer>
        </Travel>
    )
}