import styled from "styled-components";
import BackgroundFooter from '../../asset/img/background-footer.jpg'
import BackgroundFooterMobile from '../../asset/img/background-footer-mobile.jpg'

export const LandPageFooter = styled.section`

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${BackgroundFooter}) no-repeat bottom;
    background-size: cover;

    @media screen and (max-width: 480px){
        align-items: flex-start;
        height: auto;
        background: url(${BackgroundFooterMobile}) no-repeat bottom center, var(--blue-1100);

    }

`

export const Content = styled.div`

    width: var(--container-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;

    @media screen and (max-width: 480px){
        width: 100%;
        flex-direction: column;
    }

`

export const SocialMeida = styled.div`
    
    margin-bottom: 40px;
    display: flex;
    gap: 30px;

`

export const Terms = styled.div`

    text-align: center;
    margin-bottom: 40px;

    & p{
        color: var(--blue-100);
    }

`

export const Copyright = styled.div`

    text-align: center;

    & p{
        color: #fff
    }

`