import styled, {keyframes} from "styled-components";

const backgroundAnimation = keyframes`

    0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

export const OurValues = styled.section`

    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: -105px;
    background: linear-gradient(90.12deg, #FFF2F2 0.11%, #EFFCFF 48.97%, #FFFDEC 99.91%);
    background-size: 400% 400%;
    animation: ${backgroundAnimation} 10s ease infinite;

    @media screen and (max-width: 480px){
        justify-content: flex-start;
        margin-top: -60px;
    }
`

export const OurValuesContainer = styled.div`

    width: var(--container-width);
    padding-top: 225px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;

    @media screen and (max-width: 480px){
        width: 100%;
        padding-top: 180px;
    }

`

export const Title = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 80px;
    width: 100%;

    & h1{
        font-size: var(--text-xlarge);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        margin-left: 30px
    }

`

export const BackgroundCircle = styled.div`

    border-radius: 50%;
    width: 500px;
    height: 500px;
    background: linear-gradient(90.12deg, #FBF8E4 0.11%, #FFEAEA 99.91%);

    @media screen and (max-width: 480px){
            width: 550px;
            height: 550px;

    }


`

export const ImgPerspectiveTopLeft = styled.div`

    position: absolute;
    margin-top: 130px;
    margin-left: -1400px;

    & img{
        border-radius: 12px;
        position: absolute;

        &:nth-child(1){
            opacity: 50%;
            margin-left: -25px;
            margin-top: 10px;
        }

        &:nth-child(2){
            opacity: 30%;
            margin-left: -35px;
            margin-top: -15px;
        }

    }

    @media screen and (max-width: 480px){
        display: none;
    }
`
export const ImgPerspectiveTopRight = styled.div`

    position: absolute;
    margin-top: 50px;
    margin-left: 600px;

    & img{
        border-radius: 12px;
        position: absolute;

        &:nth-child(1){
            opacity: 50%;
            margin-left: -15px;
            margin-top: -10px;
        }

        &:nth-child(2){
            opacity: 30%;
            margin-left: 10px;
            margin-top: -25px;
        }

    }

    @media screen and (max-width: 480px){
        display: none;
    }
`
export const ImgPerspectiveBottomLeft = styled.div`

    position: absolute;
    margin-top: 510px;
    margin-left: -1300px;

    & img{
        border-radius: 12px;
        position: absolute;

        &:nth-child(1){
            opacity: 50%;
            margin-left: -10px;
            margin-top: -15px;
        }

        &:nth-child(2){
            opacity: 30%;
            margin-left: -25px;
            margin-top: -5px;
        }

    }

    @media screen and (max-width: 480px){
        display: none;
    }
`
export const ImgPerspectiveBottomRight = styled.div`

    position: absolute;
    margin-top: 340px;
    margin-left: 800px;

    & img{
        border-radius: 12px;
        position: absolute;

        &:nth-child(1){
            opacity: 50%;
            margin-left: 10px;
            margin-top: 15px;
        }

        &:nth-child(2){
            opacity: 30%;
            margin-left: 20px;
            margin-top: 25px;
        }

    }

    @media screen and (max-width: 480px){
        display: none;
    }
`

export const CenterText = styled.div`

    margin-top: -550px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    & h2{
        font-size: var(--text-large);
        font-weight: var(--text-bold);
        color: var(--blue-700);
        white-space: pre-line;
        text-align: center;
        margin-top: 40px;
    }

    & p{
        width: 660px;
        text-align: center ;
        margin-top: 80px;
    }

    @media screen and (max-width: 480px){
        align-items: flex-start;

        & h2{
            width: 100%;
        }

        & p{
            width: 100%;
            text-align: justify;
            box-sizing: border-box;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
`

export const ValuesItens = styled.div`

    width: 100%;
    margin-top: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;

    & h4{
        font-size: var(--text-small);
        font-weight: var(--text-bold);
        color: var(--blue-700);
        margin-bottom: 40px;
    }

    & ul{
        width: 770px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;

        & li{
            padding: 12px 24px;
            border-radius: 8px;
            color: var(--white);
            font-weight: var(--text-light);
            background: linear-gradient(76.81deg, #DE57A8 -64.64%, #EF4040 68.94%, #FFB0B0 115.78%);
        }
    }
    @media screen and (max-width: 480px){

        & ul{
            width: 100%;
        }
    }
`

