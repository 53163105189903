export const informations = {

    serviceCard: {
        title:'Atendimento',
        textColor: 'A Royal Eagle Insurance Group ',
        text1: 'é uma empresa que quer transformar suas preocupações em tranquilidade. Nosso atendimento é totalmente focado em atender as suas necessidades com sucesso.',
        text2: 'Somos especialistas em seguro. Diga-nos como podemos ajudá-lo?'
    },
    expertsCard: {
        text1: 'Como especialistas em ',
        textColor: 'seguros nos Estados Unidos, ',
        text2: 'podemos fornecer o seguro certo para proteger tudo que é importante para você.',
        button: 'Obtenha Cotação e\n Assessoria Gratuita'
    },
    timeCard: {
        title: 'Horário - CST',
        week: 'Segunda á Sexta',
        weekTime: '9:00 AM - 5:00 PM',
        saturday: 'Sábado',
        saturdayTime: 'Fechado',
        sanday: 'Domingo',
        sandayTime: 'Fechado'
    }


}