import styled from "styled-components";

import BackgroundFooter from '../../asset/img/background-footer.jpg'
import BackgroundFooterMobile from '../../asset/img/background-footer-mobile.jpg'

export const FooterContainer = styled.footer`

    width: 100%;
    height: 661px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${BackgroundFooter}) no-repeat center;
    background-size: cover;

    @media screen and (max-width: 480px){
        align-items: flex-start;
        height: auto;
        background: url(${BackgroundFooterMobile}) no-repeat top center, var(--blue-1100);

    }

`

export const FooterContent = styled.div`

    width: var(--container-width);
    display: flex;
    justify-content: space-between;
    padding-top: 120px;
    padding-bottom: 120px;

    @media screen and (max-width: 480px){
        width: 100%;
        flex-direction: column;

    }

`

export const Nav = styled.nav`

    & ul{

        & li{
            font-size: var(--text-small);
            color: var(--white);
            font-weight: var(--text-bold);
            margin-bottom: 40px;
            transition: all 0.2s ease;

            &:hover{
                color: var(--yellow-100);
            }            
        }
    }

    @media screen and (max-width: 480px){
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 80px;

        & li{
            text-align: center;
        }
    }

`

export const Contact = styled.div`

    & h4{
        font-size: var(--text-small);
        color: var(--white);
        font-weight: var(--text-bold);
        margin-bottom: 40px;
    }

    & ul{
        
        & li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 30px;          
            margin-bottom: 30px;
            transition: all 0.2s ease;

            &:hover a p{
                color: var(--yellow-100);
                transition: all 0.2s ease;
            }            


            &:nth-last-child(1){
                margin-bottom: 0px;
            }
            
        
            & a{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 30px;  
            }
        }
        
        & p{
            color: var(--white);
            font-weight: var(--text-light);
        }

        & div{
            width: 40px;
        }
    }

    @media screen and (max-width: 480px){
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (max-width: 480px){
        margin-bottom: 80px;

        & h4{
            text-align: center;
        }

        & ul{
            & li{
                justify-content: center;

                    &:nth-last-child(1){
                        margin-top: 80px;
                    }


                & a{
                    flex-direction: column;
                }
            }
        }
    }


`

export const ImgSocialMedia = styled.img`
    transition: all 0.2s ease;

    &:hover{
        filter: brightness(0) invert();
    }
`

export const LogoFooter = styled.div`

    & img{
        margin-bottom: 40px;
    }

    & p{
        width: 370px;
        color: var(--white);
        font-weight: var(--text-light);
    }

    @media screen and (max-width: 480px){
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;

        & a{
            text-align: center;
        }

        & img{
            width: 80%;
        }

        & p{
            width: auto;
            text-align: justify;
        }
    }

`

export const FooterCopyRight = styled.div`

    width: var(--container-width);
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
        
    & div{
        height: 100%;
        width: 970px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #FFF;
        margin-top: -1px;
    }

    & p{
        
        color: var(--white);
        font-weight: var(--text-light);
    }

    @media screen and (max-width: 480px){
        width: 100%;
        height: auto;
        

        & div{
            width: 80%;
            height: auto;
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

`