export const header = {
    menuContact: {
        phone: '+1(346) 351-9858',
        phoneLink: 'https://wa.me/13463519858',
    },
    menu: {
        service: 'Serviços',
        commonQuestions: 'Perguntas Frequentes',
        quote: 'Cotação',
        about: 'Sobre',
        contact: 'Contato'
    },
    bannerTitle: {
        normalText: 'Temos orgulho de cuidar de suas necessidades de seguro e isso se estende a ',
        boldText: 'TODOS os TIPOS DE SEGUROS'
    },
    bannerSubTitle: {
        normalText: 'Mantenha tudo sob controle na ',
        boldText: 'Royal Eagle Insurance'
    },
    insuranceCard: {
        health: 'Ter um seguro de saúde pode fornecer proteção financeira contra altas despesas médicas e acesso a uma gama mais ampla de serviços médicos.',
        life: 'O seguro de vida é um tipo de seguro que oferece proteção financeira aos seus entes queridos em caso de morte. Ele paga uma quantia fixa de dinheiro aos seus beneficiários para ajudar a cobrir despesas como custos de funeral, dívidas e despesas de subsistência.',
        travel: 'Podemos ajudá-lo a encontrar uma apólice de seguro de viagem adequada que atenda às suas necessidades. Você pode comparar todas as suas opções de seguro de viagem, obter cobertura on-line e viajar sem preocupações.',
        auto: 'Na Royal Eagle Insurance, entendemos que não existe uma solução única para todas as coberturas de seguro de automóveis. Trabalhamos individualmente com nossos clientes para garantir que eles tenham a cobertura de que mais precisam com economia e segurança.',
        home: 'Seu seguro residencial pode ajudar a protegê-lo dos altos custos de reparo ou substituição de sua casa após um acidente em sua propriedade, evitando consequências financeiras se sua casa for, por exemplo, danificada por um incêndio ou outro evento coberto. O seguro residencial ajuda a fazer os reparos necessários para restaurar o valor de sua casa.',
        business: 'Business insurance refere-se às várias linhas de cobertura que as empresas podem precisar para se proteger adequadamente contra os riscos que enfrentam. Nos vamos ajudar você a identificar os tipos de cobertura e valores adequados de seguro empresarial são necessários para garantir a segurança do seu negócio.',
        truck: 'Ter a cobertura de seguro certa é fundamental para qualquer caminhoneiro/proprietário independente. Sem ele, um único incidente poderia colocá-lo fora do negócio.'
    }
}