export const ourTeam = {
    foundedCompany: {
        title: 'We work to make sure that everything will be fine with you and your family!',
        text: 'ROYAL EAGLE INSURANCE was founded with the aim of humanizing the relationship between insurance and the insured, offering transparency in the relationship and constant care that provides the safest journey for you and your family in the United States, enabling each customer to feel single.'
    },
    team: {
        edimara: {
            title:'Edimara Pasinato Dal Pozzo',
            subTitle: 'Insurance Producer',
            text1: 'Acts as Insurance Producer in the United States for General Lines, Life, Health, HMO, Annuities Accidents, Property, Casualty & Personal Lines, leading a highly qualified team committed to the quality of services, agility, credibility and orientation to customer needs.',
            text2: 'She is responsible for a broad portfolio of INSURANCE products and services that benefit and protect individuals, families, businesses, assets, and groups of employers and employees. At the head of the Royal Eagle Insurance Group, Edimara\'s purpose is the development and execution of humanized, enlightening and personalized service management, which establishes a relationship of trust between the insurance company and the insured, in addition to transparency in the relationship, honesty and care. constant for each customer.',
            text3: 'In addition, she is active in a non-governmental and non-profit organization that develops educational, social and environmental methodologies and technologies, promoting educational inclusion and contributing to the socio-environmental development of the community in which it is inserted.',
            certifications: {
                title: 'Insurance Certifications',
                number: 8,
                list: {
                    1: 'General Lines',
                    2: 'Life',
                    3: 'Health',
                    4: 'HMO',
                    5: 'Annuities',
                    6: 'Accidents',
                    7: 'Property',
                    8: 'Casualty & Personal Lines'    
                }
            },
            bachelors: {
                title: 'bachelors',
                number: 2,
                list: {
                    1: 'Business Administration',
                    2: 'Human Resources',    
                }
            },
            postgraduate: {
                title: 'Postgraduate',
                number: 3,
                list: {
                    1: 'Juris Doctor',
                    2: 'Human Resources',
                    3: 'Business Administration',    
                }
            }

        },
        patricia: {
            title:'Patricia Duarte',
            subTitle: 'Licensed Insurance Agent',
            text1: 'Patricia Duarte is an insurance industry specialist with the license and experience necessary to provide the best possible service to her clients. Her mission is to build customized insurance policies for the specific needs of each client. As your insurance agent, Patricia has expertise in truck insurance and can also advise you on a variety of insurances including personal and commercial, home, business and commercial auto insurance.',
            text2: 'Born in Brazil, Patrícia has lived in the USA for 25 years having lived in Florida for twelve years and currently residing in Dallas, Texas.',
            certifications: {
                title: 'Insurance Certifications',
                number: 1,
                list: {
                    1: 'Property & Casualty',    
                }
            },
            bachelors: {
                title: 'bachelors',
                number: 2,
                list: {
                    1: 'Marketing',
                    2: 'Web Developer',    
                }
            },
        },
        fernanda: {
            title:'Fernanda Leite',
            subTitle: 'Marketing and Service',
            text1: "Fernanda's mission is to serve our clients and direct their personal demands to the Royal Eagle Insurance broker, Edimara Pasinato, for insurance quotes and application.",
            text2: 'Fernanda also manages marketing for Royal Eagle Insurance.',
            text3: 'Their excellent customer service and communication skills are a constant source of praise. Fernanda is extremely attentive and has a lot of affection and vocation in serving our customers.',
            text4: 'We are PROUD to have this excellent professional, with a huge heart and extreme competence on our Royal Eagle Team!'
        },

    }
}