import React, { useState } from "react";
import ButtonPrimary from "../ButtonPrimary";
import { i18n } from "../../translate/i18n";
import EmailJs from '@emailjs/browser';
import MessageWindow from './MessageWindow'
import { CheckboxContainer, FormContainer, FormContent, FormFields, FormInputs, FormTitle, InputRow, ButtonContainer } from './style'

export default function FormQuoteLink({type}){

    const [statusMessanger, setStatusMessanger] = useState(true)
    const [showMessage, setShowMessage] = useState(false)
    const [isStopped, setIsStopped] = useState(true)
    

    function sendEmail(event){
        let isSendMessage = ''
        event.preventDefault()
        
        EmailJs.sendForm('service_n1soz9p', 'template_l35de76', event.target, 'iEfYSYQEvwZmLle-b')
        .then((result) =>{
            console.log(result.text)
            setStatusMessanger(true)
            isSendMessage = true
            showNotification()
    
        }, (error) => {
            console.log(error.text)
            setStatusMessanger(false)
            isSendMessage = false
            showNotification()    
        })
        

        function showNotification(){

            if(isSendMessage) event.target.reset()
            setShowMessage(true)
            setIsStopped(false)
    
            const timeOut = setTimeout(() => {
                setShowMessage(false)
            }, 3000)
          
            const stopTimeOut = setTimeout(() => {
                setIsStopped(true)
            }, 4000)    
        }
        
    }

    return(
        <FormContainer>
            <FormContent>

                <MessageWindow statusMessage={statusMessanger} showMessage={showMessage} isStopped={isStopped}/>  

                <FormFields>

                    <FormTitle>
                  
                        <h2>
                            {i18n.t('formQuote.title3')}
                        </h2>
                        
                    </FormTitle>

                    <FormInputs onSubmit={sendEmail}>
                    
                        <InputRow>
                            <label>
                                <p>{i18n.t('formQuote.name')}</p> 
                                <input type="text" name="name" required />
                            </label>

                            <label>
                                <p>{i18n.t('formQuote.lastName')}</p>
                                <input type="text" name="lastname" required />
                            </label>
                        </InputRow>
                        <InputRow>
                            <label>
                                <p>{i18n.t('formQuote.phone')}</p>
                                <input type="tel" name="phone" required />
                            </label>

                            <label>
                                <p>{i18n.t('formQuote.email')}</p> 
                                <input type="email" name="email" required />
                            </label>
                        </InputRow>
                        <InputRow>
                            <label>
                                <p>{i18n.t('formQuote.educationalLevel')}</p> 
                                <select name="educationalLevel" id="" defaultValue={'DEFAULT'}>
                                    <option value="DEFAULT" disabled>{i18n.t('formQuote.educationalLevelPlacehoder')}</option>
                                    <option value="ensino médio">{i18n.t('formQuote.highSchool')}</option>
                                    <option value="superior">{i18n.t('formQuote.higherEducation')}</option>
                                    <option value="pós-graduação">{i18n.t('formQuote.graduateDegree')}</option>
                                    <option value="mestrado">{i18n.t('formQuote.mastersDegree')}</option>
                                    <option value="doutorado">{i18n.t('formQuote.doctorate')}</option>
                                </select>
                            </label>

                            <label>
                                <p>{i18n.t('formQuote.profession')}</p> 
                                <input type="text" name="profession" required />
                            </label>
                        </InputRow>

                        <label>
                            <p>{i18n.t('formQuote.insuranceType')}</p> 
                            <select name="insurancetype" id="" defaultValue={'DEFAULT'} >
                                <option value="DEFAULT" disabled>{i18n.t('form.insuraceTypePlacehoder')}</option>
                                <option value="health">Health</option>
                                <option value="life">Life</option>
                                <option value="travel">Travel</option>
                                <option value="auto">Auto</option>
                                <option value="home">Homeowners</option>
                                <option value="business">Business & Commercial</option>
                                <option value="truck">Truck</option>
                            </select>
                        </label>
                        <label>
                            <CheckboxContainer>
                                <input type="checkbox" name="agreeNotifications" />
                                <p>
                                    {i18n.t('formQuote.agreeNotifications')}
                                </p>
                            </CheckboxContainer>
                        </label>
                        <input type="hidden" name="formtype" value="Cotação" />                       
                       
                        <ButtonContainer>
                            <ButtonPrimary type={'large'}>{i18n.t('formQuote.button')}</ButtonPrimary>
                        </ButtonContainer>

                    </FormInputs>
                </FormFields>
            </FormContent>
        </FormContainer>
    )
}