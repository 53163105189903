import styled from "styled-components";

export const FormContainer = styled.section`

    width: 100%;
    display: flex;
    justify-content: center;

`

export const FormContent = styled.div`

    width: var(--container-width);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 120px;
    position: relative;

    @media screen and (max-width: 480px){
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

`

export const MessageWindowWraper = styled.div`

    z-index: 5;
    width: 100%;
    height: 100%;
    transition: 1s ease;
    visibility: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({fade}) => {
        if(fade){
            return 'opacity: 1;  visibility: visible; '
        }else{
            return 'opacity: 0;  visibility: hidden;'
        }
    }
    }

`

export const WhiteOnTop = styled.div`

    background: linear-gradient(270.96deg, #FFFFFF 23.45%, rgba(255, 255, 255, 0) 91.53%);

`

export const FormAnimation = styled.div`

    width: 570px;
    position: relative;
    z-index: -1;

    @media screen and (max-width: 480px){
        width: 100%;
    }

`

export const LottieContainer = styled.div`

    margin-left: -245px;
    padding-bottom: 57px;

    & > div svg{
        width: 974px;
    }

    @media screen and (max-width: 480px){
        margin-left: 0px;
        display: none;

    }

`

export const LottieContainerMobile = styled.div`
    display: none;

    @media screen and (max-width: 480px){
        display: block;
        padding-bottom: 57px;
        width: 100%;
    }

`

export const FormFields = styled.div`

    width: 570px;

    @media screen and (max-width: 480px){
        width: 100%;
    }
    
`

export const FormTitle = styled.div`

    margin-bottom: 80px;

    & h2{
        font-size: var(--text-large);
        font-weight: var(--text-bold);
        color: var(--blue-900);
        white-space: pre-line;
    }

    & h4{
        font-size: var(--text-small);
        font-weight: var(--text-bold);
        color: var(--blue-900);
    }

    @media screen and (max-width: 480px){
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    }

`

export const FormInputs = styled.form`

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & label{
        font-size: var(--text-small);
        color: var(--blue-900);
        margin-bottom: 24px;
        
    }

    & input{
        width: 550px;
        height: 50px;
        background-color: var(--blue-50);
        border: 1px solid var(--blue-100);
        margin-top: 18px;
        border-radius: 4px;
        padding-left: 20px;

        &:focus{
            border-color: white;
        }
    }

    & select{
        width: 570px;
        height: 52px;
        background-color: var(--blue-50);
        border: 1px solid var(--blue-100);
        margin-left: 0px;
        margin-top: 18px;
        border-radius: 4px;
        padding-left: 20px;
        color: var(--blue-700);
    }

    & textarea{
        width: 530px;
        height: 100px;
        background-color: var(--blue-50);
        border: 1px solid var(--blue-100);
        margin-top: 18px;
        border-radius: 4px;
        padding: 20px;
        resize: none;
        color: var(--blue-700);
    }

    & button{
        margin-top: 16px;
    }

    @media screen and (max-width: 480px){

        align-items: flex-end;
        padding-left: 20px;
        padding-right: 20px;

        
        & label {
            width: 100%;
        }

        & input{
            width: 93%;
        }

        & select{
            width: 100%;
        }

        & textarea{
            width: 88%;
        }

    }

`

export const InputRow = styled.div`

    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 30px;

    & label{
        width: 270px;

        & input{
            width: 250px;
        }
    }

    @media screen and (max-width: 480px){
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;

        & label{
        width: 100%;

        & input{
            width: 93%;
        }

    }

    
`