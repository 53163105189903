export const formQuote = {
    title1: 'Está com alguma duvida?\n Por favor, entre em contato',
    title2: 'Solicite sua cotação de seguro hoje mesmo e veja como podemos ajudá-lo a ter a tranquilidade que você merece.',
    title3 : 'Assessoria e Cotação gratuita!',
    name: 'Nome',
    lastName: 'Sobrenome',
    phone: 'Telefone',
    email: 'E-mail',
    educationalLevel: 'Nível de Escolaridade',
    profession: 'Profissão',
    highSchool: 'Ensino Médio',
    higherEducation: 'Ensino Superior',
    graduateDegree: 'Pós-graduação',
    mastersDegree: 'Mestrado',
    doctorate: 'Doutorado',
    insuranceType: 'Tipo de Seguro',
    insuraceTypePlacehoder: 'Escolha o tipo de seguro',
    educationalLevelPlacehoder: 'Escolha a escolaridade',
    message: 'Mensagem',
    button: 'Enviar',
    agreeNotifications: 'Aceito receber notificações via SMS, alertas e comunicações da Royal Eagle Insurance.',
    messageSent: 'Mensagem Enviada!',
    touchSoon: 'Entraremos em contato em breve.',
    messageNotSent: 'Mensagem não enviada!',
    againLater: 'Tente novamente mais tarde.'

}