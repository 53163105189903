import styled from "styled-components";
import BackgroundFeedback from '../../asset/img/background-feedback.jpg'

export const  FeedbackContainer = styled.section`

    width: 100%;
    display: flex;
    justify-content: center;
    background: url(${BackgroundFeedback}) no-repeat center;
    background-size: cover;

`

export const FeedBackContent = styled.div`

    width: var(--container-width);
    height: 702px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 480px){
        width: 100%;
        height: auto;
        padding-bottom: 120px;
    }

`

export const Carrucel = styled.div`

    padding-top: 120px;

    & h2{
        font-size: var(--text-large);
        font-weight: var(--text-bold);
        color: var(--white);
        text-align: center;
    }

        @media screen and (max-width: 480px){
            width: 100%;
        }


`

export const  LeftButton = styled.div`

    display: flex;
    align-items: center;

    & button{
        width: 60px;
        height: 60px;
        transform: rotate(180deg);
        border-radius: 50%;
        background: var(--yellow-100);
        border: none;
        cursor: pointer;

        &:hover{
            filter: brightness(80%);
        }
    }

    @media screen and (max-width: 480px){
        display: none;
    }

`

export const  RightButton = styled.div`

    display: flex;
    align-items: center;

    & button{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: var(--yellow-100);
        border: none;
        cursor: pointer;

        &:hover{
            filter: brightness(80%);
        }

    }

    @media screen and (max-width: 480px){
        display: none;
    }
`

export const  FeedBackArea = styled.div`

    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 80px;

`

export const  Avatar = styled.div`

    display: flex;
    justify-content: center;

`

export const AvatarContainer = styled.div`

    overflow: hidden;
    width: 304px;

`

export const AvatarSlider = styled.div`

    height: 104px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    padding-left: 100px;
    position: relative;
    transition: all ease-in-out 0.5s ;


`

export const AvatarImg = styled.img`

    border: 2px solid ${props => props.noSelected || ('var(--yellow-100)')};
    border-radius: 50%;
    transition: all ease-in-out 0.1s;
    width: ${props => props.noSelected && ('70px')};

`

export const FeedbackText = styled.div`

    width: 930px;
    overflow: hidden;

    @media screen and (max-width: 480px){
        width: 100%;
        overflow-x: auto;
        scroll-snap-type: x mandatory;

    }

`

export const Slider = styled.div`

    display: flex;
    justify-content: flex-start;
    position: relative;
    transition: all ease-in-out 0.5s ;

`

export const TextContainer = styled.div`

    margin-top: 80px;
    width: 930px;

    & > p{
        width: 930px;
        color: var(--white);
        font-size: 20px;
        font-weight: var(--text-light);
        text-align: justify;
    }
    
    @media screen and (max-width: 480px){
        width: 100vw;
        scroll-snap-align: start;
            & > p{
                width: 100vw;
                padding-left: 20px;
                padding-right: 20px;
                box-sizing: border-box;
            }
    }
`

export const Perfil = styled.div`

    margin-top: 40px;

    & h4{
        color: var(--yellow-100);
        font-size: var(--text-small);
        font-weight: var(--text-bold);
    }

    & p{
        color: var(--yellow-100);
        font-weight: var(--text-light);
    }

`