export const form = {
    title1: 'Está com alguma duvida?\n Por favor, entre em contato',
    title2: 'Solicite sua cotação de seguro hoje mesmo e veja como podemos ajudá-lo a ter a tranquilidade que você merece.',
    name: 'Nome',
    lastName: 'Sobrenome',
    phone: 'Telefone',
    email: 'E-mail',
    insuranceType: 'Tipo de Seguro',
    insuraceTypePlacehoder: 'Escolha o tipo de seguro',
    message: 'Mensagem',
    button: 'Enviar'

}