import styled,{keyframes} from "styled-components";

import BackgroundTop from '../../asset/img/background-insurers-top.svg'
import BackgroundBottom from '../../asset/img/background-insurers-botton.svg'

export const InsurersContainer = styled.section`

    width: 100%;
    padding-top: 120px;
    padding-bottom: 100px;
    background: url(${BackgroundTop}) no-repeat top right, url(${BackgroundBottom}) no-repeat bottom left;
   
   @media screen and (max-width: 480px){
    background: url(${BackgroundTop}) no-repeat -25% top, url(${BackgroundBottom}) no-repeat bottom left;
   }
`

export const InsurersContent = styled.div`

    width: 100%;
    display: flex;
    justify-content: center;
   

`

export const InsurerText = styled.div`

    width: var(--container-width);
    padding-bottom: 60px;

    & h2{
        font-size: var(--text-large);
        color: var(--blue-1000);
        font-weight: var(--text-bold);
        white-space: pre-line;
    }

    @media screen and (max-width: 480px) {
        padding-left: 20px;
    }

`

export const Carrucel = styled.div`
    
    overflow: hidden;
    display: flex;
    justify-content: flex-start;

`

const carrucelAnimation = keyframes`

    from{
        left: 0px;
    }
    to{
        //To the infinity effect works, set the total width of the box component plus 30
        left: -4085px;
    }

`

export const Box = styled.div`

    display: flex;
    justify-content: flex-start;
    gap: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    position: relative;
    animation: ${carrucelAnimation} linear 30s infinite;
    animation-play-state: running;

    & img{
        border-radius: 12px;
        box-shadow: 0px 0px 18px #EEEEEE;
    }

`


