import React from "react";
import ButtonPrimary from "../ButtonPrimary";
import { i18n } from '../../translate/i18n'

import { CenterArticle, CenterArticleContainer, CollumText, GetInsurance, ImgContent, LeftArticle, LeftImgEffect, Life, LifeContainer, RightArticle, RightImgEffect, Subtitle, TextContent, TextQuestion, Title } from './style'

import IconLife from '../../asset/img/icon-title-life.svg'
import ImgLife1 from '../../asset/img/img-life-1.jpg'
import ImgLife2 from '../../asset/img/img-life-2.jpg'
import ImgLife3 from '../../asset/img/img-life-3.jpg'

import IconTermOLife from '../../asset/img/icon-life-termlife.svg'
import IconPermanentLife from '../../asset/img/icon-life-permanentlife.svg'
import { Link } from "react-router-dom";

export default function LifeContent(){
    return(
        <Life>
            <LifeContainer>

                <Title>
                    <img src={IconLife} alt="" />
                    <h1>{i18n.t('life.pageTitle')}</h1>
                </Title>

                <LeftArticle>
                    <TextContent>

                        <div>
                            <h4>{i18n.t('life.annuities.title')}</h4>
                            <p>
                                {i18n.t('life.annuities.text')}
                            </p>
                        </div>

                        <div>
                            <h4>{i18n.t('life.benefits.title')}</h4>
                            <p>
                                {i18n.t('life.benefits.text')}
                            </p>
                        </div>

                    </TextContent>

                    <ImgContent right>

                        <div>
                            <img src={ImgLife1} alt={i18n.t('life.image1')} />
                            <RightImgEffect></RightImgEffect>
                        </div>

                    </ImgContent>
                </LeftArticle>

                <CenterArticle>

                    <CenterArticleContainer>

                        <h4>{i18n.t('life.lifeInsurance.title')}</h4>
                        <p>
                            {i18n.t('life.lifeInsurance.text')}
                        </p>

                        <CollumText>

                            <div>
                                <img src={IconTermOLife} alt={i18n.t('life.lifeInsurance.lifeInsuranceTypes.termLifeInsurance.title')} />
                                <h4>{i18n.t('life.lifeInsurance.lifeInsuranceTypes.termLifeInsurance.title')}</h4>
                                <p>
                                    {i18n.t('life.lifeInsurance.lifeInsuranceTypes.termLifeInsurance.text')}
                                </p>
                            </div>
                            <div>
                                <img src={IconPermanentLife} alt={i18n.t('life.lifeInsurance.lifeInsuranceTypes.permanentLifeInsurance.title')} />
                                <h4>{i18n.t('life.lifeInsurance.lifeInsuranceTypes.permanentLifeInsurance.title')}</h4>
                                <p>
                                    {i18n.t('life.lifeInsurance.lifeInsuranceTypes.permanentLifeInsurance.text')}
                                </p>
                            </div>

                        </CollumText>

                        <p>
                            {i18n.t('life.lifeInsurance.text2')}
                        </p>

                        <div>
                            <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                                <ButtonPrimary type="large">
                                    {i18n.t('life.lifeInsurance.button')}
                                </ButtonPrimary>
                            </Link>
                        </div>

                    </CenterArticleContainer>

                </CenterArticle>


                <RightArticle>

                    <ImgContent left="true">
                        <div>
                            <LeftImgEffect></LeftImgEffect>
                            <img src={ImgLife2} alt={i18n.t('life.questions.1.image')} />                            
                        </div>
                    </ImgContent>

                    <TextContent>
                        <h2>{i18n.t('life.questions.1.title')}</h2>
                        <p>
                            {i18n.t('life.questions.1.text1')}
                        </p><br/>
                        <p>
                            {i18n.t('life.questions.1.text2')}
                        </p>
                    </TextContent>

                </RightArticle>

                <LeftArticle>

                    <TextContent>
                        <h2>{i18n.t('life.questions.2.title')}</h2>
                        <p>
                            {i18n.t('life.questions.2.text')}
                        </p>
                    </TextContent>

                    <ImgContent right>

                        <div>
                            <img src={ImgLife3} alt={i18n.t('life.questions.2.image')} />
                            <RightImgEffect></RightImgEffect>
                        </div>

                    </ImgContent>
                </LeftArticle>

                <GetInsurance>
                    <div>
                        <h4>{i18n.t('life.callQuotation.title')}</h4>
                        <p>
                            {i18n.t('life.callQuotation.text')}
                        </p>
                        <Link to="/quote" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <ButtonPrimary type="large">
                                {i18n.t('life.callQuotation.button')}
                            </ButtonPrimary>
                        </Link>
                    </div>
                </GetInsurance>


            </LifeContainer>
        </Life>
    )
}