import styled from "styled-components";

export const LandPageIntroduction = styled.section`

    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 480px){
    }
`
export const Container = styled.div`

    max-width: var(--container-width);
    padding-top: 80px;
    padding-bottom: 80px;

    @media screen and (max-width: 480px){
        width: 100%;
    }

`

export const Title = styled.div`

    padding-left: 30px;
    
    & h2{
        font-size: var(--text-xlarge);
        font-weight: var(--text-bold);
        color: var(--blue-900);
    }


`

export const IntroductionText = styled.div`

    padding: 40px 50px 40px 50px;

    & p{

    }

    @media screen and (max-width: 480px){
        padding: 40px 30px 40px 30px;
    }

`
/*
export const LandPageCarousel = styled.div`

    overflow: auto;
    scroll-snap-type: x mandatory;

    & div{
        display: flex;
        gap: 40px;      

        & img{
            border-radius: 12px;
            scroll-snap-align: start;
            scroll-snap-stop: always;
        }
    }
   
`
*/

