import React, { useState } from 'react'
import { LandPageHeader, LandPageHeaderContainer, LanguageChange, LanguageEnus, LanguagePtbr} from './style'
import { Link } from 'react-router-dom'

import LogoHeader from '../../asset/img/logo-header.png'
import IconBrazil from '../../asset/img/brazil.png'
import IconUsa from '../../asset/img/usa.png'


export default function QuoteLinkHeader(){

    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'))

    function handleLanguages(languageSelect){
        localStorage.setItem('i18nextLng', languageSelect)
        window.location = window.location
    }

    return(
        <>
            <LandPageHeader>
                <LandPageHeaderContainer>

                    <LanguageChange>
                        <LanguagePtbr selected={language === 'pt-BR' && ('true')} onClick={event => handleLanguages('pt-BR')}>
                            <img src={IconBrazil} alt="" />
                        </LanguagePtbr>
                        <LanguageEnus selected={language === 'en-US' && ('true')} onClick={event => handleLanguages('en-US')}>
                            <img src={IconUsa} alt="" />
                        </LanguageEnus>
                    </LanguageChange>
                    <nav>
                        <Link to="/" onClick={() => { window.scroll({ top: 0, left: 0,}) }}>
                            <img src={LogoHeader} alt="Royal Eagle Insurance" />
                        </Link>
                    </nav>

                </LandPageHeaderContainer>
            </LandPageHeader>
        </>
    )
}