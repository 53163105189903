import React from "react";
import Footer from "../components/Footer";
import Form from "../components/Form";
import Header from "../components/Header";
import QuestionContent from "../components/QuestionContent";

export default function CommonQuestions(){
    return(
        <>        
            <Header />

            <main>

                <QuestionContent />

                <Form />

            </main>

            <Footer />

        </>
    )
}