export const auto = {
    pageTitle: 'Auto',
    text1: 'Sua apólice de seguro auto ajuda a proteger sua estabilidade financeira se você sofrer um acidente. Existem muitos tipos de coberturas, algumas delas opcionais.',
    image1: 'Carro novo',
    button: 'Formulário de Cotação',
    subTitle: 'Mais sobre Auto Insurance',
    text2: 'O seguro auto ajuda a fornecer proteção contra os efeitos financeiros negativos de um acidente de carro. A lei exige que você tenha cobertura de responsabilidade. Você também pode optar por comprar uma cobertura que ajude a proteger sua propriedade, independentemente de quem seja o culpado em um acidente.',
    text3: 'Entre em contato conosco hoje para saber mais sobre como criar uma apólice de seguro de automóvel que esteja em conformidade com as leis locais e, ao mesmo tempo, proteja suas finanças.',
    text4: 'Faça uma Cotação de Seguro Auto, Agentes Royal Eagle Insurance Group podem ajudá-lo a avaliar suas opções de auto insurance. Entre em contato e ajudaremos você a ter sua apólice de seguro de automóvel personalizada.',
    image2: 'Manutenção do carro'
}