export const question = {
    pageTitle: 'Frequently Asked Questions',

    questions: {
        1: {
            title:'What is Obamacare?',
            answer: {
                text1: 'The Affordable Care Act, popularly known as Obamacare, is a law enacted in 2014 to ensure that all Americans have affordable health insurance. The law offers subsidies to consumers (“premium tax credits”) that reduce families\' health costs.',
                text2: 'Obamacare has made important changes in how many people can access health care. For example, before Obamacare, insurers could exclude people with pre-existing conditions. Thus, Obamacare guaranteed basic treatments and even hospitalization for serious illnesses, regardless of gender or, above all, pre-existing conditions. The states of the Union that joined the project also received more federal funds for other projects focused on public health.'
            }
        },
        2: {
            title: 'Can I apply for Obamacare at any time?',
            answer: {
                text: 'There is a specific period for applying Obamacare. It starts on November 1st and ends on December 15th. This is also the renewal period for plans that will go into effect on January 1st of the following year.'
            }
        },
        3: {
            title: 'How can I apply for Obamacare outside of the regular application period?',
            answer: {
                text: 'After the November 1 through December 15 application period, you will only be able to apply for Obamacare if you qualify for a special application period such as getting married, having a child or adopting, moving out of state, getting divorced. if, among others.'
            }
        },
        4: {
            title: 'Can people without a Social Security Number apply for Obamacare?',
            answer: {
                text: 'Yes, submitting Social Security is optional.'
            }
        },
        5: {
            title: 'What coverage does Obamacare offer?',
            answer: {
                text: 'The act also determines a list of health care, especially related to prevention, that all health plans are required to cover, without a coverage cap, for:',
                list: {
                    1: 'Preventive visits, annual checkup, including chronic disease management',
                    2: 'Maternity and newborn',
                    3: 'Mental and behavioral health treatment',
                    4: 'Services and devices to help people with injuries, disabilities, or chronic conditions',
                    5: 'Laboratory',
                    6: 'Pediatric care',
                    7: 'Prescription drugs',
                    8: 'Outpatient care',
                    9: 'Emergency room services',
                    10: 'Hospitalization',
                }
            }
        },
        6: {
            title: 'What are the requirements to enroll in Obamacare?',
            answer: {
                text: 'To enroll in health coverage through Obamacare, you must be legally in the country and file a tax return.'
            }
        },
        7: {
            title: 'How much does health insurance through Obamacare cost?',
            answer: {
                text: 'Plan prices vary depending on:',
                list: {
                    1: 'Region you live',
                    2: 'How many people are there in your house?',
                    3: 'The family income declared in the annual tax'
                }
            }
        },
        8: {
            title: 'What is the "Maximum Out of Pocket" or Maximum Annual Limit?',
            answer: {
                text1: 'This is the limit you will have on your annual expenses for each person or family (not including the monthly payment). Imagine you have a $1,000 deductible, a 20% Co-Insurance, and a $5,000 Out of Pocket Maximum. After spending the $1,000, you are still responsible for paying 20% of the costs, as long as this amount does not exceed the $5,000 maximum.',
                text2: 'If you need to undergo a severe $100,000 treatment, you will not pay the $20,000 corresponding to your Co-Insurance, but the annual limit of $5,000 only.',
                text3: 'If you have health insurance with the following conditions:',
                list: {
                    1: '$500 dollar deductible',
                    2: '80% coinsurance',
                    3: '$3.000 dollar Out of Pocket Maximum.',
                    4: 'If your hospital bill was $10,000, you will first pay $500.',
                    5: 'From there, your insurance will cover 80% of the amount, while you pay the remaining 20%.',
                    6: 'You will continue to pay 20% of bills until you reach your Out Of Pocket Maximum.',
                },
                text4: 'From that moment on, the insurance will cover 100% of your medical expenses. Bear in mind that this account is made within the validity of your plan. When a new year starts, expenses go back to $0, and before insurance covers 100% of your hospital bill, you will need to reach your Deductible and Out Of Pocket Maximum values again.'
            }
        },
        9: {
            title: 'What is the difference between Life Insurance Term and Life Insurance Permanent Life?',
            answer: {
                text1: 'Permanent Life Insurance is Whole Life Insurance or Universal Life Insurance and they offer lifetime coverage to their policyholders.',
                text2: 'Term Life Insurance provides protection for a certain period, however its cost is lower when compared to Permanent Life Insurance.',
                text3: 'Life Insurance will ensure that your beneficiaries can use the money for whatever purpose they choose. Having the safety net of life insurance ensures, for example, that your family can pay off the mortgage on their home, pay for the projects you\'ve dreamed of for them, giving your family a measure of comfort in the face of uncertainty and grief.',
            }
        },
        10: {
            title: 'How does PPO work?',
            answer: {
                text: 'PPO you are free to choose the doctor for your need. With a PPO plan you don\'t need a *referral (to be referred by the general practitioner) that is, you can go straight to a specialist. PPO offers a larger care network of doctors and hospitals; often national network. You can also consult with “out of network” doctors, however, obviously, in this case, the amount paid by the insurance for medical treatments will be lower.'
            }
        },
        11: {
            title: 'What is Deductible?',
            answer: {
                text: 'Deductible is a kind of deductible amount. It is the amount you need to pay before your insurance starts paying for valid medical treatments during the 1 year period. If you purchase a health plan with a deductible of US$1,000, this means that your plan only becomes “valid” after you have already paid US$1,000 in that year, not including the amounts paid in your premium.'
            }
        },
        12: {
            title: 'What is Premium?',
            answer: {
                text: 'Premium is the monthly fee you need to pay for your plan.'
            }
        },
        13: {
            title: 'How does the HMO work?',
            answer: {
                text: 'HMOs offer a smaller listing of “in network” hospitals and you will need to go through a general practitioner who will see you initially who will then refer you to a specialist if necessary. You will not be able to choose the specialist who will assist you. These plans do not cover visits to doctors that are considered “out of network” by your plan.'
            }
        },
        14: {
            title: 'What is Co-Pay?',
            answer: {
                text1: 'Co-Pay is how much you will pay with the plan for each consultation and use of the plan. When you take out insurance, you are usually responsible for paying portions of your medical expenses. This value changes according to the physician\'s type/category.',
                text2: 'For example: you may have to shell out US$15 for a general practitioner, US$40 for specialists and US$200 for an emergency (keep in mind that these amounts vary according to your contracted insurance).',
            }
        },
        15: {
            title: 'What is Co-Insurance?',
            answer: {
                text: 'It\'s the percentage your insurance will pay when you reach your deductible. For example: If you have a Co-Insurance of 20% and you use a treatment of $1000 USD, this means that the plan will pay you $800 USD and you will have to pay the remaining $200 USD.'
            }
        }
    }

}