import styled, {keyframes} from "styled-components";

import IconHelthMedium from '../../asset/img/icon-health-medium.svg'
import IconLifeMedium from '../../asset/img/icon-life-medium.svg'
import IconTravelMedium from '../../asset/img/icon-travel-medium.svg'
import IconaAutoMedium from '../../asset/img/icon-car-medium.svg'
import IconHomeMedium from '../../asset/img/icon-home-medium.svg'
import IconBusinessMedium from '../../asset/img/icon-business-medium.svg'
import IconTruckMedium from '../../asset/img/icon-truck-medium.svg'

import BackgroundVideo from '../../asset/img/background-video.png'

export const HeaderContainer = styled.div`

    width: 100%;
    display: flex;
    justify-content: center;

`

export const HeaderContant = styled.div`

    width: 1170px;     

    @media (max-width: 480px) {
        width: 100%;
    }

`

export const TopHeader = styled.div`

    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    z-index: 2;
    position: relative;

    @media (max-width: 480px) {
        display: none;
    }


`

export const TopHeaderMobile = styled.div`
    display: none;

    @media (max-width: 480px) {
        display: block;
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px 0px 20px;
        box-sizing: border-box;
    }

`

export const LogoMobile = styled.img`

    @media (max-width: 480px) {
        width: 150px;
        position: relative;
        z-index: 2;
    }


`

export const MenuMobile = styled.div`

    @media (max-width: 480px) {
        position: relative;
        z-index: 5;
    }

`

export const MenuCard = styled.div`

    display: none;

    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 71%;
        background: rgba(10, 15, 44, 0.8);
        backdrop-filter: blur(3.5px);
        border-radius: 12px 0px 0px 12px;
        position: absolute;
        top: 10px;
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 20px;
        box-sizing: border-box;
        z-index: 4;
        transition: all 0.4s ease;
        right: ${props => props.active === true ? ('0') : ('-71%')};

    }

`

export const Logo = styled.div`

    

`

export const Nav = styled.div`

    

`

export const HeaderContact = styled.div`

    display: flex;
    justify-content: flex-end;
    gap: 30px;
    

`

export const HeaderContactContainer = styled.div`

    display: flex;
    justify-content: flex-end;
    gap: 30px;


    & a{
        display: flex;
        align-items: center;
        gap: 14px;

        &:hover p{
            color: var(--yellow-100);
            transition: all 0.3s ease;

        }            

    }

    & p{
        color: #fff;
        transition: all 0.3s ease;

    }

    @media screen and (max-width:480px){
        justify-content: flex-start;

        & p{
            font-size: var(--text-xsmall);
        }

    }

`

export const LanguageChange = styled.div`

    display: flex;
    justify-content: flex-end;
    gap: 14px;

    & div{
        width: 30px;
        height: 30px;
        border-radius: 100% ;
        cursor: pointer;
    }

    & div:hover{
        border: 1px solid #FFD67D;
    }

    @media screen and (max-width:480px){
        justify-content: flex-start;
    }

`

export const LanguagePtbr = styled.div`

    
    border: 1px solid ${props => props.selected ? ('#FFD67D;') : ('transparent')};

    `

export const LanguageEnus = styled.div`

    border: 1px solid ${props => props.selected ? ('#FFD67D;') : ('transparent')};

`

export const HeaderMenu = styled.ul`

    display: flex;
    justify-content: flex-end;
    gap: 30px;
    margin-top: 28px;

    & li{
        color: #fff;
        transition: all 0.2s linear;

        &:hover{
            color: var(--yellow-100);
        }
    }

    @media screen and (max-width:480px){
        flex-direction: column;
        gap: 30px;
    }
`

export const Banner = styled.div`

    width: 100%;
    height: ${props => props.smallBanner ? ('645px') : ('900px')};
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    ${props => props.smallBanner ? ('') : (`background: url(${BackgroundVideo}) no-repeat center; background-size: cover;`)};
    background: url(BackgroundVideo) none center;
    background-size: cover;
    z-index: -1;

    top: 0;
    left: 0;

    & video, img{
        width: 100%;
        object-fit: cover;
    }

    @media screen and (max-width: 480px){
        height: ${props => props.smallBanner ? ('467px') : ('100vh')};
    }

`

export const BannerContainer = styled.div`

    width: 100%;
    height: ${props => props.smallBanner ? ('645px') : ('900px')};
    position: absolute;
    display: flex;
    justify-content: center;

    top: 0;
    left: 0;

    @media screen and (max-width: 480px){
        height: ${props => props.smallBanner ? ('467px') : ('100vh')};
    }


`

export const BannerContent = styled.div`

    width: 1170px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & h3{
        width: 404px;
        color: #fff;
        font-size: 32px;

        & span{
            font-weight: 600;
        }
    }

    & h4{        
        margin-top: 16px;
        color: #fff;
        font-size: 24px;
        & span{
            font-weight: 600;
            color: #FFD67D;
        }
    }

    @media (max-width: 480px) {
        padding-left: 20px;
        padding-right: 20px;

        & h3{
            width: 100%;
        }
    }

`

export const InsuranceMenu = styled.div`

    position: relative;
    margin-top: ${props => props.smallBanner ? ('280px') : ('535px')};
    z-index: 2;

    @media screen and (max-width: 480px){
        margin-top: ${props => props.smallBanner ? ('120px') : ('350px')};
    }

`

export const InsuranceInfo = styled.div`

    display: flex;
    justify-content: center;
    height: 204px;

    & > div{
        width: 100%;
        height: 204px;
        display: flex;
        justify-content: center;

        &:hover{
            //height: 188px;
            //margin-bottom: 16px;
        }
    }


`

const suaveCard = keyframes`
    from {
        opacity: 0;
        margin-top: 10px
    }
    to{
        opacity: 1;
        margin-top: 0px
    }
    
`
 

export const InsuranceInfoContainer = styled.div`

    width: 877.69px;
    height: 188px;
    backdrop-filter: blur(3.5px);
    border-radius: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;

    animation: ${suaveCard} 0.2s ease;


    background: rgba(162, 176, 255, 0.5) 
    ${(props) => 
        props.background === 'health' && (`url(${IconHelthMedium})`) ||
        props.background === 'life' && (`url(${IconLifeMedium})`) ||
        props.background === 'travel' && (`url(${IconTravelMedium})`) ||
        props.background === 'auto' && (`url(${IconaAutoMedium})`) ||
        props.background === 'home' && (`url(${IconHomeMedium})`) ||
        props.background === 'business' && (`url(${IconBusinessMedium})`) ||
        props.background === 'truck' && (`url(${IconTruckMedium})`)
        
     }    
    no-repeat;
    background-position: left center;

    @media screen and (max-width: 480px){
        display: none;
    }


`

export const InsuranceInfoText = styled.div`

    width: 470px;

    & h4{
        font-size: 24px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 8px;
    }

    & p{
        color: #FFF;
        text-align: justify;
        font-weight: 300;
        line-height: unset;
    }

`

export const ButtonContainer = styled.div`

    margin-right: 70px;
    
    & button{
        cursor: pointer;
    }

`

export const InsuranceOptions = styled.ul`

    display: flex;
    justify-content: space-between;
    
    
    & li{
        width: 155px;
        height: 160px;
        text-align: center;
        background: linear-gradient(320.44deg, #171666 25.34%, #44368E 80.72%, #AF517E 138.46%);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.2s ease-out;

        &::before{
            position: absolute;
            content: "";
            width: 155px;
            height: 161px;
            background-image: linear-gradient(135.63deg, #D6D3FF 0%, #C8C6F6 70.46%, #6766B9 137.21%);
            z-index: 0;
            transition: opacity 0.2s linear;
            opacity: 0;
            border-radius: 12px;
        }

        & div{
            position: relative;
            z-index: 1;
            transition: opacity 0.5s linear;

        }

        & div p{
            margin-top: 22px;
            color: #fff;
            text-transform: uppercase;
        }

        

    }

    & li:hover{
        background: linear-gradient(135.63deg, #D6D3FF 0%, #C8C6F6 70.46%, #6766B9 137.21%);

        & div svg path{
            stroke:#171666;
        }

        & div p{
            color: #171666;
            font-weight: var(--text-bold);
        }
    }

    & li:hover::before{
        opacity: 1;
    }

    @media (max-width: 480px) {
        overflow-x: auto;
        gap: 20px;
        padding-left: 20px;
        padding-right: 20px;

        & li{
            width: 130px;
            height: 135px;

            &::before{
                display: none;
            }

            & li:hover::before{
                display: none;
            }

            & li:hover{
                background: linear-gradient(135.63deg, #D6D3FF 0%, #C8C6F6 70.46%, #6766B9 137.21%);
            }
        }
    }

`