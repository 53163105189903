import React, { useState } from "react";
import Lottie from "react-lottie";
import AnimationData from '../../asset/video/computer-animation.json'
import ButtonPrimary from "../ButtonPrimary";
import { i18n } from "../../translate/i18n";
import EmailJs from '@emailjs/browser';
import MessageWindow from './MessageWindow'
import { FormAnimation, FormContainer, FormContent, FormFields, FormInputs, FormTitle, LottieContainer, WhiteOnTop, InputRow, MessageWindowWraper, LottieContainerMobile } from './style'

export default function Form({type}){

    const [sendEmailMessage, setSendEmailMessage] = useState({fade: false, isStopped: true})

    function sendEmail(event){

        event.preventDefault()
        
        EmailJs.sendForm('service_n1soz9p', 'template_ff8fq4s', event.target, 'iEfYSYQEvwZmLle-b')
        .then((result) =>{
            console.log(result.text)
        }, (error) => {
            console.log(error.text)
        })
        
        console.log(event.target.elements.phone)

        for(let i = 0; i < event.target.length; i++){
            event.target.elements[i].value = ""
        }

        setSendEmailMessage({fade:true})

        const timeOut = setTimeout(() => {
            setSendEmailMessage({fade:false})
        }, 3000)

        const stopTimeOut = setTimeout(() => {
            setSendEmailMessage({isStopped:true})
        }, 4000)

    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: AnimationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }

    return(
        <FormContainer>
            <FormContent>

                <MessageWindowWraper fade={sendEmailMessage.fade}>
                    <MessageWindow isStopped={sendEmailMessage.isStopped}/>
                </MessageWindowWraper>


                <FormAnimation>
                    <WhiteOnTop></WhiteOnTop>
                    <LottieContainer>
                        <Lottie options={defaultOptions} width="974px" isClickToPauseDisabled/>
                    </LottieContainer>
                    <LottieContainerMobile>
                        <Lottie options={defaultOptions} width="100%" isClickToPauseDisabled/>
                    </LottieContainerMobile>
                </FormAnimation>
                <FormFields>

                    <FormTitle>

                    { type ? (

                        <h4>
                            {i18n.t('form.title2')}
                        </h4>

                        ) : (

                        <h2>
                            {i18n.t('form.title1')}
                        </h2>
                    )}  
                    </FormTitle>

                    <FormInputs onSubmit={sendEmail}>

                    { type ? (

                        <>
                            <InputRow>
                                <label>
                                    <p>{i18n.t('form.name')}</p> 
                                    <input type="text" name="name" required />
                                </label>

                                <label>
                                    <p>{i18n.t('form.lastName')}</p>
                                    <input type="text" name="lastname" required />
                                </label>
                            </InputRow>
                            <InputRow>
                                <label>
                                    <p>{i18n.t('form.phone')}</p>
                                    <input type="tel" name="phone" required />
                                </label>

                                <label>
                                    <p>{i18n.t('form.email')}</p> 
                                    <input type="email" name="email" required />
                                </label>
                            </InputRow>

                            <label>
                                <p>{i18n.t('form.insuranceType')}</p> 
                                <select name="insurancetype" id="" >
                                    <option value="" disabled selected>{i18n.t('form.insuraceTypePlacehoder')}</option>
                                    <option value="health">Health</option>
                                    <option value="life">Life</option>
                                    <option value="travel">Travel</option>
                                    <option value="auto">Auto</option>
                                    <option value="home">Homeowners</option>
                                    <option value="business">Business & Commercial</option>
                                    <option value="truck">Truck</option>
                                </select>
                            </label>
                            <input type="hidden" name="formtype" value="Cotação" />                       
                        </>

                    ) : (

                        <>
                            <label>
                                {i18n.t('form.name')}
                                <input type="text" name="name" required />
                            </label>

                            <label>
                                {i18n.t('form.email')}
                                <input type="email" name="email" required />
                            </label>

                            <label>
                                {i18n.t('form.phone')}
                                <input type="tel" name="phone" required />
                            </label> 
                            <input type="hidden" name="formtype" value="Mensagem" />                       
                        </>

                    )}

                        <label>
                            {i18n.t('form.message')}
                            <textarea name="message" required />
                        </label>
                        <div>
                            <ButtonPrimary type={'large'}>{i18n.t('form.button')}</ButtonPrimary>
                        </div>

                    </FormInputs>
                </FormFields>
            </FormContent>
        </FormContainer>
    )
}