import styled from "styled-components";

export const MessageWindowContainer = styled.div`
    width: 570px;
    height: 405px;

    border-radius: 12px;
    border: 1px solid #F3F3F3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
    background: white;
    border: 1px solid var(--blue-100);
    /*
    position: absolute;
    z-index: 5;    
    left: 25%;
    top: 15%;
    */

    @media screen and (max-width: 480px){
        width: 85%;
    }
`

export const MessageText = styled.h2`
    font-size: var(--text-xlarge);
    font-weight: var(--text-bold);
    white-space: pre-wrap;
    color: var(--blue-700);
    
    @media screen and (max-width: 480px){
        text-align: center;
    }

`