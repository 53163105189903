import React from 'react'
import LandpageHeader from '../components/LandpageHeader'
import LandpageIntroduction from '../components/LandpageIntroduction'
import FormLandpage from '../components/FormLandpage'
import LandpageFooter from '../components/LandpageFooter'

import ReactPixel from 'react-facebook-pixel'

export default function LandPage(){

    const options = {
        autoConfig: true,
        debug: false
    }

    ReactPixel.init('7490390597681219', options)
    ReactPixel.pageView()

    return(
        <>             
            <LandpageHeader />

            <main>

                <LandpageIntroduction />

                <FormLandpage />

            </main>

            <LandpageFooter />
            
        </>
    )
}